import { gql } from "@apollo/client";
import { DOCUMENTS_FRAGMENT } from "./LinkedDocumentQueries";

export const LETTER_FRAGMENT = gql`
    ${DOCUMENTS_FRAGMENT}
    fragment LetterFragment on Letter {
        id
        title
        letterDate
        letterTemplateId
        letterTemplate{
            id
            name
            practitioner{
                id
                firstName
                lastName
            }
            isDeleted
        }
        content
        notes
        practitionerId
        practitioner{
            id
            firstName
            lastName
        }
        recipients{
            id
            firstName
            lastName
        }
        version
        imageFileIds
        isLinkedDocument
        linkedDocument { 
          ...DocumentsFragment
        }
        audit {
          crePit
        }
    }
  `


export const GET_PATIENT_LETTERS = gql`
  ${LETTER_FRAGMENT}
  query GetPatientLetters($patientId: ID!, $tenantId: ID!) {
    tenant(id: $tenantId) {
      patient(id: $patientId) {
        letter {
          list {
            ...LetterFragment
          }
        }
      }
    }
  }
`

export const CREATE_LETTER = gql`
  ${LETTER_FRAGMENT}
  mutation CreateLetter($tenantId: ID!, $input: LetterInput!) {
    tenant(id: $tenantId) {
      patient {
        letter {
          create(input: $input) {
            ...LetterFragment
          }
        }
      }
    }
  }
`

export const UPDATE_LETTER = gql`
  ${LETTER_FRAGMENT}
  mutation UpdateLetter($tenantId: ID!, $letterId: ID!, $input: LetterInput!) {
    tenant(id: $tenantId) {
      patient {
        letter {
          update(letterId: $letterId, input: $input) {
            ...LetterFragment
          }
        }
      }
    }
  }
`

export const DELETE_LETTER = gql`
  mutation DeleteLetter($tenantId: ID!, $letterId: ID!) {
    tenant(id: $tenantId) {
      patient {
        letter {
          delete(letterId: $letterId)
        }
      }
    }
  }
`
