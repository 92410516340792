import React, { useCallback } from 'react'
import { ApolloError, useMutation } from '@apollo/client'
import {
  CREATE_REVIEW_DOCUMENT,
  DELETE_DOCUMENT,
  GET_USER_DOCUMENTS,
  LINK_DOCUMENT
} from '../../../../graphql-definitions'
import { useParams } from 'react-router-dom'
import { LinkDocumentDetails, LinkDocumentInput, PagesToInclude, ReviewRequired } from '../../../../types/Document'
import { useAlerts } from 'saga-library/src/providers/Alerts'
import { useDocumentContext } from '../../../../providers/DocumentProvider'
import { useImportedDocumentsStateContext } from './ImportedDocumentsStateProvider'
import {
  processPageRangesForExtractPages,
  processPageRangesForRemovePages
} from '../../../../components/FoxitPDFViewer/util/PageRange'
import { omit } from 'lodash'
import { ReviewDocument, ReviewDocumentInput } from '../../../../types/inbox/ReviewDocument'
import _get from 'lodash/get'

interface ImportedDocumentsActionContextType {
  linkDocument: (documentData: LinkDocumentDetails) => Promise<void>,
  deleteDocument: (documentId: string) => Promise<void>,
}

const defaultImportedDocumentsActionContext: ImportedDocumentsActionContextType = {
  linkDocument: (documentData) => Promise.resolve(),
  deleteDocument: (documentId) => Promise.resolve(),
}

export const saveDocumentChanges = async (documentModified, pdfDocRef, selectedFile, updateFile, uploadToAzure, forceSave?: boolean) : Promise<boolean> => {
  if (!documentModified && !forceSave) return true
  if (!pdfDocRef) return false

  const pdfDoc = pdfDocRef.current
  if (!pdfDoc) return false

  const file = await pdfDoc.getFile({ flags: 0, fileName: selectedFile?.originalFileName })
  if (!file) {
    console.error('Error getting file from PDFDoc')
    return false
  }

  const updatedFile = await updateFile(selectedFile!.id, file.size, selectedFile!.version || "0")
  if (!updatedFile) {
    console.error('Error updating file')
    return false
  }

  try {
    await uploadToAzure(file, updatedFile!)
    return true
  } catch (e) {
    console.error('Error uploading file to Azure', e)
    return false
  }
}

const ParseError = (error: ApolloError, defaultMessage: string, showErrorAlert: (message: string, Action?: React.ReactNode) => void) => {
  const errors = (error?.networkError as any)?.result?.errors || []
  if (errors.length > 0 && errors[0]?.extensions?.userError === true) {
    showErrorAlert(errors[0]?.message)
  } else {
    showErrorAlert(defaultMessage)
  }
}

const ImportedDocumentsActionContext = React.createContext(defaultImportedDocumentsActionContext)

export const ImportedDocumentsActionProvider = ({ children }) => {
  const { tenant_id } = useParams()
  const { showErrorAlert, showSuccessAlert } = useAlerts()
  const { uploadToAzure, updateFile, uploadAndLinkFile } = useDocumentContext()
  const { pdfDocRef, pdfViewerRef, documentModified, selectedFile,  } = useImportedDocumentsStateContext()

  const [linkDocumentMutation] = useMutation(LINK_DOCUMENT)
  const [createReviewDocumentMutation] = useMutation(CREATE_REVIEW_DOCUMENT)
  const [deleteDocumentMutation] = useMutation(DELETE_DOCUMENT)

  const getPdfDocRender = useCallback(() => {
    if (!pdfDocRef) return null
    const pdfDoc = pdfDocRef.current
    if (!pdfDoc) return null

    if (!pdfViewerRef) return null
    const pdfViewer = pdfViewerRef.current
    if (!pdfViewer) return null

    return pdfViewer.getPDFDocRender()
  }, [pdfDocRef, pdfViewerRef])

  const linkDocument = useCallback(async (documentData: LinkDocumentDetails) => {
    if (!await saveDocumentChanges(documentModified, pdfDocRef, selectedFile, updateFile, uploadToAzure)) {
      showErrorAlert("Document changes couldn't be saved. Document was not linked.")
      return
    }

    const reviewRequired = documentData.reviewRequired
    const assignedUserId = documentData.assignedUserId
    const linkDocumentInput = omit(documentData, ['pagesToInclude', 'pageRange', 'reviewRequired', 'assignedUserId']) as LinkDocumentInput

    await linkDocumentMutation({
      variables: {
        tenantId: tenant_id,
        linkDocumentInput: linkDocumentInput
      },
      onCompleted: async (data) => {
        if (reviewRequired === ReviewRequired.YES) {
          const linkedDocument = _get(data, 'tenant.file.linkDocument')
          await createReviewDocument({
            userId: assignedUserId!,
            linkedDocumentId: linkedDocument.id,
            version: '0'
          }, () => showSuccessAlert('Document has been linked.'))
        } else {
          showSuccessAlert('Document has been linked.')
        }
      },
      onError: (error) => {
        ParseError(error, "Document couldn't be linked.", showErrorAlert)
      }
    })
  }, [saveDocumentChanges, linkDocumentMutation, tenant_id, showErrorAlert, showSuccessAlert])

  const linkDocumentPageRange = useCallback(async (documentData: LinkDocumentDetails) : Promise<boolean> => {
    if (!pdfDocRef) return false
    const pdfDoc = pdfDocRef.current
    if (!pdfDoc) return false

    if (!documentData.pageRange) return false

    let fileBlob: ArrayBuffer[]
    try {
      fileBlob = await pdfDoc.extractPages(processPageRangesForExtractPages(documentData.pageRange))
    } catch (error) {
      console.error("Error extract pages", error)
      return false
    }

    const file = new File(fileBlob, selectedFile!.originalFileName)
    const linkDocumentInput = omit(documentData, ['pagesToInclude', 'pageRange']) as LinkDocumentInput

    const fileRecord = await uploadAndLinkFile(file, linkDocumentInput)
    if (fileRecord) {
      showSuccessAlert("Page(s) have been split into a separate document and linked to patient.")
    } else {
      return false
    }

    try {
      await pdfDoc.removePages(processPageRangesForRemovePages(documentData.pageRange))
    } catch (error) {
      console.error("Error removing pages", error)
      return false
    }

    if (!await saveDocumentChanges(documentModified, pdfDocRef, selectedFile, updateFile, uploadToAzure, true)) {
      showErrorAlert("Document changes couldn't be saved.")
    }
    return true
  }, [pdfDocRef, selectedFile, uploadAndLinkFile, saveDocumentChanges, showSuccessAlert, showErrorAlert])
  
  const linkDocumentPages = useCallback(async (documentData: LinkDocumentDetails) => {
    switch (documentData.pagesToInclude) {
      case PagesToInclude.CURRENT:
        const pdfDocRender = getPdfDocRender()
        if (!pdfDocRender) {
          showErrorAlert("Document couldn't be linked.")
          return
        }

        const currentPage = pdfDocRender.getCurrentPageIndex() + 1

        if (!await linkDocumentPageRange({...documentData, pageRange: [[currentPage]]})) {
          showErrorAlert("Document couldn't be linked.")
        }
        break
      case PagesToInclude.RANGE:
        if (!await linkDocumentPageRange(documentData)) {
          showErrorAlert("Document couldn't be linked.")
        }
        break
      default:
        await linkDocument(documentData)
        break
    }
  },[getPdfDocRender, linkDocumentPageRange, linkDocument, showErrorAlert] )

  const createReviewDocument = useCallback(async (input: ReviewDocumentInput, onSuccess) => {
    await createReviewDocumentMutation({
      variables: {
        tenantId: tenant_id,
        input: input
      },
      onCompleted: () => {
        onSuccess()
      },
      onError: (error) => {
        ParseError(error, "Review document couldn't be created.", showErrorAlert)
      },
      update: (cache, data) => {
        const newReviewDocument = _get(data, 'data.tenant.review.document.create') as ReviewDocument
        const reviewDocumentsData = cache.readQuery({
          query: GET_USER_DOCUMENTS,
          variables: {
            tenantId: tenant_id,
            userId: input.userId
          }
        })

        cache.updateQuery({
          query: GET_USER_DOCUMENTS,
          variables: {
            tenantId: tenant_id,
            userId: input.userId
          }
        }, () => {
          if (reviewDocumentsData) {
            const existingReviewDocuments = _get(reviewDocumentsData, 'tenant.review.document.list', [])
            return {
              tenant: {
                review: {
                  document: {
                    list: [...existingReviewDocuments, newReviewDocument]
                  }
                }
              }
            }
          }
        })
      }
    })
  }, [createReviewDocumentMutation, showErrorAlert, tenant_id])

  const deleteDocument = useCallback(async (documentId:string) => {

    await deleteDocumentMutation({
      variables: {
        tenantId: tenant_id,
        documentId: documentId
      },
      onCompleted: (data) => {
        showSuccessAlert('Document has been deleted.')
      },
      onError: (error) => {
        ParseError(error, "Document couldn't be deleted.", showErrorAlert)
      }
    })
  },[deleteDocumentMutation, tenant_id, showErrorAlert, showSuccessAlert] )

  const providerValue : ImportedDocumentsActionContextType = {
    linkDocument: linkDocumentPages,
    deleteDocument,
  }

  return (
    <ImportedDocumentsActionContext.Provider value={providerValue}>
      {children}
    </ImportedDocumentsActionContext.Provider>
  )
}

export const useImportedDocumentsActionContext = () => {
  return React.useContext(ImportedDocumentsActionContext)
}
