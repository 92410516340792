import React, { useEffect } from 'react'
import { FormProvider, useForm } from 'saga-library/src/components/Form'
import { usePrompt } from '../../../../providers/NavigationPrompt'
import { TaskForm } from './TaskForm'
import { taskDefaults } from './TaskDefaultValues'
import { schema } from './TaskValidationsSchema'
import { PatientSectionHeader } from '../../../patients/components/PatientSectionHeader'
import { Section } from 'saga-library/src'
import { TaskAssignmentInput } from '../../../../types/tasks/TaskAssignment'

const FORM_NAME = "task_editor_form"

export const TaskEditor = ({}) => {
  const { enableNavigationPrompt } = usePrompt()

  const formMethods = useForm<TaskAssignmentInput>({
    defaultValues: taskDefaults,
    schema: schema,
  })

  const {
    handleSubmit,
    formState: { dirtyFields, isSubmitting },
  } = formMethods

  useEffect(() => {
    enableNavigationPrompt(!!Object.keys(dirtyFields).length, FORM_NAME, `tasks/`)
    return () => enableNavigationPrompt(false, FORM_NAME)
  }, [Object.keys(dirtyFields).length])

  const onSave = handleSubmit(() => {
    console.log("TODO")
  })

  return (
    <Section.Column
      rightPadding={1}
      sx={{ flex: "1 1 100%" }}
      header={
        <PatientSectionHeader
          dataTestId={'task-editor'}
          sectionLabel={"New task"}
          formName={FORM_NAME}
          submitting={isSubmitting}
          showSave={false}
        />
      } >
      <FormProvider {...formMethods}>
        <TaskForm
          formName={FORM_NAME}
          onSubmit={onSave}
          dataTestId={"task-editor-form"}
        />
      </FormProvider>
    </Section.Column>
  )
}