import {
  TableList,
  TableListHeaderConfig
} from 'saga-library/src'
import React, { useEffect, useMemo, useState } from 'react'
import { TASKS_SCROLL_KEY } from '../../../../providers/UserInteractionContext'
import { TaskCategory } from '../../../../types/tasks/TaskState'
import { TaskRow } from './TaskRow'
import { TaskAssignmentType } from '../../../../types/tasks/TaskAssignment'
import { useReadQuery } from '@apollo/client'
import { DATE_FORMAT } from '../../../../utils/SettingsConstants'
import { useAccountContext } from '../../../../providers/AccountContext'
import { useTaskContext } from '../../../../providers/TaskProvider'

const columns: TableListHeaderConfig[] = [
  { name: 'Priority', sortable: true },
  { name: 'State', sortable: true },
  { name: 'Type', sortable: true },
  { name: 'Name', sortable: true },
  { name: 'Patient', sortable: true },
  { name: 'Start date', sortable: true },
  { name: 'Due date', sortable: true }
]

export const TaskList = ({ filters, setSelectedTasks, clearSelectedTasks, setClearSelectedTasks }) => {
  const { getUserSetting } = useAccountContext()
  let dateFormat = getUserSetting(DATE_FORMAT) as string

  const { tasks, parseTaskQueryResults } = useTaskContext()
  const { data } = useReadQuery(tasks)
  const parsedData = useMemo(() => parseTaskQueryResults(data),
    [tasks, parseTaskQueryResults]
  )

  const [ filteredTaskList, setFilteredTaskList ] = useState<TaskAssignmentType[]>([])

  useEffect(() => {
    if(!parsedData) return

    let tempTasks = parsedData
    switch (filters.state) {
      case TaskCategory.INCOMPLETE:
        tempTasks = tempTasks.filter((task: TaskAssignmentType) => task.state.category === TaskCategory.INCOMPLETE)
        break
      default:
        tempTasks = tempTasks.filter((task: TaskAssignmentType) => task.state.name === filters.state)
    }
    switch (filters.type) {
      case "*":
        break
      default:
        tempTasks = tempTasks.filter((task: TaskAssignmentType) => task.type.type === filters.type)
    }
    setFilteredTaskList(tempTasks)

  }, [filters, parsedData])

  return (
    <TableList
      dataTestId={'task-table'}
      scrollKey={TASKS_SCROLL_KEY}
      showCheckbox={true}
      enableSorting={true}
      defaultSortColumn={-1}
      columns={columns}
      rows={[
        ...filteredTaskList.map((task) => TaskRow(task, () => console.log("TODO"), dateFormat))
      ]}
      onCheckboxSelectionChanged={setSelectedTasks}
      clearSelectedRows={clearSelectedTasks}
      setClearSelectedRows={setClearSelectedTasks}
      sx={{height: '100%'}}
      emptyListComponent={ { message: "Tasks appear here once created." } }
    />
  )
}