import { Box } from '@mui/material'
import { FilterSelect } from 'saga-library/src'
import React from 'react'
import { FilterType } from './DocumentsList'

interface DocumentsHeaderProps {
  setFilters: (event: any, filterType: FilterType) => void,
  dataTestId: string,
}

export const DocumentsHeader = ({
  setFilters,
  dataTestId
}: DocumentsHeaderProps) => {
  const filterDocumentsCategoryOptions = [
    { label: 'All categories', value: '*' },
    { label: 'Labs', value: 'labs' },
    { label: 'Investigations', value: 'investigations' },
    { label: 'Other', value: 'other' },
  ]

  const filterDocumentsReviewOptions = [
    { label: 'Unreviewed', value: 'unreviewed' },
    { label: 'Reviewed', value: 'reviewed' },
  ]

  return (
    <Box
      display={'flex'}
      flexDirection={'row'}
      justifyContent={'flex-start'}
      alignItems={'center'}
      gap={1}
    >
      <FilterSelect
        options={filterDocumentsCategoryOptions}
        onSelect={(event) => setFilters(event, "category")}
        defaultSelectedValue={'*'}
        dataTestId={`${dataTestId}-list-documents-filter`}
      />
      <FilterSelect
        options={filterDocumentsReviewOptions}
        onSelect={(event) => setFilters(event, "unreviewed")}
        defaultSelectedValue={'unreviewed'}
        dataTestId={`${dataTestId}-list-documents-review-filter`}
      />
    </Box>
  )
}