import React from 'react'
import ReactDOM from 'react-dom/client';
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { ApolloClientProvider } from './providers/Apollo'
import { ConfigurationProvider } from './providers/Configuration'
import { Auth } from './providers/Auth'
import { SagaThemeProvider } from 'saga-library/src'
import { ConcurrencysProvider } from "./providers/ConcurrencyConflictProvider";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <SagaThemeProvider>
    <ConfigurationProvider>
      <Auth>
        <ConcurrencysProvider>
          <ApolloClientProvider>
            <React.StrictMode>
              <App />
            </React.StrictMode>
          </ApolloClientProvider>
        </ConcurrencysProvider>
      </Auth>
    </ConfigurationProvider>
  </SagaThemeProvider>
)

//TODO: deal with this
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

// I just want to sneak this in temporarily. 
// TODO: If you find this, you can remove it.
console.warn("Mode: " + process.env.NODE_ENV)
