import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { Alert, AlertTitle, Box } from "@mui/material";
import { Tabs, Button, DeleteButton, Form, SaveButton } from "saga-library/src";
import { ClaimBase } from "../ClaimBase";
import { ClaimOptions } from "../ClaimOptions";
import { FormProvider, useForm } from "saga-library/src/components/Form";
import { useMutation, useQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import { useAlerts } from "saga-library/src/providers/Alerts";
import { schema } from "../../util/baseClaimValidation";
import _get from "lodash/get";
import { useAccountContext } from "../../../../providers/AccountContext";
import { LoadingSpinner } from "../../../../components/LoadingScreen";
import { StaticPage } from "../../../../components/Layouts";
import PauseCircleOutline from '@mui/icons-material/PauseCircleOutline'
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { ClaimInputType } from "../../../../types/billing";
import { UnsubmittedClaimUpdateDetailsHeader } from "./UnsubmittedClaimUpdateDetailsHeader";
import { setAbClaimValues } from "../../util/setAbClaimValues";
import { DELETE_CLAIM, GET_CLAIM, HOLD_CLAIM, LIST_UNSUBMITTEDCLAIMS, UNHOLD_CLAIM, UPDATE_CLAIM } from "../../../../graphql-definitions";
import { createClaimInputFromFormData } from "../../util/createClaimInputFromFormData";
import { setProblems } from "../../util/setProblems";
import { usePrompt } from "../../../../providers/NavigationPrompt";
import { Permission, PermissionType } from "../../../../types/settings/Permission";
import { AbClaimStatus } from "../../../../utils/ABClaimConstants";
import { ReadOnlyFormActionsMask } from "../../../../components/ReadOnlyFormActionsMask";
import claimDefaults from "../../util/ClaimDefaults"
import { useUnsubmittedClaimsContext } from '../../providers/UnsubmittedClaimsProvider'


const FORM_NAME = "unsubmitted_claim_update_details"

export const UnsubmittedClaimUpdateDetails = () => {
  const { tenant_id, claim_id } = useParams()
  const { showErrorAlert, showSuccessAlert, showWarningAlert } = useAlerts()
  const navigate = useNavigate()
  const { buildTenantRoute } = useAccountContext()
  const { enableNavigationPrompt } = usePrompt()
  const {setCurrentPatientId, redirectToNewlyCreatedClaim, setRedirectToNewlyCreatedClaim} = useUnsubmittedClaimsContext()

  const formRef = useRef<HTMLFormElement>(null)

  const{ loading: initialLoading, data: initialData, error: initialError } = useQuery(
    GET_CLAIM,
    {
      variables: {
        claimId: claim_id,
        tenantId: tenant_id,
      },
      onCompleted: (data) => {
        const claim = _get(data, 'tenant.abClaim.abClaim', null)
        let patientId = claim?.patient?.id || ''
        setCurrentPatientId(patientId)
        setAbClaimValues(claim, reset)
        const problems = _get(data, 'tenant.abClaim.abClaim.problems', null)
        setProblems(problems, setError)
      },
      onError: (error) => {
        console.error(JSON.stringify(error, null, 2))
        showErrorAlert("Claim couldn't be retrieved.")
      },
      fetchPolicy: 'cache-and-network',
    }
  )

  const formMethods = useForm<ClaimInputType>({
    defaultValues: claimDefaults,
    schema: schema
  })
  const {
    getValues,
    handleSubmit,
    formState: { dirtyFields, isSubmitSuccessful, isSubmitting },
    resetField,
    reset,
    setError,
    watch,
    setFocus
  } = formMethods

  useEffect(() => {
    enableNavigationPrompt(!!Object.keys(dirtyFields).length, FORM_NAME)
    return () => enableNavigationPrompt(false, FORM_NAME)
  }, [Object.keys(dirtyFields).length]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({}, { keepValues: true })
    }
  }, [isSubmitSuccessful])

  React.useEffect(() => {
    const subscription = watch((data, { name, type }) => {
      // Reset fee modifier input on SC change.
      if (name === "serviceCode" && type === "change") {
        resetField("serviceCodeFeeModifiers")
        resetField("feeMod1Units")
        resetField("feeMod2Units")
        resetField("feeMod3Units")
      }
      // Update patient for patient claims list
      let patientId = data?.patient?.id || ''
      setCurrentPatientId(patientId)
    })
    return () => subscription.unsubscribe()
  }, [watch])

  const [updateAbClaim, { loading: updateLoading }] = useMutation(UPDATE_CLAIM, {
      onCompleted: (data) => {
        if (!!data.tenant.abClaim.updateAbClaim) {
          const claim = _get(data, 'tenant.abClaim.updateAbClaim', null)
          const problems = _get(data, 'tenant.abClaim.updateAbClaim.problems', null)
          if(claim.id === claim_id) {
            setAbClaimValues(claim, reset)
            setProblems(problems, setError)
          }
          if (problems.length > 0) {
           problems.filter(problem => problem.severity === 'ERROR').length === 0 ?
             showWarningAlert("Claim has been updated but contains warnings. If submitted as is, the claim may be be rejected by AHCIP.")
             :
             showWarningAlert("Claim has been updated but contains errors. It cannot be submitted until all errors are resolved.")
          } else {
            showSuccessAlert("Claim has been updated.")
            if (redirectToNewlyCreatedClaim) {
              setRedirectToNewlyCreatedClaim(false)
              navigate('../')
            }
          }
        }
      },
      onError: (error) => {
        console.error(JSON.stringify(error, null, 2))
        showErrorAlert("Claim couldn't be updated.")
      }
    }
  )

  const onSubmit = handleSubmit(async(updateData) => {
    if (updateLoading) {
      return
    }
    let cleanedUpdateData = createClaimInputFromFormData(updateData)
    await updateAbClaim({
      variables: {
        tenantId: tenant_id,
        claimId: claim_id,
        claimData: cleanedUpdateData,
      }
    })

  })

  const save = () => {
    if (formRef.current) {
      formRef.current.dispatchEvent(
        new Event("submit", { cancelable: true, bubbles: true })
      )
    }
  }

  const shortcuts = useMemo(() => ({
    "d": () => {
      save()
    },
    "Enter": () => {
      save()
    },
    "l": () => {
      save()
    },
    "f": () => {
      setFocus("facility")
    },
    "r": () => {
      setFocus("referralPractitioner")
    },
    "t": () => {
      setFocus("text")
    },
    "p": () => {
      setFocus("patient")
    },
    "e": () => {
      setFocus("encounter")
    }
  }), [setFocus])

  const handleKeyPress = useCallback((event) => {
    if (event.altKey && event.key in shortcuts){
      event.preventDefault()
      shortcuts[event.key]()
    }
  }, [setFocus])

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress)
    return () => {
      document.removeEventListener('keydown', handleKeyPress)
    };
  }, [handleKeyPress])

  const [ holdClaim ] = useMutation(HOLD_CLAIM, {
    variables: {
      claimId: claim_id,
      tenantId: tenant_id,
    },
    onCompleted: (data) => {
      const claim = _get(data, 'tenant.abClaim.holdClaim', null)
      setAbClaimValues(claim, reset)
      showSuccessAlert("Claim has been held.")
    },
    onError: (error) => {
      console.error(JSON.stringify(error, null, 2))
      showErrorAlert("Claim couldn't be held.")
    },
  })

  const [ unholdClaim ] = useMutation(UNHOLD_CLAIM, {
    variables: {
      claimId: claim_id,
      tenantId: tenant_id,
    },
    onCompleted: (data) => {
      const claim = _get(data, 'tenant.abClaim.unholdClaim', null)
      setAbClaimValues(claim, reset)
      showSuccessAlert("Claim has been unheld.")
    },
    onError: (error) => {
      console.error(JSON.stringify(error, null, 2))
      showErrorAlert("Claim couldn't be unheld.")
    },
  })

  const [ deleteClaim ] = useMutation(DELETE_CLAIM, {
    variables: {
      claimId: claim_id,
      tenantId: tenant_id,
    },
    onCompleted: (data) => {
      setRedirectToNewlyCreatedClaim(false)
      showSuccessAlert("Claim has been deleted.")
      navigate(buildTenantRoute('billing', tenant_id))
    },
    onError: (error) => {
      console.error(JSON.stringify(error, null, 2))
      showErrorAlert("Claim couldn't be deleted.")
    },
    update(cache, returnedData, {variables}) {
      cache.updateQuery({
        query: LIST_UNSUBMITTEDCLAIMS,
        variables: {tenantId: tenant_id} },
        (data) => {
          let list = [...data.tenant.abClaim.listUnsubmittedAbClaim.filter(c => c.id !== claim_id)]
          return {
            tenant: {
              abClaim: {
                listUnsubmittedAbClaim: list
              }
            }
          }
        }
      )
    }
  })

  const options = [
    {
      label: 'BASE',
      key: 'BASE',
      content: (
        <ClaimBase
          isAssessed={false}
        />
      ),
    },
    {
      label: 'OPTIONS',
      key: 'OPTIONS',
      content: (
        <ClaimOptions.Form />
      )
    },
  ]

  const hasErrors = getValues('claimStatus') === AbClaimStatus.DRAFT
  const isReady = getValues('claimStatus') === AbClaimStatus.READY

  const UpdateClaimHeader = () => {
    if(!initialLoading && initialData){
      return <Box
        display={'flex'}
        flexDirection={'row'}
        sx={{
          justifyContent: 'space-between',
          alignItems: 'start',
        }}
      >
        <UnsubmittedClaimUpdateDetailsHeader identifier={getValues('claimIdentifier')} status={getValues('claimStatus')}/>
        <ReadOnlyFormActionsMask requiredType={PermissionType.Billing} requiredPermission={Permission.READWRITE}>
          <Box>
            <HoldButton
              holdClaim={holdClaim}
              unholdClaim={unholdClaim}
              hasErrors={hasErrors}
              isReady={isReady}
            />
            <DeleteButton
              onClick={deleteClaim}
            />
            <Button
              name="newClaim"
              variant={'outlined'}
              onClick={() => {
                setRedirectToNewlyCreatedClaim(false)
                navigate(buildTenantRoute('billing', tenant_id))
              }}
            >
              NEW CLAIM
            </Button>
            <SaveButton form={FORM_NAME} submitting={isSubmitting} dataTestId={'unsubmittedClaimUpdate'} />
          </Box>
        </ReadOnlyFormActionsMask>
      </Box>
    }

    return <></>
  }

  return (
    <FormProvider {...formMethods}>
      <UpdateClaimHeader />
      {initialLoading && !initialData && (
        <LoadingSpinner />
      )}

      {initialData && (
        <Box display={'flex'} flexDirection={'column'}>
          <Form onSubmit={onSubmit} autoComplete={'false'} ref={formRef} id={FORM_NAME}>
            {initialData.tenant.abClaim.abClaim.problems.some(p => p.field === 'claim' && p.severity === 'WARNING') &&
              <Alert severity="warning">
                <AlertTitle>Warning</AlertTitle>
                {initialData.tenant.abClaim.abClaim.problems.filter(p => p.field === 'claim' && p.severity === 'WARNING').map( p => <>{p.message}<br/></> )}
              </Alert>
            }
            {initialData.tenant.abClaim.abClaim.problems.some(p => p.field === 'claim' && p.severity === 'ERROR') &&
              <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                {initialData.tenant.abClaim.abClaim.problems.filter(p => p.field === 'claim' && p.severity === 'ERROR').map( p => <>{p.message}<br/></> )}
              </Alert>
            }
            <Tabs options={options} />
          </Form>
        </Box>
      )}

      {initialError && (
        <StaticPage>
          Sorry, we could not find the requested claim.
        </StaticPage>
      )}
    </FormProvider>
  )
}

export const HoldButton = ({ holdClaim, unholdClaim, sx= {}, hasErrors, isReady }) => {

  if(hasErrors) return null

  const isHeld = !hasErrors && !isReady
  return (
    <Button
      name={'hold-claim'}
      onClick={isHeld ? unholdClaim : holdClaim}
      variant={'text'}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {isHeld &&
          <PauseCircleOutline
            sx={{
              mr: 0.5,
              ...sx
            }}
          />
        }
        {!isHeld &&
          <PlayCircleOutlineIcon
            sx={{
              mr: 0.5,
              ...sx
            }}
          />
        }
        {isHeld ? 'Unhold' : 'Hold'}
      </Box>
    </Button>
  )
}
