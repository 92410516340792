import { TaskStateModal } from './TaskStateModal'
import React, { useEffect } from 'react'
import { useForm } from 'saga-library/src'
import { TaskState } from '../../../../types/tasks/TaskState'
import { taskStateDefaults } from './TaskStateDefaultValues'
import { schema } from './TaskStateValidationsSchema'
import { useMutation } from '@apollo/client'
import { useAlerts } from 'saga-library/src/providers/Alerts'
import { UPDATE_TASK_STATE } from '../../../../graphql-definitions/tenant/TaskQueries'
import { useParams } from 'react-router-dom'

const FORM_NAME = 'EditTaskStateModal'

export const EditTaskStateModal = ({taskState, onClose}:{taskState:TaskState|null, onClose:()=>void}) => {

  const { tenant_id } = useParams()

  const { showErrorAlert, showSuccessAlert } = useAlerts()

  const formMethods = useForm<TaskState>({
    defaultValues: taskStateDefaults,
    schema: schema,
  })

  const {
    handleSubmit,
    reset
  } = formMethods

  useEffect(() => {
    if (taskState) {
      reset(taskState)
    }
  }, [taskState])

  const [updateTaskState] = useMutation(UPDATE_TASK_STATE, {
    onCompleted: (data) => {
      showSuccessAlert('Task state has been saved.')
      onClose()
    },
    onError: (error: any) => {
      const errors = error.networkError?.result?.errors || []
      if (errors.length > 0 && errors[0].extensions?.userError === true) {
        showErrorAlert(errors[0].message)
      } else {
        showErrorAlert("Task state couldn't be created.")
      }
    }
  })

  const onSubmit = handleSubmit( async (data:TaskState) => {
    let input:any = {...data}
    delete input.id
    delete input.order
    await updateTaskState(    {
      variables:{
        tenantId: tenant_id,
        taskStateId: data?.id,
        input: input
      }
    })
  })

  return (
    <TaskStateModal
      open={!!taskState}
      onClose={onClose}
      formName={FORM_NAME}
      formMethods={formMethods}
      onSubmit={onSubmit}
    />
  )
}