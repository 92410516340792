import { PrintIconButton } from 'saga-library/src'
import React, { useState } from 'react'
import { useActivityTracking, UserActivityObjectType } from '../../../../hooks/useActivityTracking'
import { useImportedDocumentsStateContext } from './ImportedDocumentsStateProvider'

export const ImportedDocumentsPrintButton = () => {
  const [loading, setLoading] = useState(false)
  const { selectedFile, pdfDocRef, pdfViewerRef } = useImportedDocumentsStateContext()
  const activityTracking = useActivityTracking()

  const printPdfCallback = (data) => {
    if(data.state !== 'end') return
    if(!selectedFile?.id) return
    activityTracking.logPrintActivity(UserActivityObjectType.FILE, selectedFile.id)
    setLoading(false)
  }

  const printPdfViewer = async () => {
    if (!pdfDocRef?.current || !selectedFile || !pdfViewerRef?.current) return

    setLoading(true)
    let totalPageCount = pdfDocRef.current.getPageCount()

    await pdfViewerRef.current?.printEx({ type: 0, pageRange: `1-${totalPageCount}`, progress: true }, (data) => {
      printPdfCallback(data)
    })
  }

  return (
    <PrintIconButton
      disabled={loading}
      onClick={printPdfViewer}
      dataTestId={'imported-documents'}
    />
  )
}
