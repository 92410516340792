import React, { useState } from "react";
import { Button, Typography } from "saga-library/src";
import { Box, List, Menu, MenuItem, Tooltip } from "@mui/material";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

export const ResubmitOptionsList = ({
                                      onResubmitButtonClick,
                                      onReassessOptionClick,
                                      onDeleteOptionClick,
                                      onChangeOptionClick,
                                      expand
                                    }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget)

  const handleClose = () => {
    setAnchorEl(null)
    onResubmitButtonClick()
  }

  return (
      <List >
        <Button name='resubmit' onClick={(e) => {
          onResubmitButtonClick()
          handleClick(e)
        }
        }>
          <Box display={'flex'} flexDirection={'row'} >
            <Typography variant={'body1'} fontWeight={'bold'}>
              RESUBMIT
            </Typography>
            {expand ? <ExpandLess /> : <ExpandMore />}
          </Box>
        </Button>
        <Menu open={expand} anchorEl={anchorEl} onClose={handleClose}>
          <Tooltip title='Select this option when text has been added but no other changes have been made a claim.' placement='bottom'>
            <MenuItem
              onClick={onReassessOptionClick}
              sx={{borderRadius: 1, width: '142.368px'}}
            >
              FOR REASSESSMENT
            </MenuItem>
          </Tooltip>
          <Tooltip title='Select this option when fields other than Text have been changed for a claim.' placement={'bottom'}>
            <MenuItem
              onClick={onChangeOptionClick}
              >
              AS CHANGED CLAIM
            </MenuItem>
          </Tooltip>
          <Tooltip title='Select this option to request that a claim is reversed by AHCIP.' placement={'bottom'} >
            <MenuItem
              onClick={onDeleteOptionClick}
              >
              FOR DELETION
            </MenuItem>
          </Tooltip>
        </Menu>
      </List>
  )
}