import React, { useEffect, useState } from 'react'
import SettingsHeader from '../../components/SettingsHeader'
import { SettingsSectionColumn } from '../../components/SettingsSectionColumn'
import { TableList, type TableListHeaderConfig, TableListRowConfig } from 'saga-library/src'
import { useQuery } from '@apollo/client'
import { LIST_TASK_TEMPLATES } from '../../../../graphql-definitions'
import { useParams } from 'react-router-dom'
import _get from 'lodash/get'
import { Permission, PermissionType } from '../../../../types/settings/Permission'
import PermissionButton from '../../../../components/PermissionButton'
import { Typography } from '@mui/material'
import { TaskTemplate } from '../../../../types/tasks/TaskTemplate'
import { practitionerDisplayName, userDisplayName } from 'saga-library/src/util/formatting'
import { NewTaskTemplateDialog } from './NewTaskTemplateDialog'
import _sortBy from 'lodash/sortBy'

const columnsHeaders: TableListHeaderConfig[] = [
  { name: 'Template name' },
  { name: 'Type' },
  { name: 'Task name' },
  { name: 'Assigned to' },
  { name: 'Practitioner' }
]

const getAssignedTo = (taskTemplate: TaskTemplate) => {
  if (!taskTemplate.assignedRole && !taskTemplate.assignedUser) {
    return ""
  }

  if (!!taskTemplate.assignedRole) {
    return taskTemplate.assignedRole.name
  }

  return userDisplayName(taskTemplate.assignedUser!.firstName, taskTemplate.assignedUser!.lastName)
}

const TaskTemplateRow = (taskTemplate: TaskTemplate): TableListRowConfig => {
  return {
    key: taskTemplate.id,
    onClick: () => console.log("TODO"),
    rowData: [
      {
        children: [
          <Typography key={`${taskTemplate.id}-name`} variant={'body2'}>
            {taskTemplate.name}
          </Typography>
        ]
      },
      {
        children: [
          <Typography key={`${taskTemplate.id}-type`} variant={'body2'}>
            {taskTemplate.type.type}
          </Typography>
        ]
      },
      {
        children: [
          <Typography key={`${taskTemplate.id}-taskName`} variant={'body2'} maxWidth={312}>
            {taskTemplate.taskName}
          </Typography>
        ]
      },
      {
        children: [
          <Typography key={`${taskTemplate.id}-assignedTo`} variant={'body2'}>
            {getAssignedTo(taskTemplate)}
          </Typography>
        ]
      },
      {
        children: [
          <Typography key={`${taskTemplate.id}-practitioner`} variant={'body2'}>
            {!!taskTemplate.practitioner ? practitionerDisplayName(taskTemplate.practitioner.firstName, taskTemplate.practitioner.lastName) : ""}
          </Typography>
        ]
      }
    ]
  }
}

export const TaskTemplates = () => {
  const { tenant_id } = useParams()
  const [ taskTemplates, setTaskTemplates ] = useState<TaskTemplate[]>([])
  const [ openDialog, setOpenDialog ] = useState<boolean>(false)

  const { loading, error, data } = useQuery(LIST_TASK_TEMPLATES,{
    variables: { tenantId: tenant_id },
    onError: (error) => {
      console.error(JSON.stringify(error, null, 2))
    },
    fetchPolicy: 'cache-and-network'
  })

  useEffect(() => {
    const taskTemplateList = _get(data, 'tenant.task.template.list', [])
    setTaskTemplates(sortTaskTemplateList(taskTemplateList))
  }, [data])

  return (
    <SettingsSectionColumn header={
      <SettingsHeader
        title={'Task templates'}
        dataTestId={'task-templates'}
        actions={
          <PermissionButton
            dataTestId={'task-new-template-button'}
            name={'new-template'}
            loading={loading}
            onClick={() => {
              setOpenDialog(true)
            }}
            requiredType={PermissionType.Admin} requiredPermission={Permission.READONLY}
          >
            NEW TEMPLATE
          </PermissionButton>
        }
      />
    }>
      <TableList
        loading={loading}
        columns={columnsHeaders}
        rows={[...taskTemplates.map((task) => TaskTemplateRow(task))]}
        emptyListComponent={{ message: (error && "Unable to retrieve task templates") || "Task templates appear here once created." }}
        dataTestId={'tasks-taskTemplateList'}
        leftAlignColumns={true}
      />
      <NewTaskTemplateDialog open={openDialog} setOpen={setOpenDialog} />
    </SettingsSectionColumn>
  )
}

export const sortTaskTemplateList = (list: TaskTemplate[]): TaskTemplate[] => {
  return _sortBy(list, [taskTemplate => taskTemplate.name.toLowerCase()])
}