import React, { useState } from 'react'
import { useLoadableQuery, useMutation, useQuery } from '@apollo/client'
import _get from 'lodash/get'
import {
  LIST_TASK_ASSIGNMENTS,
  LIST_TASK_STATES,
  LIST_TASK_TYPES,
  UPDATE_TASK_ASSIGNMENT_STATUSES
} from '../graphql-definitions'
import { TaskType } from '../types/tasks/TaskType'
import { TaskState } from '../types/tasks/TaskState'
import { TaskAssignmentType } from '../types/tasks/TaskAssignment'
import { useParams } from 'react-router-dom'
import { useAlerts } from 'saga-library/src/providers/Alerts'

const getTaskQueryResults = (data) => {
  return [..._get(data, 'tenant.task.assignment.list', []) as Array<TaskAssignmentType>]
}

const parseTaskQueryResults = (data) => {
  return getTaskQueryResults(data)
}

interface TaskContextInterface {
  taskTypes: TaskType[],
  taskStates: TaskState[],
  tasks: any,
  loadTasks: any,
  parseTaskQueryResults: (any) => TaskAssignmentType[],
  updateTaskStatuses: (ids: string[], stateId: string, onSuccess: ()  => void) => Promise<void>
}

const defaultTaskContext: TaskContextInterface = {
  taskTypes: [],
  taskStates: [],
  tasks: null,
  loadTasks: () => {},
  parseTaskQueryResults: parseTaskQueryResults,
  updateTaskStatuses: (ids: string[], stateId: string, onSuccess: () => void) => Promise.resolve()
}

const TaskContext = React.createContext(defaultTaskContext)

export const TaskProvider = ({ children }) => {
  const { tenant_id } = useParams()
  const { showErrorAlert, showSuccessAlert } = useAlerts()
  const [ taskTypes, setTaskTypes ] = useState<TaskType[]>([])
  const [ taskStates, setTaskStates ] = useState<TaskState[]>([])

  const [ loadTasks, tasks, { refetch: refetchTasks } ] = useLoadableQuery(LIST_TASK_ASSIGNMENTS, {
      fetchPolicy: 'cache-and-network',
      onError: (error) => {
        console.error(JSON.stringify(error, null, 2))
      }
    },
  )

  useQuery(LIST_TASK_STATES, {
    variables: { tenantId: tenant_id },
    onCompleted: (data) => {
      setTaskStates(_get(data, 'tenant.task.state.list', []))
    },
    onError: (error) => {
      console.error(JSON.stringify(error, null, 2))
    },
  })

  useQuery(LIST_TASK_TYPES, {
    variables: { tenantId: tenant_id },
    onCompleted: (data) => {
      setTaskTypes(_get(data, 'tenant.task.type.list', []))
    },
    onError: (error) => {
      console.error(JSON.stringify(error, null, 2))
    },
  })

  const [ updateTaskAssignmentStatuses ] = useMutation(UPDATE_TASK_ASSIGNMENT_STATUSES)

  const updateTaskStatuses = async (ids: string[], stateId: string, onSuccess: () => void) => {
    await updateTaskAssignmentStatuses({
      variables: {
        tenantId: tenant_id,
        ids: ids,
        stateId: stateId
      },
      onCompleted: () => {
        showSuccessAlert(`${ids.length} task(s) have been updated.`)
        onSuccess()
      },
      onError: () => {
        showErrorAlert("Task(s) couldn't be updated.")
      },
      update: () => {
        refetchTasks()
      }
    })
  }

  const providerValues = {
    taskTypes,
    taskStates,
    tasks,
    loadTasks,
    parseTaskQueryResults,
    updateTaskStatuses
  }

  return (
    <TaskContext.Provider value={providerValues}>
      { children }
    </TaskContext.Provider>
  )
}

export const useTaskContext = () => {
  return React.useContext(TaskContext)
}