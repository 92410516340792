import React from "react";
import { Typography } from "saga-library/src";
import { Box, Chip, useTheme } from "@mui/material";
import { AbClaimStatus } from "../../../../utils/ABClaimConstants";
import { ErrorIcon, HoldIcon, ReadyIcon } from "../ClaimStatusIcons";

interface UpdateClaimDetailsHeaderProps {
  identifier: string,
  status: string,
}
export const UnsubmittedClaimUpdateDetailsHeader = ({ identifier, status } : UpdateClaimDetailsHeaderProps ) => {
  const theme = useTheme()

  const iconStyle = {
    fontSize: '20px',
    mt: 0.25,
    ml: 1
  }

  const chipStyle = {
    color: 'white',
    fontSize: '12px',
    width: 'auto'
  }

  return (
    <Box sx={{padding: '8px 0px'}}>
      <Box display={'flex'} flexDirection={'row'}>
        <Typography variant={'h2'}>
          {identifier}
        </Typography>
        {status === AbClaimStatus.DRAFT && <ErrorIcon theme={theme} sx={iconStyle}/>}
        {status === AbClaimStatus.READY && <ReadyIcon theme={theme} sx={iconStyle}/>}
        {status === AbClaimStatus.HOLD && <HoldIcon theme={theme} sx={iconStyle}/>}
      </Box>
      {status === AbClaimStatus.DRAFT &&
        <Chip
          size={'small'}
          label={'CONTAINS ERRORS'}
          sx={{
            backgroundColor: theme.palette.warning.main,
            ...chipStyle
          }}
        />
      }
      {status === AbClaimStatus.READY &&
        <Chip
          size={'small'}
          label={'READY TO SUBMIT'}
          sx={{
            backgroundColor: theme.palette.primary.main,
            ...chipStyle
          }}
        />
      }
      {status === AbClaimStatus.HOLD &&
        <Chip
          size={'small'}
          label={'HELD'}
          sx={{
            backgroundColor:theme.palette.greys.medium,
            ...chipStyle
          }}
        />
      }
    </Box>
  )
}