import React, { Suspense, useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { FilterType, LabsList } from './components/Labs/LabsList'
import { useReviewContext } from './providers/ReviewProvider'
import { useParams } from 'react-router-dom'
import { LoadingSpinner } from '../../components/LoadingScreen'
import { LabsHeader } from './components/Labs/LabsHeader'
import { EmptyList, Section } from 'saga-library/src'
import { LabsResult } from './components/Labs/LabsResult'
import { ReviewLab } from '../../types/inbox/ReviewLab'
import { useLoadableQuery } from '@apollo/client'
import { GET_LAB_AND_INVESTIGATION } from '../../graphql-definitions'
import AssignedTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined'

interface LabsProps {
  dataTestId?: string
}

export const Labs = ({
  dataTestId = 'inbox'
}: LabsProps) => {
  const { user_id, tenant_id } = useParams()
  const { loadPractitionerLabs, practitionerLabs } = useReviewContext()

  const [ selectedRow, setSelectedRow ] = useState<ReviewLab | null>(null)
  const [listFilters, setListFilters] = useState({
    labsAndInvestigations: '*',
    unreviewed: 'unreviewed',
    normalAndAbnormal: '*',
  })

  const [loadGetLab, getLab] = useLoadableQuery(GET_LAB_AND_INVESTIGATION,
    {fetchPolicy: 'cache-and-network'}
  )

  const onRowClicked = (lab: ReviewLab) => {
    setSelectedRow(lab)
  }

  useEffect(() => {
    if(!tenant_id || !user_id || !loadPractitionerLabs) return

    loadPractitionerLabs({
        tenantId: tenant_id,
        practitionerId: user_id,
    })
  }, [user_id])

  useEffect(() => {
    if(!tenant_id || !user_id || !selectedRow?.abLabResult?.id || !loadGetLab) return

    loadGetLab({
      id: selectedRow?.abLabResult?.id,
      patientId: selectedRow?.abLabResult?.patientId,
      tenantId: tenant_id,
    })
  }, [selectedRow?.abLabResult?.id])

  const setFilters = (event: any, type: FilterType) => {
    switch(type){
      case 'labsAndInvestigations':
        setListFilters({...listFilters, labsAndInvestigations: event.value})
        break
      case 'unreviewed':
        setListFilters({...listFilters, unreviewed: event.value})
        break
      case 'normalAndAbnormal':
        setListFilters({...listFilters, normalAndAbnormal: event.value})
        break
    }
  }

  return (
    <Box display={'flex'} width={"100%"}>
      <Section.Column
        width={'50%'}
        sx={{ pr: 1 }}
        header={
          <LabsHeader
            setFilters={setFilters}
            dataTestId={dataTestId}
          />
        }
      >
        <Suspense fallback={<LoadingSpinner />}>
          {
            practitionerLabs && (
              <LabsList
                listFilters={listFilters}
                dataTestId={dataTestId}
                rowClicked={onRowClicked}
                isRowClicked={(lab) => lab.id === selectedRow?.id}
              />
            )
          }
        </Suspense>
      </Section.Column>
      <Suspense fallback={<LoadingSpinner />}>
        {
          !!getLab
            ? <LabsResult
                labQuery={getLab}
                dataTestId={dataTestId}
            />
            : <EmptyLabSelection />
        }
      </Suspense>
    </Box>
  )
}


const EmptyLabSelection = () => {
  return (
    <Section.Column rightPadding={1} sx={{ width: "50%" }}>
      <EmptyList icon={AssignedTurnedInOutlinedIcon} message={'Select a lab to view it.'} />
    </Section.Column>
  )
}
