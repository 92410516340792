import React, { useCallback, useEffect, useState } from 'react'
import { Controller, useFormContext } from "saga-library/src/components/Form";
import {
  DataGrid as MuiDataGrid,
  GridColDef,
  GridToolbarContainer,
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  GridSlots,
  GridRowId,
  GridRowModel,
  GridRenderEditCellParams,
  GridPreProcessEditCellProps,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridCellParams
} from '@mui/x-data-grid'
import { AddButton } from '../AddButton'
import { RemoveButton } from '../RemoveButton'
import { Box } from '@mui/material'
import { GridDatePicker } from '../DatePicker'
import moment from 'moment-timezone'
import dayjs from 'dayjs'

export const borderlessFieldsSx = {
  width: '100%',
  mt: '-8px',
  '& .MuiInputBase-input': {
    fontSize: '12px'
  },
  '& .MuiOutlinedInput-root': {
    border: 'none',
    '& fieldset': {
      border: 'none',
    },
    '&:hover fieldset': {
      border: 'none',
    },
    '&.Mui-focused fieldset': {
      border: 'none',
    }
  },
}

export type ExtendedGridColDef = GridColDef & {
  required?: boolean
};

export interface DataGridProps extends Omit<SimpleDataGridProps,  'initialRows' | 'onRowsChange'> {
  name: string
}

export const DataGrid = ({
  name,
  ...props
}: DataGridProps) => {
  const { control, setValue } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }}) => (
        <SimpleDataGrid
          {...props}
          initialRows={value || []}
          onRowsChange={(newRows) => onChange(newRows)}
          setValue={setValue}
        />
      )}
    />
  )
}

const focusOnInput = (cell: HTMLElement) => {
  if (cell) {
    cell.focus()
    setTimeout(() => {
      const inputElement = cell.querySelector('input')
      if (inputElement) {
        inputElement.focus()
      }
    }, 100)
  }
}

export const tabAutocompleteComponent = (params: GridRenderEditCellParams) => {
  return (
    (event: React.KeyboardEvent) => {
      if (event.key === 'Tab') {
        event.preventDefault()
        const nextCell = params.api.getCellElement(params.id, params.field)?.nextElementSibling  as HTMLElement
        focusOnInput(nextCell)
      }
    }
  )
}

export const blurDataGrid = async (dataGridRef: React.MutableRefObject<any>) => {
  // Remove focus from DataGrid to process row update
  if (dataGridRef.current) {
    dataGridRef.current.blur()
  }
  // Small delay required for the row update to complete
  await new Promise(resolve => setTimeout(resolve, 500))
}

interface EditToolbarProps {
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (
    newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
  ) => void;
}

export interface SimpleDataGridProps {
  initialColumns: ExtendedGridColDef[]
  initialRows: any[]
  dataTestId: string
  addLabel?: string
  checkboxSelection?: boolean
  disabled?: boolean
  format?: string
  height?: string
  onRowsChange?: (newRows: any[]) => void
  emptyListMessage?: string
  dataGridRef?: React.MutableRefObject<any>
  setValue?: (name: string, value: any, options?: any) => void
}

function convertToMoment(value: string | dayjs.Dayjs): moment.Moment {
  if (typeof value === 'string') {
    return moment(value)
  } else {
    return moment(value.toISOString()).utc()
  }
}

export const SimpleDataGrid = ({
  initialColumns,
  initialRows,
  addLabel,
  dataTestId,
  checkboxSelection = false,
  disabled,
  onRowsChange,
  format = 'YYYY/MM/DD',
  emptyListMessage = 'There are no items in this list',
  height,
  dataGridRef,
  setValue
}: SimpleDataGridProps) => {
  const [rows, setRows] = useState(initialRows);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({})

  useEffect(() => {
    setRows(initialRows)
  }, [initialRows])

  const updateEditingStatus = (model: GridRowModesModel, id?: GridRowId) => {
    if (setValue) {
      if (id && model[id]) {
        model[id].mode = GridRowModes.View
      }
      const isEditing = Object.values(model).some((rowMode) => rowMode.mode === GridRowModes.Edit)
      setValue('isEditing',isEditing, { shouldDirty: false })
    }
  }

  useEffect(() => {
    // adjust empty first row to have focus
    if (addLabel && rows.length !== Object.keys(rowModesModel).length && rows.length === 1) {
      const { id, ...rest } = rows[0];
      if (Object.values(rest).every((value) => !value)) {
        setRows([{ ...rows[0], isNew: true }]);
        setRowModesModel((oldModel) => ({
          [rows[0].id]: { mode: GridRowModes.Edit, fieldToFocus: columns[0].field },
          ...oldModel,
        }));
      }
    }
  }, [rowModesModel, rows]);

  const handleDeleteClick = (id: GridRowId) => () => {
    const updatedRows = rows.filter((row) => row.id !== id)
    updateEditingStatus(rowModesModel, id)
    setRows(updatedRows);
    if (onRowsChange) {
      onRowsChange(updatedRows);
    }
  };

  const validateDateInput = (params: GridPreProcessEditCellProps) => {
    const testDate = params.props.value
    if (testDate) {
      try {
        const newValue = convertToMoment(testDate)
        return { ...params.props, error: !newValue.isValid() };
      } catch (e) {
        return { ...params.props, error: true };
      }
    }
    return { ...params.props, error: false };
  }

  function wrapCellInError(node, error) {
    return (
      <Box
        sx={{
          width:'100%',
          border: (theme) => error ? `1px solid ${theme.palette.error.main}` : 'none',
        }}
      >
        {node}
      </Box>
    );
  }

  const columns: GridColDef[] = initialColumns.map((column) => {
    const { required, ...rest } = column;
    let tempColumn: GridColDef = {
      ...rest,
      flex: 1,
      editable: disabled ? !disabled : rest.editable,
      preProcessEditCellProps: (params) => {
        if (required) {
          return { ...params.props, error: !params.props.value }
        }
        return params.props
      },
    }

    const renderEditCell = tempColumn.renderEditCell
    if (required && renderEditCell) {
      tempColumn = {
        ...tempColumn,
        renderEditCell: (params: GridRenderEditCellParams) => {
          const { error } = params;
          return wrapCellInError(renderEditCell(params), error)
        }
      }
    }

    if (rest.field === 'date') {
      const datePicker = (params) => (
        <GridDatePicker
          dataTestId={dataTestId}
          format={format}
          {...params}
        />
      )
      tempColumn = {
        ...tempColumn,
        preProcessEditCellProps: validateDateInput,
        renderCell: params => params?.value && convertToMoment(params?.value).isValid() ?
          convertToMoment(params?.value).format(format) : params?.value,
        renderEditCell: (props: GridRenderEditCellParams) => {
          const { error } = props
          if (error && setValue) {
            setValue('isEditing', true, { shouldDirty: false })
          }
          return wrapCellInError(datePicker(props), error)
        },
        flex: undefined,
        width: 220
      };
    }

    return tempColumn
  });

  const actions: GridColDef = {
    field: 'Actions',
    type: 'actions',
    headerName: 'Actions',
    cellClassName: 'actions',
    getActions: ({ id }) => {
      return [
        <RemoveButton
          onClick={handleDeleteClick(id)}
          dataTestId={dataTestId}
          disabled={disabled}
        />
      ];
    }
  }

  const setFocusAndCursor = useCallback((id, field) => {
    setTimeout(() => {
      const editableCell = dataGridRef?.current?.querySelector(`[data-id="${id}"] [data-field="${field}"]`) as HTMLElement
      focusOnInput(editableCell)
    }, 100)
  }, [])

  function CustomToolbar(props: EditToolbarProps) {
    if (addLabel) {
      const { setRows, setRowModesModel } = props;

      const handleAddClick = () => {
        const id = crypto.randomUUID()
        setRows((oldRows) => [{ id, isNew: true }, ...oldRows]);
        setRowModesModel((oldModel) => ({
          [id]: { mode: GridRowModes.Edit, fieldToFocus: columns[0].field },
          ...oldModel,
        }))

        // Set the text cursor within the first editable cell
        setFocusAndCursor(id, columns[0].field)
      }

      return (
        <GridToolbarContainer>
          <AddButton
            label={`Add ${addLabel.toLowerCase()}`}
            dataTestId={dataTestId}
            onClick={handleAddClick}
            disabled={disabled}
          />
        </GridToolbarContainer>
      )
    }

    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel)
    updateEditingStatus(newRowModesModel)
  };

  const processRowUpdate = (newRow: GridRowModel) => {
    const updatedRow = { ...newRow };
    const updatedRows = rows.map((row) => (row.id === newRow.id ? updatedRow : row));
    setRows(updatedRows);
    if (onRowsChange) {
      onRowsChange(updatedRows);
    }
    return updatedRow;
  }

  const handleCellClick = useCallback((params: GridCellParams, event: React.MouseEvent) => {
    // Check if the cell is already in edit mode
    if (params.isEditable && params.cellMode === 'edit') {
      return
    }

    setRowModesModel((oldModel) => ({
      [params.id]: { mode: GridRowModes.Edit },
      ...oldModel,
    }))

    setFocusAndCursor(params.id, params.field)
  }, [])

  return (
    <Box sx={{ height: height || '100%' }} >
      <MuiDataGrid
        ref={dataGridRef}
        data-testid={`${dataTestId}-data-grid`}
        rows={rows}
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        processRowUpdate={processRowUpdate}
        onCellClick={handleCellClick}
        editMode={'row'}
        columns={addLabel ? [...columns, actions] : columns}
        checkboxSelection={checkboxSelection}
        disableRowSelectionOnClick={true}
        localeText={{ noRowsLabel: emptyListMessage }}
        getRowHeight={() => 40}
        disableColumnResize={!!addLabel}
        slots={{
          toolbar: CustomToolbar as GridSlots['toolbar'],
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel },
        }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: addLabel ? 10 : 18,
            },
          },
        }}
        pageSizeOptions={addLabel ? [10] : [18]}
        sx={{
          border: 'none !important',
          '& .MuiDataGrid-root .MuiDataGrid-main': {
            border: 'none',
          },
          '& .MuiDataGrid-row:hover': {
            backgroundColor: 'backgrounds.hover',
          },
          '& .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeaderTitle': {
            color: 'greys.medium',
          },
          '& .MuiDataGrid-cell--editable:hover': {
            cursor: 'pointer',
          },
        }}
      />
    </Box>
  )
}

export default DataGrid