import React, { useEffect, useMemo, useRef, useState } from 'react'
import { FormProvider, useForm } from "saga-library/src/components/Form";
import { DialogV2, LoadingButton } from "saga-library/src";
import { usePrompt } from "../../../../providers/NavigationPrompt";
import { FamilyHistoryType } from "../../../../types/FamilyHistory";
import { useHistoryContext } from '../../providers/HistoryProvider'
import { FAMILY_HISTORY_COLUMN_KEYS, FamilyHistoryForm } from './FamilyHistoryForm'
import { useReadQuery } from '@apollo/client'
import { useAlerts } from 'saga-library/src/providers/Alerts'
import { blurDataGrid } from 'saga-library/src/components/DataGrid/DataGrid'

const FORM_NAME = "edit_family_history_form"

export type FamilyHistoryFormType = {
  familyHistory: FamilyHistoryType[]
  version?: string
  isEditing?: boolean
}

const familyHistoryDefaults: FamilyHistoryFormType = {
  version: "0",
  familyHistory: [],
  isEditing: false
}

const FamilyHistoryDialog = ({ open, setOpen }) => {
  const { parseFamilyHistoryQueryResults, familyHistoryQueryRef, updateFamilyHistory } = useHistoryContext()
  const { enableNavigationPrompt } = usePrompt()
  const { showWarningAlert } = useAlerts()
  const dataGridRef = useRef<any>(null)

  const { data: familyHistoryData } = useReadQuery(familyHistoryQueryRef!)
  const familyHistory = useMemo(() => parseFamilyHistoryQueryResults(familyHistoryData), [familyHistoryData, parseFamilyHistoryQueryResults])

  const [openNavigationPrompt, setOpenNavigationPrompt] = useState<boolean | string>(false)

  const formMethods = useForm<FamilyHistoryFormType>({
    defaultValues: familyHistoryDefaults,
  })

  const {
    handleSubmit,
    formState: { dirtyFields, isSubmitting },
    reset,
    getValues
  } = formMethods

  useEffect(() => {
    if (open) {
      if (familyHistory.length === 0) {
        reset({ familyHistory: [
            {
              id: crypto.randomUUID(),
              condition: '',
              relationship: '',
              notes: '',
            }
          ]
        })
      } else {
        reset({ familyHistory: familyHistory })
      }
    }
  }, [open])

  const handleNavigationPromptDiscard = (discard: boolean) => {
    if (openNavigationPrompt) {
      setOpenNavigationPrompt(false)
      if (discard) {
        setOpen(null)
        reset({ familyHistory: familyHistory })
      }
    }
  }

  useEffect(() => {
    if (open) {
      enableNavigationPrompt(!!Object.keys(dirtyFields).length, FORM_NAME, undefined, !!openNavigationPrompt, handleNavigationPromptDiscard)
    }
    return () => enableNavigationPrompt(false, FORM_NAME)
  }, [open, Object.keys(dirtyFields).length, openNavigationPrompt]);

  const onSubmit = () => {
    setOpen(null)
  }

  const onSave = handleSubmit(async (data) => {
    await blurDataGrid(dataGridRef)
    const updatedData = getValues()
    if (updatedData.isEditing) {
      showWarningAlert(`History contains errors. Please fix before saving.`)
      return
    }

    await updateFamilyHistory(updatedData)
    setOpen(null)
  })

  const onCancel = async () => {
    await blurDataGrid(dataGridRef)
    const isEditing = getValues('isEditing')
    const updatedValues = getValues('familyHistory')
    const emptyFirstItem = familyHistory.length === 0 && updatedValues.length === 1 && FAMILY_HISTORY_COLUMN_KEYS.every(key => !updatedValues[0][key])
    if (!emptyFirstItem && (!!Object.keys(dirtyFields).length || isEditing)) {
      setOpenNavigationPrompt(true)
    } else {
      setOpen(null)
      reset({ familyHistory: familyHistory })
    }
  }

  return <DialogV2
    dataTestId={'edit-family-history-dialog'}
    title={"Family history"}
    open={!!open}
    onClose={onCancel}
    primaryAction={()=> null}
    overridePrimaryComponent={
      <LoadingButton
        name={'saveFamilyHistory'}
        form={FORM_NAME}
        dataTestId={"edit-family-history-dialog-primary-button"}
        loading={isSubmitting}
        onClick={onSave}
        variant={'contained'}
      >
        Save
      </LoadingButton>
    }
  >
    <FormProvider {...formMethods}>
      <FamilyHistoryForm
        onSubmit={onSubmit}
        dataGridRef={dataGridRef}
      />
    </FormProvider>
  </DialogV2>
}

export default FamilyHistoryDialog