export type TaskStateInput = {
  id?: string
  name: string
  category: TaskCategory
  version: string
}

export type TaskState = TaskStateInput & {
  id: string
  order: number
}

export enum TaskCategory {
  DONE = 'DONE',
  INCOMPLETE = 'INCOMPLETE',
}