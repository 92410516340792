import * as React from 'react'
import { AutocompleteProps } from "@mui/material/Autocomplete"
import { AutocompleteBase as Autocomplete } from "saga-library/src/components/Autocomplete"
import { debounce } from '@mui/material/utils'
import { DEBOUNCE_WAIT_TIME, RenderPackage, SearchQueries } from "./types"
import { gql, useLazyQuery } from "@apollo/client"
import { SimpleTextField } from "../TextField"
import { Typography } from "../Typography"
import EmptySearchIcon from "@mui/icons-material/ManageSearch"
import { AutocompleteRenderInputParams, Box, Paper } from "@mui/material";
import { Spinner } from "../Spinner";
import { AddButton } from "../AddButton";
import './SearchControl.css'
import ClearIcon from "@mui/icons-material/Clear";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

export interface SearchControlProps<
    T,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined
  > extends Omit<AutocompleteProps<
    T,
    Multiple,
    DisableClearable,
    FreeSolo
  >, 'options'|'renderInput'> {
  label: string
  placeholder?: string
  queries: SearchQueries<T>
  render: RenderPackage<T>
  inputRef?: React.Ref<HTMLInputElement>
  error: boolean
  size?: "small" | "medium" | undefined
  renderInput?: (params: AutocompleteRenderInputParams) => React.ReactNode
  onOptionsChanged?: (options: readonly T[]) => void
  disabled?: boolean
  helperText?: string
  initialOptionsArray?: T[]
  autoFocus?: boolean
  addNewName?: string
  addNewOnClick?: () => void
  onLoading?: (loading: boolean) => void
  listBoxProps?: React.HTMLAttributes<HTMLUListElement>
  autocompleteRef?: React.Ref<HTMLInputElement>
  dataTestId?: string
  name?: string
  onKeyDown?: (event: React.KeyboardEvent) => void
}

export default function SearchControl <
    T,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined = undefined
  >
({
  label,
  placeholder,
  value,
  queries,
  render,
  inputRef,
  error,
  renderInput,
  onInputChange,
  size = 'small',
  noOptionsText,
  loadingText,
  onOptionsChanged,
  disabled = false,
  helperText,
  initialOptionsArray = [],
  autoFocus = false,
  addNewName,
  addNewOnClick = () => {},
  onLoading,
  listBoxProps,
  autocompleteRef,
  dataTestId,
  name,
  onKeyDown,
  ...props
}:SearchControlProps<T, Multiple, DisableClearable, FreeSolo>) {

  //Mui control values
  const [inputValue, setInputValue] = React.useState<string>('')
  const [searchOptions, setSearchOptions] = React.useState<readonly T[]>([])
  const [initialOptions, setInitialOptions] = React.useState<readonly T[]>(initialOptionsArray)

  //GQL search values
  const [initial, { loading: iLoading}] = useLazyQuery(queries.initial?.gql || DUMMY_SEARCH, {fetchPolicy: queries.initial?.fetchPolicy})
  const [search, { loading }] = useLazyQuery(queries.search.gql, {fetchPolicy: queries.search.fetchPolicy})

  //Main search query
  const fetch = React.useMemo(
    () =>
      debounce(
        (
          request: { input: string },
          callback: (results?: readonly T[]) => void,
        ) => {
          setSearchOptions([])
          search({
            variables: {
              searchParam: request.input,
              ...queries.search.variables
            },
            onCompleted: (data) => {
              callback(queries.search.get(data))
            },
            onError: (err) => {
              console.error(err)
            },
          })
        },
        DEBOUNCE_WAIT_TIME,
      ),
    [queries.search.variables],
  )

  //Load initial options on first render and when variables change
  React.useEffect(() => {
    if(queries.initial) {
      initial({
        variables: {
          ...queries.initial?.variables
        },
        onCompleted: (data) => {
          setInitialOptions(queries.initial?.get(data) || [])
        },
        onError: (err) => {
          console.error(err)
        },
      })
    }
  }, [queries.initial?.variables])

  //Perform search on value, or input value change
  React.useEffect(() => {
    let active = true
    if(inputValue) {
      fetch({ input: inputValue }, (results?: readonly T[]) => {
        if (active) {
          setSearchOptions(results || [])
        }
      })
    }
    return () => {
      active = false
    }
  }, [value, inputValue, fetch])

  //Enable options watching
  React.useEffect(() => {
    if(onOptionsChanged) {
      if (inputValue) {
        onOptionsChanged(searchOptions)
      } else {
        onOptionsChanged(initialOptions)
      }
    }
  }, [initialOptions, searchOptions])

  React.useEffect(() => {
    if (onLoading) {
      onLoading(loading || iLoading)
    }
  }, [onLoading, loading, iLoading])

  // Enable custom paper component with add button as required
  const PaperComponentCustom = (options) => {
    const { containerProps, children } = options;
    return (
      <Paper {...containerProps}>
        {children}
        {addNewName &&
          <Box
            display={'flex'}
            justifyContent={'flex-start'}
            width={'90%'}
            sx={{ borderTop: "1px solid rgba(32, 41, 49, 0.50)", padding: '4px 0 4px 0', marginLeft: "16px"}}
          >
            <AddButton
              label={`Add new ${addNewName}`}
              onMouseDown={addNewOnClick}
              iconVariant={'outlined'}
              dataTestId={`${dataTestId}-new${addNewName}`}
            />
          </Box>
        }
      </Paper>
    );
  };

  return (
    <Autocomplete
      {...props}
      data-testid={dataTestId}
      ref={autocompleteRef}
      ListboxProps={listBoxProps}
      size={size}
      autoComplete
      includeInputInList
      forcePopupIcon={false}
      PaperComponent={PaperComponentCustom}
      disabled={disabled}
      loading={loading || iLoading}
      getOptionLabel={(option) => render.value(option as T)}
      filterOptions={(x) => x}
      options={inputValue ? searchOptions : initialOptions}
      value={value}
      noOptionsText={
        noOptionsText ? noOptionsText : <NoOptions inputValue={inputValue} />
      }
      loadingText={
        loadingText ? loadingText : <OptionsLoading />
      }
      onInputChange={(event, newInputValue, reason) => {
        setInputValue(newInputValue)
        if(onInputChange) {
          onInputChange(event, newInputValue, reason)
        }
      }}
      isOptionEqualToValue={(option: any, value: any) => {
        if (typeof option === 'string' && typeof value === 'string') {
          return option === value
        }
        const keys = ['resultType', 'id', 'code'];
        if (keys.every(key => option?.[key] === undefined && value?.[key] === undefined)) return false
        return keys.every(key => option?.[key] === value?.[key])
      }}
      renderInput={(params) => (
        renderInput ? renderInput(params) :
        <SimpleTextField
          {...params}
          name={name}
          value={value}
          dataTestId={`${dataTestId}-textfield`}
          fullWidth
          label={label}
          placeholder={placeholder}
          inputRef={inputRef}
          error={error}
          helperText={helperText}
          autoFocus={autoFocus}
          onKeyDown={onKeyDown}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              (render.endAdornment || params.InputProps.endAdornment) &&
              <>
                {render.endAdornment && render.endAdornment(value as T)}
                {params.InputProps.endAdornment}
              </>
            ),
            startAdornment: (
              (render.startAdornment || params.InputProps.startAdornment) &&
              <>
                {render.startAdornment && render.startAdornment(value as T)}
                {params.InputProps.startAdornment}
              </>
            )
          }}
        />
      )}
      renderOption={(props, option, state) => {
        return (
          <li
            {...props}
            key={(option as any).id || (option as any).code || option}
            data-testid={`${dataTestId}-option-${state.index}`}
          >
            {render.option(option, state)}
          </li>
        )
      }}
      componentsProps={{
        clearIndicator: {
          // @ts-ignore
          "data-testid": `${dataTestId}-clear-button`
        },
        popupIndicator: {
          // @ts-ignore
          "data-testid": `${dataTestId}-dropDown-button`
        }
      }}
      clearIcon={<ClearIcon fontSize="small" data-testid={`${dataTestId}-clearIcon`} />}
      popupIcon={<ArrowDropDownIcon data-testid={`${dataTestId}-dropDownIcon`} />}
    />
  )
}

//Generic no results render.
export const NoOptions = ({ inputValue }) => {
  if(inputValue) {
    return (
      <Box data-testid={'no-results-message'} display={'flex'} flexDirection={'column'} alignItems={'center'}>
        <EmptySearchIcon sx={{ height: '40px', width: '40px' }} />
        <Typography variant={'h4'} style={{textAlign: 'center'}}>
          We couldn't find any matches for <b>{inputValue}</b>.
        </Typography>
        <Typography>
          Try a different search.
        </Typography>
      </Box>
    )
  } else {
    return (
      <Box data-testid={'type-to-search-message'} display={'flex'} flexDirection={'column'} alignItems={'center'}>
        <EmptySearchIcon sx={{ height: '40px', width: '40px' }} />
        <Typography variant={'h4'}>
          Type something to search
        </Typography>
      </Box>
    )
  }
}

export const OptionsLoading = () => {
  return (
    <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
      <Spinner size={'md'} />
    </Box>
  )
}

export const DUMMY_SEARCH = gql`
    query {
        dummy
    }
`