import { ReportParameterType, ReportRunInput, ReportType } from '../../../types/Report'
import { Form, LoadingButton } from 'saga-library/src'
import { FormProvider, useForm } from 'saga-library/src/components/Form'
import React, { useEffect } from 'react'
import * as yup from 'yup'
import { ReportResultsParameter } from './ReportResultsParameter'
import { Box } from '@mui/material'
import { useTenantContext } from '../../../providers/TenantContextProvider'
import { PractitionerListType } from '../../../types/settings/Practitioner'

const getSchema = (report: ReportType, allPractitioners: PractitionerListType[]) => {
  return yup.object().shape({
    parameters: yup.array().of(
      yup.object().shape({
        objectIdReportRunParameter: yup.object()
          .default(undefined)
          .notRequired()
          .shape({
            type: yup
              .string()
              .required('Required'),
            value: yup
              .string()
              .required('Required')
          }),
        objectIdListReportRunParameter: yup.object()
          .default(undefined)
          .notRequired()
          .shape({
            type: yup
              .string()
              .required('Required'),
            value: yup
              .array()
              .transform(objectIds => {
                if (objectIds.find(o => o.id === 'allPractitioners')) {
                  return allPractitioners.map(p => p.id)
                }
                return objectIds?.map(o => o.id)
              })
              .min(1, 'Required')
              .required('Required')
            }),
        dateRangeReportRunParameter: yup.object()
          .default(undefined)
          .notRequired()
          .shape({
            type: yup
              .string()
              .required('Required'),
            valueStart: yup
              .date()
              .required('Required'),
            valueEnd: yup
              .date()
              .required('Required')
              .test('endAfterStart',
                'End date should be greater than start date',
                (valueEnd, context) => !context.parent.valueStart || !valueEnd || valueEnd >= context.parent.valueStart
              )
          })
      }).test('parameterExists',
        'Required',
        parameter => !!parameter.objectIdReportRunParameter || !!parameter.objectIdListReportRunParameter || !!parameter.dateRangeReportRunParameter
      )).length(report.parameters.length)
      .required('Required')
  }).required('Required')
}

const getDefaults = (report: ReportType) => {
  return {
    id: report.id,
    parameters: report.parameters.map(parameter => {
      switch (parameter.parameter) {
        case ReportParameterType.PRIMARY_PRACTITIONER:
          return {
            objectIdReportRunParameter: {
              type: parameter.parameter,
              value: null
            }
          }
        case ReportParameterType.CLAIM_PRACTITIONERS:
        case ReportParameterType.APPOINTMENT_PRACTITIONERS:
          return {
            objectIdListReportRunParameter: {
              type: parameter.parameter,
              value: []
            }
          }
        case ReportParameterType.SERVICE_DATE_RANGE:
        case ReportParameterType.PAYMENT_DATE_RANGE:
        case ReportParameterType.APPOINTMENT_DATE_RANGE:
          return {
            dateRangeReportRunParameter: {
              type: parameter.parameter,
              valueStart: null,
              valueEnd: null
            }
          }
      }
    })
  } as ReportRunInput
}

const FORM_NAME = "report_results_form"

export interface  ReportResultsFormProps {
  report: ReportType,
  onSubmit: (data: any) => void
}

export const ReportResultsForm = ({report, onSubmit}:ReportResultsFormProps) => {
  const { practitioners } = useTenantContext()
  const formMethods = useForm<ReportRunInput>({
    schema: getSchema(report, practitioners),
    defaultValues: getDefaults(report)
  })

  const {
    handleSubmit,
    formState: { isSubmitting },
    reset
  } = formMethods

  useEffect(() => {
    reset(getDefaults(report))
  }, [report.id, reset])

  return (
    <FormProvider {...formMethods}>
      <Form
        name={FORM_NAME}
        id={FORM_NAME}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box display={"flex"} alignItems={"center"} gap={2}>
          {report.parameters.map((parameter, index) => <ReportResultsParameter parameter={parameter} index={index}/>)}
          <LoadingButton
            name={"runReport"}
            type={'submit'}
            sx={{mb: 1}}
            dataTestId={"report-run-button"}
            loading={isSubmitting}
            form={FORM_NAME}
          >
            Run Report
          </LoadingButton>
        </Box>
      </Form>
    </FormProvider>
  )
}