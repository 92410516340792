import React, { useCallback, useEffect, useState } from "react";
import { useLazyQuery, useQuery } from "@apollo/client";
import { Outlet, useParams, useSearchParams } from "react-router-dom";
import { LoadingOverlay } from "../components/LoadingScreen";
import _get from "lodash/get";
import {
  APPOINTMENT_ROOM_ON_CHANGED, LIST_SCHEDULES,
  GET_APPOINTMENT_DATA,
  GET_SCHEDULES,
  LIST_APPOINTMENT_TYPES,
  SCHEDULE_ON_CHANGED
} from "../graphql-definitions";
import { useAlerts } from "saga-library/src/providers/Alerts";
import { useLocationContext } from "./LocationContextProvider";
import {
  getScheduleViewDates,
  parseScheduleSettings,
} from "../apps/schedule/util/scheduleFunctions";
import { practitionerDisplayName } from "saga-library/src/util/formatting";
import {
  resourceType,
  ScheduleDataInterface,
  ScheduleLayoutInterface,
  ScheduleLength,
  ScheduleViewInterface
} from "../types/schedule/Schedule";
import { useTenantContext } from "./TenantContextProvider";
import { Dictionary, findIndex } from "lodash";
import { isEqual } from "lodash";
import { AppointmentSlotType } from "../types/schedule/Appointment";
import {
  ScheduleDialogActions, shuttleBayDefaults, ShuttleBayType, tempEventDefaults, TempEventHandlerType,
  useAppointmentSlot, useShuttleBay, useTempEvent
} from '../utils/ScheduleProviderUtils'
import { AppointmentRoom } from "../types/schedule";
import { ScheduleSettingsDialog } from "../apps/schedule/components/ScheduleSettingsDialog";
import {
  defaultUseScheduleItemActionsProps, useScheduleItemActions,
  UseScheduleItemActionsProps
} from "../apps/schedule/util/useScheduleItemActions";
import { useLastViewed } from "../apps/schedule/util/useLastViewed";
import { EventDialogHandler, EventDialogOpenProps } from "../apps/schedule/components/EventDialogHandler";
import {
  AppointmentCheckDialog,
  AppointmentCheckDialogOpenProps
} from "../apps/schedule/components/AppointmentCheckDialog";

export interface ScheduleContextInterface {
  loading?: boolean
  scheduleView: ScheduleViewInterface
  setScheduleView: (scheduleView:ScheduleViewInterface) => void

  getScheduleData: (scheduleIds:string) => ScheduleDataInterface | null
  goToScheduleOrAppointment: (scheduleId: string|null, appointmentId: string|null) => Promise<string | null>
  openScheduleIdSettings: (scheduleId: string) => void
  openEventDialog: (
    action: ScheduleDialogActions,
    eventData: any|null
  ) => void
  selectedScheduleIds: string[]
  listSchedules: any[]
  shuttleBay: ShuttleBayType,
  tempEventHandler:  TempEventHandlerType,
  appointmentSlot: {
    slotInfo: AppointmentSlotType | null,
    gotoSlot: (appointmentSlot: AppointmentSlotType)=>void,
    clearSlot: ()=>void
  }
  schedulesData: ScheduleLayoutInterface
  scheduleItemActions: UseScheduleItemActionsProps
  displayScheduleError: (error: any, type: string, ending?: string) => void
}

const defaultScheduleView : {view: ScheduleLength, date: string, schedules: string[]} = {
  view: ScheduleLength.DAY,
  date: '',
  schedules: []
}

const defaultScheduleContext: ScheduleContextInterface = {
  loading: false,
  scheduleView: defaultScheduleView,
  setScheduleView: (scheduleView) => {},
  getScheduleData: (scheduleIds) => null,
  openScheduleIdSettings: (scheduleId) => {},
  goToScheduleOrAppointment: (scheduleId, appointmentId) => Promise.resolve(null),
  shuttleBay: shuttleBayDefaults,
  tempEventHandler: tempEventDefaults,
  appointmentSlot: {
    slotInfo: null,
    gotoSlot: (appointmentSlot)=>null,
    clearSlot: ()=>null
  },
  scheduleItemActions: defaultUseScheduleItemActionsProps,
  schedulesData: {
    resources: undefined,
    rooms: undefined,
    defaultClosedHours:[],
    scheduleEndEvents:[],
  },
  openEventDialog: (eventData) => {},
  selectedScheduleIds: defaultScheduleView.schedules,
  listSchedules: [],
  displayScheduleError: (error, type, ending) => {}
}

const SCHEDULE_PROVIDER_UNMOUNT_EVENT = "scheduleContextProviderUnmount";

const ScheduleContext = React.createContext(defaultScheduleContext)
export const ScheduleContextProvider = () => {
  const { tenant_id } = useParams()
  const [ searchParams, setSearchParams ] = useSearchParams()
  const { selectedLocationId, locations, handleLocationChange, getLocationName } = useLocationContext()
  const { showErrorAlert } = useAlerts()
  const { getTenantSettings, tenantSettingsKeys } = useTenantContext()
  const tempEventActions = useTempEvent()
  const { timezone } = getTenantSettings([tenantSettingsKeys.TIMEZONE])
  const { getLastViewed, setLastViewed } = useLastViewed()

  const [ currentScheduleView, setCurrentScheduleView ] = useState<ScheduleViewInterface | null>(null)
  const [ allSchedules, setAllSchedules ] = useState<any[]>([])
  const [ schedulesData, setSchedules ] = useState<ScheduleLayoutInterface>(defaultScheduleContext.schedulesData)
  const [ openScheduleSettings, setOpenScheduleSettings ] = useState<string | null>(null)
  const [ openConfirmationDialog, setOpenConfirmationDialog ] = useState<AppointmentCheckDialogOpenProps | null>(null)
  const [ openEventDialog, setOpenEventDialog ] = useState<EventDialogOpenProps | null>(null)
  const [ subscriptionDictionary, setSubscriptionDictionary] = useState<Dictionary<Function>>({})

  const {loading } = useQuery(LIST_SCHEDULES,{
    fetchPolicy: 'cache-first',
    variables: {
      tenantId: tenant_id
    },
    onCompleted: async(data) => {
      const retrievedSchedules = _get(data, 'tenant.schedule.listSchedules', [])
      setAllSchedules(retrievedSchedules || [])
      await setScheduleView(getLastViewed(retrievedSchedules))
    }
  })

  const { data : allAppointmentTypesData } = useQuery(LIST_APPOINTMENT_TYPES, {
    variables: { tenantId: tenant_id},
    onError: (error) => {
      console.error(JSON.stringify(error, null, 2))
    },
  })

  const [ getAppointmentData ] = useLazyQuery(
    GET_APPOINTMENT_DATA,{
      fetchPolicy: 'cache-and-network',
      onError: (error) => {
        showErrorAlert('Unable to retrieve appointment information')
        console.error(JSON.stringify(error, null, 2))
      }
    }
  )

  const { data: getSchedulesData, loading: getSchedulesLoading, subscribeToMore: subscribeToMoreSchedules, refetch } = useQuery(
    GET_SCHEDULES,
    {
      variables: {
        tenantId: tenant_id,
        scheduleIdList: currentScheduleView?.schedules,
      },
      skip: !currentScheduleView,
    }
  )

  const displayScheduleError = (error, type, ending = "created") => {
    console.error(JSON.stringify(error, null, 2))
    const errors = error.networkError?.result?.errors || []
    if (errors.length > 0 && errors[0].extensions?.userError === true) {
      showErrorAlert(errors[0].message)
    } else {
      showErrorAlert(`${type} could not be ${ending}.`)
    }
  }

  const setScheduleView = async(newView:ScheduleViewInterface)=> {
    if(!isEqual(newView, currentScheduleView)) {
      if(!isEqual(newView.schedules, currentScheduleView?.schedules)){
        await refetch({tenantId: tenant_id, scheduleIdList: newView.schedules})
      }
      setCurrentScheduleView(newView)
    }
  }

  const removeUnnecessaryRoomSubscriptions = (rooms: AppointmentRoom[]) => {
    if (subscriptionDictionary) {
      for (const [key] of Object.entries(subscriptionDictionary)) {
        if (key.includes('roomOnChanged')) {
          if (findIndex(rooms, { id: key.split('_')[2] }) === -1) {
            //console.debug(`unsubscribing (rooms) ${key}`)
            subscriptionDictionary[key]()
            setSubscriptionDictionary(({ [key]: toDelete, ...rest }) => rest);
          }
        }
      }
    }
  }

  const setupRoomSubscriptions = (rooms: AppointmentRoom[], subscribeToMore: Function) => {
    rooms.map((room) => {
      if (subscriptionDictionary && subscriptionDictionary[`${tenant_id}_roomOnChanged_${room.id}`]) {
        //only subscribe once
      } else {
        const stopSubscription = subscribeToMore({
          document: APPOINTMENT_ROOM_ON_CHANGED,
          variables: { tenantId: tenant_id, roomId: room.id }
        })
        //console.debug(`subscribing ${tenant_id}_roomOnChanged_${room.id}`)
        setSubscriptionDictionary(prevState => ({ ...prevState, [`${tenant_id}_roomOnChanged_${room.id}`]: stopSubscription }))
      }
      return room
    })
  }

  useEffect(() => {
    //the unmount action needs to be decoupled from the changes to the subscriptionDictionary
    return () => {
      let e = new Event(SCHEDULE_PROVIDER_UNMOUNT_EVENT);
      document.dispatchEvent(e);
    }
  }, []);

  useEffect(() => {
    function doOnUnmount() {
      //TODO: will have to save schedule time (vscroll position) at some point, 🤞 Mobiscroll makes it possible
      // if(currentScheduleView) {
      //   setLastViewed(currentScheduleView)
      // }

      if (subscriptionDictionary) {
        for (const [key] of Object.entries(subscriptionDictionary)) {
          subscriptionDictionary[key]()
        }
      }
    }
    document.addEventListener(SCHEDULE_PROVIDER_UNMOUNT_EVENT, doOnUnmount);
    return () => {
      document.removeEventListener(SCHEDULE_PROVIDER_UNMOUNT_EVENT, doOnUnmount)
    }
  }, [subscriptionDictionary])

  useEffect(() => {
    const currentSchedules = _get(getSchedulesData, 'tenant.schedule.schedules', [])
    let currentSubscriptions = { ...subscriptionDictionary }
    let currentScheduleIds = currentSchedules.map((schedule) => schedule.id)

    if (currentSubscriptions) {
      for (const [key] of Object.entries(currentSubscriptions)) {
        if (key.includes('scheduleOnChanged')) {
          if (!currentScheduleIds.includes(key.split('_')[2])) {
            console.debug(`unsubscribing (schedules) ${key}`)
            currentSubscriptions[key]()
            delete currentSubscriptions[key]
          }
        }
      }
    }

    currentScheduleIds.map((id) => {
      if (currentSubscriptions && currentSubscriptions[`${tenant_id}_scheduleOnChanged_${id}`]) {
        return id
      } else {
        console.debug(`subscribing ${tenant_id}_scheduleOnChanged_${id}`)
        const stopSubscription = subscribeToMoreSchedules({
          document: SCHEDULE_ON_CHANGED,
          variables: { tenantId: tenant_id, scheduleId: id }
          })
        currentSubscriptions = ({ ...currentSubscriptions, [`${tenant_id}_scheduleOnChanged_${id}`]: stopSubscription })
      }
      return id
    })

    setSubscriptionDictionary(currentSubscriptions)
  }, [getSchedulesData])

  useEffect( ()=> {
    if(currentScheduleView) {
      setLastViewed(currentScheduleView)
    }
  },[currentScheduleView])

  useEffect( () => {
    setScheduleLayout()
  }, [currentScheduleView, getSchedulesData])

  const setScheduleLayout = useCallback(() => {
    const currentSchedules = _get(getSchedulesData, 'tenant.schedule.schedules', [])
    const currentRooms = _get(getSchedulesData, 'tenant.schedule.room.rooms', [])

    const scheduleDates = getScheduleViewDates(currentScheduleView?.date, currentScheduleView?.view)
    let scheduleAvailability = parseScheduleSettings(currentSchedules, scheduleDates.start, scheduleDates.end, timezone)

    let scheduleResources: resourceType[]| undefined = undefined

    if (currentScheduleView && currentScheduleView.schedules.length > 0) {
      removeUnnecessaryRoomSubscriptions(currentRooms)
      if(currentRooms.length > 0) {
        setupRoomSubscriptions(currentRooms, subscribeToMoreSchedules)
      }
      const schedules = allSchedules.filter(s => currentScheduleView?.schedules.some(sId => sId === s.id))
      scheduleResources = schedules.map(s => {
        return {
          id: s.id,
          name: practitionerDisplayName(s.practitionerFirstName, s.practitionerLastName),
          location: getLocationName(s.locationId)
        }
      })
    }

    setSchedules({
      ...scheduleAvailability,
      resources: scheduleResources,
      rooms: currentRooms,
      defaultClosedHours: scheduleAvailability.closedHours,
    })
  },[getScheduleViewDates, parseScheduleSettings, currentScheduleView, getSchedulesData, timezone, allSchedules])

  const setEventDialog = (action, eventData) => setOpenEventDialog({action:action, eventData: eventData})

  const getScheduleData = useCallback((scheduleId):ScheduleDataInterface|null => {
    const currentSchedules = _get(getSchedulesData, 'tenant.schedule.schedules', [])

    const scheduleMeta = allSchedules && allSchedules.find(s => scheduleId === s.id)
    const schedule = currentSchedules.find(s => scheduleId === s.id)
    const filteredTypes = _get(allAppointmentTypesData, "tenant.schedule.type.listTypes", []).filter(t => t.allSchedules || (t.schedules.findIndex(s => s.id === scheduleId) !== -1))
    let defaultAppointmentTypeId = schedule?.defaultAppointmentTypeId
    if (!filteredTypes.find(t => t.id === defaultAppointmentTypeId)) {
      defaultAppointmentTypeId = ''
    }

    if(schedule && scheduleMeta) {
      return {
        practitionerName: practitionerDisplayName(scheduleMeta.practitionerFirstName, scheduleMeta.practitionerLastName),
        practitionerId: scheduleMeta.id,
        defaultBillingProfileId: schedule?.defaultBillingProfileId,
        defaultAppointmentTypeId: defaultAppointmentTypeId,
        appointmentTypes: filteredTypes,
        id: scheduleMeta.scheduleId,
        locationId: scheduleMeta.locationId,
        endDate: schedule?.endDate
      }
    }
    return null
  }, [allSchedules, getSchedulesData, allAppointmentTypesData, practitionerDisplayName])

  const gotoSchedule = (scheduleId, date):Promise<void> => {
    return new Promise<void>( (resolve, reject) => {
      const schedule = allSchedules.find(s => scheduleId === s.id)
      const existingView = currentScheduleView ? currentScheduleView : defaultScheduleView

      if (schedule !== null) {
        if (selectedLocationId === schedule.locationId) {
          setCurrentScheduleView({ ...existingView, date: date || existingView.date, schedules: [scheduleId] })
          resolve()
        } else {
          const hasLocation = locations.findIndex(l => l.id === schedule.locationId) > -1
          if (hasLocation) {
            handleLocationChange(schedule.locationId)
            setTimeout(() => {
              //PractitionerScheduleListSelect component's default behaviour is to clear selected practitioners,
              // the delay is to let that happen and then set the selected schedule.
              refetch({ tenantId: tenant_id, scheduleIdList: [scheduleId] })
              setCurrentScheduleView({ ...existingView, date: date || existingView.date, schedules: [scheduleId] })
              resolve()
            }, 0)
          } else {
            showErrorAlert('Schedule is not accessible')
            reject()
          }
        }
      } else {
        showErrorAlert('Schedule is not accessible')
        reject()
      }
    })
  }

  const goToScheduleOrAppointment = (scheduleId, appointmentId):Promise<null | string> => {
    return new Promise<null | string>( (resolve, reject) => {
      if(appointmentId){
        getAppointmentData({
          variables:{
            tenantId: tenant_id,
            appointmentId: appointmentId
          },
          onCompleted: data => {
            const appointmentData = _get(data, 'tenant.schedule.appointment.get',null)
            if(scheduleId && (appointmentData && appointmentData.scheduleId !== scheduleId)){
              showErrorAlert('Appointment is not on provided schedule')
              resolve(null)
            }
            gotoSchedule(appointmentData?.scheduleId, appointmentData?.start)
              .then(() => resolve(appointmentId))
              .catch(() => reject())
            resolve(appointmentId)
          }
        })
      }

      if (scheduleId){
        gotoSchedule(scheduleId, null)
          .then(() => resolve(null))
          .catch(() => reject())
      }
    })
  }

  const gotoAppointmentSlot = (appointmentSlot) => {
    if(appointmentSlot) {
      const existingView = currentScheduleView ? currentScheduleView : defaultScheduleView
      setCurrentScheduleView({ ...existingView })
    }
  }

  const shuttleBay = useShuttleBay()
  const handleShuttledAppt = async(event) => {
    delete event.shuttleBay
    shuttleBay.setUnoccupied()
    return event
  }

  const scheduleItems = useScheduleItemActions({
    scheduleView:currentScheduleView!,
    scheduleData:schedulesData,
    tempEventActions,
    setOpenConfirmationDialog,
    setOpenEventDialog,
    getScheduleData,
    handleShuttledAppt
  })

  let providerValues = {
    loading: getSchedulesLoading,
    scheduleView: currentScheduleView || defaultScheduleView,
    setScheduleView,
    getScheduleData,
    goToScheduleOrAppointment,
    openScheduleIdSettings: setOpenScheduleSettings,
    selectedScheduleIds: currentScheduleView?.schedules || [],
    listSchedules: allSchedules || [],
    shuttleBay,
    appointmentSlot: useAppointmentSlot(gotoAppointmentSlot),
    tempEventHandler: tempEventActions,
    scheduleItemActions: scheduleItems,
    schedulesData,
    openEventDialog: setEventDialog,
    displayScheduleError
  }

  const onOkay = (event) => {
    setEventDialog(ScheduleDialogActions.CREATE, event)
  }

  const onMove = async (event) => {
    if (event.shuttleBay) {
      event.type = null
      await handleShuttledAppt(event)
    }
    scheduleItems.updateEvent({
      ...event,
      resource: event.resource|| currentScheduleView?.schedules[0]
    })
  }

  if(loading || !currentScheduleView ){
    return <LoadingOverlay
      loading={loading}
      message={'Loading schedules...'}
    />
  }

  if(currentScheduleView) {
    return (
      <ScheduleContext.Provider value={providerValues}>
        <Outlet />
        <ScheduleSettingsDialog
          onClose={() => { setOpenScheduleSettings(null) }}
          scheduleId={openScheduleSettings}
        />

        <EventDialogHandler
          open = {openEventDialog}
          onClose={() => {
            tempEventActions.clearTempEvent()
            setOpenEventDialog(null)
            if (searchParams.has('aId')) {
              searchParams.delete('aId')
              setSearchParams(searchParams)
            }
          }}
        />

        <AppointmentCheckDialog
          checks={openConfirmationDialog?.checks || []}
          eventData={openConfirmationDialog?.eventData}
          onCreate={onOkay}
          onMove={onMove}
          onClose={() => {
            setOpenConfirmationDialog(null)
          }}
          appointmentMove={openConfirmationDialog?.appointmentMove}
        />
      </ScheduleContext.Provider>
    )
  }

  return <></>
}

export const useScheduleContext = () => {
  return React.useContext(ScheduleContext)
}
