import * as yup from 'yup'

export const schema = yup.object({
  name: yup.string().nullable(),
  typeId: yup.string().required("Required"),
  assignedTo: yup.object().required("Required"),
  stateId: yup.string().required("Required"),
  priority: yup.string().nullable(),
  startDate: yup.date().typeError("Invalid date"),
  dueDate: yup.date().typeError("Invalid date"),
  practitionerId: yup.string().nullable(),
  description: yup.string().nullable()
})