import { useParams } from "react-router-dom";
import { useAlerts } from "saga-library/src/providers/Alerts";
import { FormProvider, useForm } from "saga-library/src/components/Form";
import { useMutation } from "@apollo/client";
import _get from "lodash/get";
import { DialogV2 } from "saga-library/src";
import React, { useState, useEffect } from "react";
import { usePrompt } from "../../../../providers/NavigationPrompt";
import { TaskStateForm } from "./TaskStateForm";
import { CREATE_TASK_STATE, LIST_TASK_STATES } from "../../../../graphql-definitions";
import { TaskStateInput } from '../../../../types/tasks/TaskState'
import { schema } from './TaskStateValidationsSchema'
import { taskStateDefaults } from './TaskStateDefaultValues'
import { TaskStateModal } from './TaskStateModal'

const FORM_NAME = 'new_task_state_form'

interface NewTaskStateModalProps {
  open: boolean
  setOpen: (a: boolean) => void
}

export const NewTaskStateModal = ({ open, setOpen }: NewTaskStateModalProps) => {
  const { tenant_id } = useParams()
  const { showErrorAlert, showSuccessAlert } = useAlerts()

  const formMethods = useForm<TaskStateInput>({
    defaultValues: taskStateDefaults,
    schema: schema,
  })

  const {
    handleSubmit,
    reset,
  } = formMethods

  const clearFields = () => {
    reset(taskStateDefaults)
    setOpen(false)
  }


  const [addNewTaskState] = useMutation(CREATE_TASK_STATE, {
    onCompleted: (data) => {
      showSuccessAlert('Task state has been saved.')
      clearFields()
    },
    onError: (error: any) => {
      const errors = error.networkError?.result?.errors || []
      if (errors.length > 0 && errors[0].extensions?.userError === true) {
        showErrorAlert(errors[0].message)
      } else {
        showErrorAlert("Task state couldn't be created.")
      }
    }
  })

  const onSubmit = handleSubmit(async (data) => {
    await addNewTaskState({
      variables: {
        tenantId: tenant_id,
        input: data
      },
      update(cache, returnedData) {
        const taskStateId = _get(returnedData, 'data.tenant.task.state.create')
        cache.updateQuery({
            query: LIST_TASK_STATES,
            variables: {tenantId: tenant_id}},
          (listData) => {
            const allStates = _get(listData, 'tenant.task.state.list', [])
            const newTaskState = {
              id: taskStateId,
              order: allStates.length,
              __typename: "TaskState",
              ...data
            }
            return {
              tenant: {
                task: {
                  state: {
                    list: [...allStates, newTaskState]
                  }
                }
              }
            }
          }
        )
      },
    })
  })

  return (
    <TaskStateModal
      open={open}
      onClose={clearFields}
      formName={FORM_NAME}
      formMethods={formMethods}
      onSubmit={onSubmit}
    />
  )
}
