import React, { useEffect, useState } from 'react'
import { BackButton, ConfirmationDialog, RemoveButton, Section } from 'saga-library/src'
import { useNavigate, useParams } from 'react-router-dom'
import { Box } from '@mui/material'
import { ReportForm } from './reportForm/ReportForm'
import { Permission, PermissionType } from '../../../types/settings/Permission'
import PermissionButton from '../../../components/PermissionButton'
import { FormProvider, useForm } from 'saga-library/src/components/Form'
import {
  REPORT_CATEGORIES,
  REPORT_COLUMNS,
  REPORT_PARAMETERS,
  ReportCategoryType,
  ReportCombinatorType,
  ReportInputType
} from '../../../types/Report'
import { reportFormDefaultValues } from './reportForm/ReportFormDefaultValues'
import { schema } from './reportForm/ReportFormValidationSchema'
import { usePrompt } from '../../../providers/NavigationPrompt'
import { useReportContext } from '../../../providers/ReportProvider'
import { flushSync } from 'react-dom'
import { useAccountContext } from '../../../providers/AccountContext'
import { HasPermissionTemplate } from '../../../components/HasPermissionTemplate'
import { convertQueriesToQueryInputs } from './ReportUtil'
import { defaultReportQueryInput } from '../../../components/ReportQueryBuilder/ReportQueryBuilderFields'

const FORM_NAME = "report_form"

export const ReportEditor = () => {
  const { enableNavigationPrompt, clearNavigationPrompt } = usePrompt()
  const navigate = useNavigate()
  const { buildTenantRoute } = useAccountContext()
  const { tenant_id, report_id, category_type } = useParams()

  const { reports, createReport, updateReport, deleteReport } = useReportContext()

  const [isLoading, setIsLoading] = useState<boolean>(!!report_id)
  const report = reports.find((r) => r.id === report_id)

  const formMethods = useForm<ReportInputType>({
    defaultValues: reportFormDefaultValues,
    schema: schema
  })

  const {
    formState: { dirtyFields, isSubmitting },
    handleSubmit,
    watch,
    reset
  } = formMethods

  useEffect(() => {
    if (!!report) {
      const queryInputs = convertQueriesToQueryInputs(report.queries)
      const reportInput = {
        id: report.id,
        name: report.name,
        description: report.description,
        category: report.category,
        columns: [...report.columns]
          .sort((a, b) => (a.order - b.order))
          .map((column) => ({
            id: column.id,
            name: column.column,
            label: REPORT_COLUMNS[column.column],
            version: column.version
          })),
        parameters: report.parameters
          .map((parameter) => ({
            id: parameter.id,
            name: parameter.parameter,
            label: REPORT_PARAMETERS[parameter.parameter],
            version: parameter.version
          })),
        queries: queryInputs.length > 0 ? queryInputs : [defaultReportQueryInput],
        combinator: queryInputs.length > 0 ? queryInputs[0].combinator : ReportCombinatorType.AND,
        version: report.version
      }
      reset(reportInput)
      setIsLoading(false)
    }
  }, [report])

  useEffect(() => {
    if (!!report_id) {
      return
    }

    if (!!category_type) {
      reset({ ...reportFormDefaultValues, category: REPORT_CATEGORIES[category_type] })
    }
  }, [report_id, category_type])

  const category = watch('category')

  useEffect(() => {
    enableNavigationPrompt(!!Object.keys(dirtyFields).length, FORM_NAME)
    return () => enableNavigationPrompt(false, FORM_NAME)
  }, [Object.keys(dirtyFields).length])

  const onSubmitCreate = handleSubmit(async (data) => {
    await createReport(data, (reportResult) => {
      flushSync(() => {
        clearNavigationPrompt(FORM_NAME)
      })
      navigate(buildTenantRoute(`reports/r/${reportResult.id}`, tenant_id), { replace: true })
    })
  })

  const onSubmitEdit = handleSubmit(async (data) => {
    await updateReport(data, () => {
      reset({}, { keepValues: true })
    })
  })

  const onDelete = async(reportId) => {
    await deleteReport(reportId, category!, () => {
      flushSync(() => {
        clearNavigationPrompt(FORM_NAME)
      })
      navigate(buildTenantRoute(`reports`, tenant_id))
    })
  }

  const permissionType = category === ReportCategoryType.BILLING
    ? PermissionType.ReportingBilling
    : PermissionType.ReportingPatientAndAppointment

  if (!report && !category) {
    return <></>
  }

  return (
    <Section.Column
      header={
        <Box display={"flex"} alignItems={"center"} gap={1} mb={1}>
          {report && <BackButton onClick={() => navigate(-1)} sx={{ mx:-1 }} dataTestId={"report-back-button"} />}
          <Section.Header lineclamp={1} sx={{ flex: "1 1 100%", mb: 0 }} dataTestId={"report-section-label"}>
            {report ? report.name : `New ${category?category.toLowerCase():""} report`}
          </Section.Header>
          {(!report || !report.isReference) && (
            <Box display={"inline-flex"} gap={1}>
              {!!report && <DeleteReportConfirmation permissionType={permissionType} onDelete={() => onDelete(report.id)} />}
              <PermissionButton
                requiredType={permissionType}
                requiredPermission={Permission.READWRITE}
                name={"saveReport"}
                form={FORM_NAME}
                type={"submit"}
                loading={isSubmitting}
                dataTestId={"report-save-button"}
              >
                Save
              </PermissionButton>
            </Box>
          )}
        </Box>
      }
      rightPadding={1}
      sx={{ flex: "1 1 100%" }}
    >
      <FormProvider {...formMethods}>
        <ReportForm
          formName={FORM_NAME}
          permissionType={permissionType}
          isLoading={isLoading}
          onSubmit={!!report ? onSubmitEdit : onSubmitCreate}
          disabled={!!report && report.isReference}
          dataTestId={"report_form"}
        />
      </FormProvider>
    </Section.Column>
  )
}

const DeleteReportConfirmation = ({ permissionType, onDelete }) => {
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false)

  return (
    <HasPermissionTemplate requiredType={permissionType} requiredPermission={Permission.READWRITE}>
      <RemoveButton
        onClick={() => setOpenDeleteConfirmation(true)}
        dataTestId={'report'}
      />
      <ConfirmationDialog
        open={openDeleteConfirmation}
        title={'Delete report?'}
        message={"This action can't be undone."}
        primaryAction={() => {
          setOpenDeleteConfirmation(false)
          onDelete()
        }}
        primaryLabel={'delete'}
        onClose={() => setOpenDeleteConfirmation(false)}
        dataTestId={'delete-report-confirmation'}
      />
    </HasPermissionTemplate>
  )
}