import React, { Suspense, useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { FormSection } from './components/Tasks/FormSection'
import { useReviewContext } from './providers/ReviewProvider'
import { useParams } from 'react-router-dom'
import { DocumentsList, FilterType } from './components/Documents/DocumentsList'
import { DocumentsHeader } from './components/Documents/DocumentsHeader'
import { ComingSoonPlaceholder, Section } from 'saga-library/src'
import { LoadingSpinner } from '../../components/LoadingScreen'

interface DocumentsProps {
  dataTestId?: string
}

export const Documents = ({
  dataTestId = 'inbox'
}: DocumentsProps) => {
  const { user_id, tenant_id } = useParams()
  const { loadUserDocuments, userDocuments } = useReviewContext()

  const [listFilters, setListFilters] = useState({
    category: '*',
    unreviewed: 'unreviewed',
  })

  const setFilters = (event: any, type: FilterType) => {
    switch(type){
      case 'category':
        setListFilters({...listFilters, category: event.value})
        break
      case 'unreviewed':
        setListFilters({...listFilters, unreviewed: event.value})
        break
    }
  }

  useEffect(() => {
    if(!tenant_id || !user_id || !loadUserDocuments) return

    loadUserDocuments({
      tenantId: tenant_id,
      userId: user_id,
    })
  }, [user_id])

  return (
    <Box display={'flex'} width={"100%"}>
      <Section.Column
        width={'50%'}
        sx={{ pr: 1 }}
        header={
          <DocumentsHeader
            setFilters={setFilters}
            dataTestId={dataTestId}
          />
        }
      >
        <Suspense fallback={<LoadingSpinner />}>
          {
            userDocuments && (
              <DocumentsList
                listFilters={listFilters}
                dataTestId={dataTestId}
              />
            )
          }
        </Suspense>
      </Section.Column>
      <FormSection>
        <ComingSoonPlaceholder />
      </FormSection>
    </Box>
  )
}