import React, { Suspense } from 'react'
import { Box } from '@mui/material'
import { TableListHeaderConfig } from 'saga-library/src'
import { AllUnsubmittedClaimsListView } from "./AllUnsubmittedClaimsListView";


export const UnsubmittedClaimList = () => {
  return (
    <Box display={'flex'} flexDirection={'column'} height={'100%'}>
      <Suspense fallback={<Box>LOADING</Box>}>
        <AllUnsubmittedClaimsListView />
      </Suspense>
    </Box>
  )
}

export default UnsubmittedClaimList
export const UnsubmittedClaimColumns: TableListHeaderConfig[] = [
  { name: '' },
  { name: 'Patient' },
  { name: 'Service date' },
  { name: 'Service' },
  { name: 'Calls' },
  { name: 'Diagnoses' },
  { name: 'Practitioner' },
  { name: 'Facility' },
  { name: 'Claimed' },
]