import React from 'react'
import { Form, TextField } from 'saga-library/src'
import { AssignedTo } from '../../../../components/AssignedTo'
import { PractitionerSelect } from '../../../../components/PractitionersSelect'
import { TaskTypeSelect } from '../../../../components/TaskTypeSelect'
import { FormRow } from '../../../../components/FormRow'
import { TaskPrioritySelect } from '../../../../components/TaskPrioritySelect'

interface TaskTemplateFormProps {
  formName: string
  onSubmit: () => void
  dataTestId: string
}

export const TaskTemplateForm = ({
  formName,
  onSubmit,
  dataTestId
}: TaskTemplateFormProps) => {
  return (
    <Form onSubmit={onSubmit} id={formName}>
      <TextField
        name={'name'}
        label={'Template name'}
        fullWidth={true}
        dataTestId={`${dataTestId}-name`}
      />
      <AssignedTo
        name={"assignedTo"}
        includeRoles={true}
        dataTestId={`${dataTestId}-assignedTo`}
      />
      <FormRow>
        <TaskTypeSelect
          name={'typeId'}
          label={'Type'}
          dataTestId={`${dataTestId}-taskType`}
        />
        <TextField
          name={'taskName'}
          label={'Task name'}
          fullWidth={true}
          dataTestId={`${dataTestId}-taskName`}
        />
      </FormRow>
      <TextField
        name={'description'}
        label={'Description'}
        fullWidth={true}
        multiline={true}
        InputProps={{
          minRows: 2
        }}
        dataTestId={`${dataTestId}-description`}
      />
      <FormRow>
        <TaskPrioritySelect
          name={'priority'}
          label={'Priority'}
          dataTestId={`${dataTestId}-priority`}
        />
        <TextField
          name={'startOffset'}
          label={'Start date'}
          type={'number'}
          transformValue={(value) => value.replace(/\D/, '')}
          extraEndAdornment={<>days from today</>}
          InputProps={{ inputProps: { min: 0 } }}
          InputLabelProps={{ shrink: true }}
          fullWidth={true}
          dataTestId={`${dataTestId}-startOffset`}
        />
        <TextField
          name={'endOffset'}
          label={'Due date'}
          type={'number'}
          transformValue={(value) => value.replace(/\D/, '')}
          extraEndAdornment={<>days from today</>}
          InputProps={{ inputProps: { min: 0 } }}
          InputLabelProps={{ shrink: true }}
          fullWidth={true}
          dataTestId={`${dataTestId}-endOffset`}
        />
      </FormRow>
      <PractitionerSelect
        name={'practitionerId'}
        label={'Practitioner'}
        dataTestId={`${dataTestId}-practitioner`}
      />
    </Form>
  )
}