import React, { useCallback, useMemo } from 'react'
import _get from "lodash/get";
import { useParams } from "react-router-dom";
import { EntryTitle, ResultOptionsWrapper, SearchControl } from 'saga-library/src'
import { GET_SEARCH_VACCINATIONS } from '../../../graphql-definitions'
import { SxProps } from '@mui/material'
import { Theme } from '@mui/system'
import { GridRenderEditCellParams } from '@mui/x-data-grid'
import { useGenericDataGridSearch } from './GenericDataGridSearch'
import { tabAutocompleteComponent } from 'saga-library/src/components/DataGrid/DataGrid'

export type VaccinationSearchResult = {
  id: string
  name: string
}

export interface VaccinationSearchProps {
  name: string
  dataTestId: string
  placeholderText?: string
  label?: string
  sx?: SxProps<Theme>
  params: GridRenderEditCellParams
}

function NameEqual(a, b): boolean {
  if (typeof a === 'string' && typeof b === 'string') {
    return a === b
  } else if (typeof a === 'string' && typeof b === 'object') {
    return a === b.name
  } else if (typeof a === 'object' && typeof b === 'string') {
    return a.name === b
  }
  return a.id === b.id
}

export const VaccinationSearch = ({
  name,
  dataTestId,
  placeholderText='',
  label = '',
  sx,
  params
}: VaccinationSearchProps) => {
  const { tenant_id } = useParams()
  const { error } = params
  const { handleChange } = useGenericDataGridSearch<VaccinationSearchResult>('vaccinationId', 'name', params)

  const getValue = useCallback(() => {
    if (params.value)
      return params.value;
    return null;
  }, [params.value]);

  const queryVariables = useMemo(() => ({
    tenantId: tenant_id
  }), [tenant_id])

  return (
    <SearchControl<VaccinationSearchResult|string, false, false, true>
      dataTestId={`${dataTestId}-vaccination-search`}
      name={name}
      label={label}
      freeSolo
      error={error}
      placeholder={placeholderText}
      value={getValue()}
      onChange={handleChange}
      onInputChange={(event, value) => (event) && handleChange(event, value)}
      queries={{
        search: {
          gql: GET_SEARCH_VACCINATIONS,
          get: (data) => _get(data, 'tenant.search.vaccinations.searchResults', []) as VaccinationSearchResult[],
          variables: queryVariables,
          fetchPolicy: 'cache-and-network',
        },
      }}
      render={{
        value: (result) => (typeof result === 'string') ? result : result.name,
        option: (result, state) => {
          return (
            <ResultOptionsWrapper>
              <EntryTitle
                title={(typeof result === 'string') ? result : result.name}
                searchText={state.inputValue}
              />
            </ResultOptionsWrapper>
          )
        }
      }}
      onKeyDown={tabAutocompleteComponent(params)}
      isOptionEqualToValue={NameEqual}
      sx={sx}
    />
  )
}
