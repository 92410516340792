import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useForm, FormProvider } from "saga-library/src/components/Form";
import { Box, useTheme } from "@mui/material";
import { Button,  Section, Tabs, Typography, Form } from "saga-library/src";
import { Outlet, useParams } from 'react-router-dom'
import { useAlerts } from "saga-library/src/providers/Alerts"
import { ABClaimActionCode, feeModUnitDescriptionList, PayToCode } from "../../../../../utils/ABClaimConstants";
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import { BatchUpdateBase } from "./BatchUpdateBase";
import {
  AbClaimMultiple,
  AbClaimField,
  BatchClaimInputType
} from "../../../../../types/billing/AbClaim/AbClaim";
import { useMutation } from "@apollo/client";
import _get from "lodash/get";
import { BatchSaveDialog } from "../components/BatchSaveDialog";
import { BatchUpdateClaimOptions } from "./BatchUpdateClaimOptions";
import {
  setAbClaimMultiple,
  setBatchAbClaimValues
} from "../batchUpdateUtil";
import moment from "moment-timezone";
import { usePrompt } from "../../../../../providers/NavigationPrompt";
import { LIST_UNSUBMITTEDCLAIMS, UPDATE_CLAIMS } from "../../../../../graphql-definitions";
import FormColumn from "../../FormColumn";
import { useUnsubmittedClaimsContext } from '../../../providers/UnsubmittedClaimsProvider'
import UnsubmittedMultipleSelected from '../../UnsubmittedClaims/UnsubmittedMultipleSelected'


const FORM_NAME = "batch_update_details"

export const claimDefaults = {
  version: "0",
  billingProfile: null,
  facility: null,
  functionalCenter: null,
  referralPractitioner: null,
  serviceCode: null,
  diagnosticCodes: [],
  serviceCodeFeeModifiers: [],
  patient: null,
  locationCode: undefined,
  text: undefined,
  serviceDate: moment().format(),
  calls: 1,
  encounter: 1,
  paperDocumentation: false,
  confidential: false,
  originalEncounterDate: null,
  originalFacility: null,
  originalLocationCode: "",
  newBornCode: null,
  guardianUli: null,
  guardianRegNumber: "",
  skillId: undefined,
  payToCode: PayToCode.BAPY,
  payToUli: null,
  interceptReason: "",
  patientFirstName: "",
  patientLastName: "",
  patientPHN: "",
  patientDOB: null,
  patientGenderId: "",
  feeMod1Units: null,
  feeMod2Units: null,
  feeMod3Units: null,
}


export const BatchUpdateDetails = () => {
  const { tenant_id } = useParams()
  const { showErrorAlert, showSuccessAlert } = useAlerts()
  const theme = useTheme()
  const [updatedFieldValues, setUpdatedFieldValues] = useState<AbClaimField[]>([])
  const [openSaveModal, setOpenSaveModal] = useState(false)
  const formRef = useRef<HTMLFormElement>(null)

  const { selectedClaimIds } = useUnsubmittedClaimsContext()

  const { enableNavigationPrompt } = usePrompt()

  const [updateBatchClaims] = useMutation(
    UPDATE_CLAIMS,
    {
      onCompleted: (data) => {
        showSuccessAlert(`${selectedClaimIds.length} ${selectedClaimIds.length === 1? 'claim has' : 'claims have'} been updated.`)
        setOpenSaveModal(false)
      },
      onError: (error) => {
        console.error(JSON.stringify(error, null , 2))
        showErrorAlert('Claims couldn\'t be saved.')
        setOpenSaveModal(false)
      }
    }
  )

  const formMethods = useForm<BatchClaimInputType>({
    defaultValues: claimDefaults,
  })
  const {
    control,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors, dirtyFields, isSubmitSuccessful },
    resetField,
    setError,
    reset,
    register,
    setFocus,
  } = formMethods

  useEffect(() => {
    enableNavigationPrompt(!!Object.keys(dirtyFields).length, FORM_NAME)
    return () => enableNavigationPrompt(false, FORM_NAME)
  }, [Object.keys(dirtyFields).length]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({}, { keepValues: true })
    }
  }, [isSubmitSuccessful])

  const updateSaveModalField = (updatedFieldValuesList: AbClaimField[], updatedFieldBool: boolean, updatedFieldName: string, updatedFieldValue: string) => {
    if (updatedFieldBool) {
      updatedFieldValuesList.push({ fieldName: updatedFieldName, fieldValue: updatedFieldValue })
    }
  }

  const save = (data) => {
    let error = false

    if (!getValues('billingProfile') && (dirtyFields.billingProfile)) {
      setError('billingProfile', {
        message: 'Required'
      })
      error = true
    }

    if (!getValues('patient') && (dirtyFields.patient)) {
      setError('patient', {
        message: 'Required'
      })
      error = true
    }

    if (!getValues('serviceCode') && (dirtyFields.serviceCode)) {
      setError('serviceCode', {
        message: 'Required'
      })
      error = true
    }

    if (!error) {
      let updatedFieldValuesList: AbClaimField[] = []
      updateSaveModalField(updatedFieldValuesList, !!dirtyFields.billingProfile, 'Practitioner', data.billingProfile ? data.billingProfile.name : '')
      updateSaveModalField(updatedFieldValuesList, !!dirtyFields.patient, 'Patient', data.patient ? data.patient.lastName + ', ' + data.patient.firstName : '')
      updateSaveModalField(updatedFieldValuesList, dirtyFields.serviceDate || false, 'Service date', data.serviceDate ? data.serviceDate : '')
      updateSaveModalField(updatedFieldValuesList, !!dirtyFields.serviceCode, 'Service code', data.serviceCode ? data.serviceCode.serviceCode : '')
      updateSaveModalField(updatedFieldValuesList, !!dirtyFields.diagnosticCodes, 'Diagnostic code', data.diagnosticCodes ? data.diagnosticCodes.map(d => d.diagnosticCode).join(', ') : '')
      updateSaveModalField(updatedFieldValuesList, !!dirtyFields.serviceCodeFeeModifiers, 'Fee modifiers', data.serviceCodeFeeModifiers ? data.serviceCodeFeeModifiers.map(fm => fm.feeMod).join(', ') : '')
      updateSaveModalField(updatedFieldValuesList, !!dirtyFields.feeMod1Units, data.serviceCodeFeeModifiers?.map(fm => feeModUnitDescriptionList.find(d => d.feeMod === fm.feeMod)).filter(fm => !!fm)[0]?.unitDescription, data.feeMod1Units)
      updateSaveModalField(updatedFieldValuesList, !!dirtyFields.feeMod2Units, data.serviceCodeFeeModifiers?.map(fm => feeModUnitDescriptionList.find(d => d.feeMod === fm.feeMod)).filter(fm => !!fm)[1]?.unitDescription, data.feeMod2Units)
      updateSaveModalField(updatedFieldValuesList, !!dirtyFields.feeMod3Units, data.serviceCodeFeeModifiers?.map(fm => feeModUnitDescriptionList.find(d => d.feeMod === fm.feeMod)).filter(fm => !!fm)[2]?.unitDescription, data.feeMod3Units)
      updateSaveModalField(updatedFieldValuesList, dirtyFields.calls || false, 'Calls', data.calls ? data.calls : '')
      updateSaveModalField(updatedFieldValuesList, dirtyFields.encounter || false, 'Encounter', data.encounter ? data.encounter : '')
      updateSaveModalField(updatedFieldValuesList, dirtyFields.claimAmount || false, 'Claim amount', data.claimAmount ? '$' + data.claimAmount : '')
      updateSaveModalField(updatedFieldValuesList, !!dirtyFields.facility, 'Facility', data.facility ? data.facility.description : '')
      updateSaveModalField(updatedFieldValuesList, !!dirtyFields.functionalCenter, 'Functional centre', data.functionalCenter ? data.functionalCenter.description : '')
      updateSaveModalField(updatedFieldValuesList, dirtyFields.locationCode || false, 'Location', data.locationCode)
      updateSaveModalField(updatedFieldValuesList, !!dirtyFields.referralPractitioner, 'Referral practitioner', data.referralPractitioner ? data.referralPractitioner.lastName + ', ' + data.referralPractitioner.firstName : '')
      updateSaveModalField(updatedFieldValuesList,  dirtyFields.text || false, 'Text', data.text ? data.text : '')

      updateSaveModalField(updatedFieldValuesList, dirtyFields.paperDocumentation || false, 'Paper documentation to follow', !!data.paperDocumentation ? 'selected' : 'unselected')
      updateSaveModalField(updatedFieldValuesList, dirtyFields.confidential || false, 'Confidential', !!data.confidential ? 'selected' : 'unselected')
      updateSaveModalField(updatedFieldValuesList, dirtyFields.originalEncounterDate || false, 'Original encounter date', data.originalEncounterDate ? data.originalEncounterDate : '')
      updateSaveModalField(updatedFieldValuesList, dirtyFields.originalFacility || false, 'Original facility', data.originalFacility ? data.originalFacility.description : '')
      updateSaveModalField(updatedFieldValuesList, dirtyFields.originalLocationCode || false, 'Original location', data.originalLocationCode)
      updateSaveModalField(updatedFieldValuesList, dirtyFields.newBornCode || false, 'Newborn', data.newBornCode ? data.newBornCode : '')
      updateSaveModalField(updatedFieldValuesList, dirtyFields.guardianUli || false, 'Guardian/parent ULI', data.guardianUli ? data.guardianUli : '')
      updateSaveModalField(updatedFieldValuesList, dirtyFields.guardianRegNumber || false, 'Guardian/parent registration number', data.guardianRegNumber ? data.guardianRegNumber : '')
      updateSaveModalField(updatedFieldValuesList, dirtyFields.skillId || false, 'Skill', data.skillId ? data.skillId.description : '')
      updateSaveModalField(updatedFieldValuesList, dirtyFields.payToCode || false, 'Pay to', data.payToCode ? data.payToCode : '')
      updateSaveModalField(updatedFieldValuesList, dirtyFields.payToUli || false, 'Pay to ULI', data.payToUli ? data.payToUli : '')
      updateSaveModalField(updatedFieldValuesList, dirtyFields.interceptReason || false, 'Intercept reason', data.interceptReason ? data.interceptReason : '')

      setUpdatedFieldValues(updatedFieldValuesList)
      setOpenSaveModal(true)
    }
  }

  const saveConfirmed = () => {
    if (formRef.current) {
      formRef.current.dispatchEvent(
        new Event("submit", { cancelable: true, bubbles: true })
      )
    }
  }

  const onSubmit = handleSubmit(async(data, errors) => {
    const claimIds = selectedClaimIds
    // False fields are not implemented in batch update yet (from options tab)
    data.abClaimBatchUpdatedFields = {
      billingProfileId: !!dirtyFields.billingProfile,
      calls: dirtyFields.calls || false,
      confidential: dirtyFields.confidential || false,
      diagnosticCodeIds: !!dirtyFields.diagnosticCodes,
      encounter: dirtyFields.encounter || false,
      facilityId: !!dirtyFields.facility,
      functionalCenterId: !!dirtyFields.functionalCenter,
      guardianUli: dirtyFields.guardianUli || false,
      interceptReason:  dirtyFields.interceptReason || false,
      locationCode: dirtyFields.locationCode || false,
      newbornCode: dirtyFields.newBornCode || false,
      originalEncounterDate:  dirtyFields.originalEncounterDate || false,
      originalFacilityId: dirtyFields.originalFacility || false,
      originalLocationCode: dirtyFields.originalLocationCode || false,
      paperDocumentation: dirtyFields.paperDocumentation || false,
      patientId: !!dirtyFields.patient,
      payToCode: dirtyFields.payToCode || false,
      payToUli: dirtyFields.payToUli || false,
      referralPractitionerId: !!dirtyFields.referralPractitioner,
      serviceCodeFeeModifierIds: !!dirtyFields.serviceCodeFeeModifiers,
      serviceCodeId: !!dirtyFields.serviceCode,
      serviceDate: !!dirtyFields.serviceDate,
      skillId: dirtyFields.skillId || false,
      text: dirtyFields.text || false,
      feeMod1Units: dirtyFields.feeMod1Units || false,
      feeMod2Units: dirtyFields.feeMod2Units || false,
      feeMod3Units: dirtyFields.feeMod3Units || false,
    }

    data.billingProfileId = data.billingProfile?.id || null
    delete data.billingProfile

    data.facilityId = data.facility?.id || null
    delete data.facility

    data.functionalCenterId = data.functionalCenter?.id || null
    delete data.functionalCenter

    data.referralPractitionerId = data.referralPractitioner?.id || null
    delete data.referralPractitioner

    data.serviceCodeId = data.serviceCode?.id || ''
    delete data.serviceCode

    data.diagnosticCodeIds = data.diagnosticCodes?.map(dc => dc.id) || null
    delete data.diagnosticCodes

    data.serviceCodeFeeModifierIds = data.serviceCodeFeeModifiers?.map(fm => fm.id) || null
    delete data.serviceCodeFeeModifiers

    data.patientId = data.patient?.id || null
    delete data.patient

    data.originalFacilityId = data.originalFacility?.id || null
    delete data.originalFacility

    data.skillId = data.skillId?.value || null
    data.originalLocationCode = data.originalLocationCode || null
    data.interceptReason = data.interceptReason || null
    data.newBornCode = data.newBornCode || null
    data.payToUli = data.payToUli || null
    data.feeMod1Units = data.feeMod1Units || null
    data.feeMod2Units = data.feeMod2Units || null
    data.feeMod3Units = data.feeMod3Units || null

    await updateBatchClaims({
      variables: {
        tenantId: tenant_id,
        claimData: data,
        claimIds: claimIds,
        action: ABClaimActionCode.ADD
      },
      update(cache, returnedData) {
        const claimsData = cache.readQuery({
          query: LIST_UNSUBMITTEDCLAIMS,
          variables: {
            tenantId: tenant_id,
          }
        })

        const newClaims = _get(returnedData, 'data.tenant.abClaim.updateAbClaims')
        const currentUnsubmittedClaims = _get(
          claimsData,
          'data.tenant.abClaim.listUnsubmittedAbClaim',
          []
        )

        cache.writeQuery({
          query: LIST_UNSUBMITTEDCLAIMS,
          data: {
            tenant: {
              abClaim: {
                listUnsubmittedAbClaim: [...currentUnsubmittedClaims, newClaims],
              },
            },
          },
          variables: {
            tenantId: tenant_id,
          },
        })
      }
    })
  })

  const shortcuts = useMemo(() => ({
    "d": () => {
      save(getValues())
    },
    "f": () => {
      setFocus("facility")
    },
    "r": () => {
      setFocus("referralPractitioner")
    },
    "t": () => {
      setFocus("text")
    },
    "p": () => {
      setFocus("patient")
    },
    "e": () => {
      setFocus("encounter")
    }
  }), [setFocus])

  const handleKeyPress = useCallback((event) => {
    if (event.altKey && event.key in shortcuts){
      event.preventDefault()
      shortcuts[event.key]()
    }
  }, [shortcuts])



  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress)
    return () => {
      document.removeEventListener('keydown', handleKeyPress)
    };
  }, [handleKeyPress])



  let abClaimMultiple = []
  // TODO: This seems to be how the form values are populated.
  //
  // let abClaimMultiple : AbClaimMultiple = setAbClaimMultiple(selectedClaims)
  //
  // useEffect(() => {
  //   setBatchAbClaimValues(abClaimMultiple, selectedClaims, setValue)
  // }, [])




  const options = [
    {
      label: 'BASE',
      key: 'BASE',
      content: (
        <BatchUpdateBase
          control={control}
          resetField={resetField}
          getValues={getValues}
          setValue={setValue}
          batchUpdateMultiple={abClaimMultiple}
          register={register}
        />
      ),
    },
    {
      label: 'OPTIONS',
      key: 'OPTIONS',
      content: (
        <BatchUpdateClaimOptions.Form
          control={control}
          errors={errors}
          resetField={resetField}
          dirtyFields={dirtyFields}
          batchUpdateMultiple={abClaimMultiple}
          setValue={setValue}
          register={register}
        />
      ),
    },
  ]

  const BatchUpdateClaimHeader = () => {
    return <><Box
      display={'flex'}
      flexDirection={'row'}
      sx={{
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Section.Header>Updating {selectedClaimIds.length} claims</Section.Header>
      <Box>
        <Button
          name="cancel"
          variant={'outlined'}
          onClick={() => {console.log("NYI")}}
          sx={{
            mr: 1,
          }}
        >
          CANCEL
        </Button>
        <Button name="save" onClick={() => save(getValues())}>
          SAVE
        </Button>
      </Box>
    </Box>
    <Box
      display={'flex'}
      flexDirection={'row'}
      color={theme.palette.warning.main}
      sx={{
        alignItems: 'center'
      }}
    >
      <WarningAmberIcon sx={{ mr: 1 }}/>
      <Typography variant={'subtitle1'}>Values entered will replace existing values in modified fields for all selected claims.</Typography>
    </Box>
    {openSaveModal ?
      <BatchSaveDialog
        selectedClaimIds={selectedClaimIds}
        openSaveModal={openSaveModal}
        setOpenSaveModal={setOpenSaveModal}
        updatedFieldValues={updatedFieldValues}
        saveConfirmed={saveConfirmed}
      />
      : <></>
    }
    </>
  }

  return (
    <FormProvider {...formMethods}>
      <BatchUpdateClaimHeader />
      <Box display={'flex'} flexDirection={'column'}>
        <Form onSubmit={onSubmit} autoComplete={'false'} ref={formRef}>
          <Tabs options={options} />
        </Form>
      </Box>
    </FormProvider>
  )
}