import { TaskAssignmentInput } from '../../../../types/tasks/TaskAssignment'
import { TaskPriority } from '../../../../types/tasks/TaskPriority'

export const taskDefaults: TaskAssignmentInput = {
  template: undefined,
  name: "",
  typeId: "",
  assignedTo: undefined,
  stateId: "",
  priority: TaskPriority.MEDIUM,
  startDate: new Date(),
  dueDate: new Date(),
  practitionerId: "",
  description: "",
  version: "0"
}