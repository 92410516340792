import { gql } from "@apollo/client";

export const IMMUNIZATION_FRAGMENT = gql`
  fragment ImmunizationFragment on Immunization {
    id
    name
    date
    vaccinationId
    audit {
      crePit
    }
  }
`

export const GET_PATIENT_IMMUNIZATIONS = gql`
  ${IMMUNIZATION_FRAGMENT}
  query GetPatientImmunizations($patientId: ID!, $tenantId: ID!) {
    tenant(id: $tenantId) {
      patient(id: $patientId) {
        immunization {
          list {
            ...ImmunizationFragment
          }
        }
      }
    }
  }
`

export const UPDATE_IMMUNIZATIONS = gql`
  ${IMMUNIZATION_FRAGMENT}
  mutation UpdateImmunizations($tenantId: ID!, $patientId: ID!, $input: ImmunizationInput!) {
    tenant(id: $tenantId) {
      patient {
        immunization {
          update(patientId: $patientId, input: $input) {
            ...ImmunizationFragment
          }
        }
      }
    }
  }
`
