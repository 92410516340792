import { usePageTitleContext } from '../../providers/PageTitleContextProvider'
import React, { useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import { BillingTabs } from './BillingTabs'
import { UnsubmittedClaimsLayout } from './UnsubmittedClaimsLayout'

import AssessedClaims from './components/AssessedClaims/AssessedClaims'
import { UnsubmittedClaimsProvider } from './providers/UnsubmittedClaimsProvider'
import { NewClaimDetails } from './components/UnsubmittedClaims/NewClaimDetails'
import { UnsubmittedClaimUpdateDetails } from './components/UnsubmittedClaims/UnsubmittedClaimUpdateDetails'
import { BatchUpdateDetails } from './components/BatchUpdate/UnsubmittedClaims/BatchUpdateDetails'


export const BillingRoutes = () => {
  const { setAreaTitle } = usePageTitleContext()

  useEffect(() => {
    setAreaTitle('Billing')
  }, [setAreaTitle])

  return (
    <Routes>

      <Route path={'/'} element={<BillingTabs />}>

        <Route path={'assessed'}>
          <Route
            path={'c/:claim_id'}
            element={
              <AssessedClaims />
            }
          />
          <Route
            index
            element={
              <AssessedClaims />
            }
          />
        </Route>

        <Route path={'/'} element={<UnsubmittedClaimsProvider />}>
          <Route element={<UnsubmittedClaimsLayout />}>
            <Route index element={<NewClaimDetails/>} />
            <Route path={'c/update-batch'} element={<BatchUpdateDetails/>} />
            <Route path={'c/:claim_id'} element={<UnsubmittedClaimUpdateDetails/>} />
          </Route>
        </Route>

        {/*<Route path={'/'} element={<UnsubmittedBase/>}>*/}
        {/*  <Route*/}
        {/*    path={'c/:claim_id'}*/}
        {/*    element={*/}
        {/*      <AssessedClaims />*/}
        {/*    }*/}
        {/*  />*/}
        {/*  <Route*/}
        {/*    index*/}
        {/*    element={*/}
        {/*      <AssessedClaims />*/}
        {/*    }*/}
        {/*  />*/}
        {/*</Route>*/}



        {/*<Route index element={<UnsubmittedForm />} />*/}
      </Route>
    </Routes>
  )
}