import React from 'react'
import { Box } from '@mui/material'
import { FilterSelect, Button } from 'saga-library/src'
import { useTaskContext } from '../../../../providers/TaskProvider'
import { TaskCategory, TaskState } from '../../../../types/tasks/TaskState'
import { TaskType } from '../../../../types/tasks/TaskType'

interface TaskHeaderProps {
  setFilters: (event: any) => void,
  onNewTask: () => void
}

export const TaskListHeader = ({
  setFilters,
  onNewTask
}: TaskHeaderProps) => {
  const { taskTypes, taskStates } = useTaskContext()

  const typeOptions = [
    {label: "All types", value: "*"},
    ...taskTypes.map((type: TaskType) => {
      return {
        label: type.type,
        value: type.type
      }
    })
  ]

  const stateOptions = [
    {label: "Incomplete", value: TaskCategory.INCOMPLETE},
    ...taskStates.map((state: TaskState) => {
      return {
        label: state.name,
        value: state.name
      }
    })
  ]

  return (
    <Box display={'flex'} mb={1} alignItems={'center'}>
      <Box flex={"1 1 100%"} gap={2} display={'flex'} flexDirection={'row'} >
        <FilterSelect
          dataTestId={'task-type-filter'}
          options={typeOptions}
          onSelect={(event) => setFilters((filters) => ({...filters, type: event.value}))}
          defaultSelectedValue={"*"}
        />
        <FilterSelect
          dataTestId={'task-state-filter'}
          options={stateOptions}
          onSelect={(event) => setFilters((filters) => ({...filters, state: event.value}))}
          defaultSelectedValue={TaskCategory.INCOMPLETE}
        />
      </Box>
      <Button dataTestId={"new_task_button"} name={"new"} onClick={onNewTask}> new </Button>
    </Box>
  )
}