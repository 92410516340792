import { Box, useTheme } from "@mui/material";
import { Typography, Button } from "saga-library/src";
import ReceiptLong from "@mui/icons-material/ReceiptLongOutlined";
import UpdateIcon from '@mui/icons-material/Update'
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import React, { useEffect, useState } from 'react'
import { styled } from "@mui/system";
import { useMutation } from '@apollo/client'
import { useNavigate, useParams } from 'react-router-dom'
import { useAlerts } from "saga-library/src/providers/Alerts";
import { BatchDeleteDialog } from "../BatchUpdate/components/BatchDeleteDialog";
import { HOLD_CLAIMS, UNHOLD_CLAIMS, DELETE_CLAIMS } from "../../../../graphql-definitions";
import { useUnsubmittedClaimsContext } from '../../providers/UnsubmittedClaimsProvider'


const BillingsIcon = styled(ReceiptLong)(({ theme }) => ({
  color: theme.palette.greys.light,
  fontSize: '100px',
}))


const UnsubmittedMultipleSelected = () => {
  const { tenant_id } = useParams()
  const { showSuccessAlert } = useAlerts()
  const theme = useTheme()
  const navigate = useNavigate()

  const { selectedClaims, selectedClaimIds } = useUnsubmittedClaimsContext()

  const [holdClaims, { error: holdError }] = useMutation(HOLD_CLAIMS)
  const [unholdClaims, { error: unholdError } ] = useMutation(UNHOLD_CLAIMS)
  const [deleteClaims, { error: deleteError }] = useMutation(DELETE_CLAIMS)

  const [openDeleteModal, setOpenDeleteModal] = useState(false)

  const handleUpdate = () => {
    navigate(`../c/update-batch`)
  }

  const handleHold = async() => {
    await holdClaims({
      variables: {
        tenantId: tenant_id,
        abClaimIds: selectedClaimIds,
      },
      onCompleted: () => {
        //refetch()
        showSuccessAlert(`Claims have been held.`)
      },
      onError: () => {
        console.error(JSON.stringify(holdError, null, 2))
      }
    })
  }

  const handleUnhold = async() => {
    await unholdClaims({
      variables: {
        tenantId: tenant_id,
        abClaimIds: selectedClaimIds,
      },
      onCompleted: () => {
        //refetch()
        showSuccessAlert(`Claims have been unheld.`)
      },
      onError: () => {
        console.error(JSON.stringify(unholdError, null, 2))
      }
    })
  }

  const handleDelete = async() => {
    await deleteClaims({
      variables: {
        tenantId: tenant_id,
        abClaimIds: selectedClaimIds,
      },
      onCompleted: () => {
        //refetch()
        //clearAllClick()
        showSuccessAlert(`${selectedClaimIds.length} claims have been deleted.`)
      },
      onError: () => {
        console.error(JSON.stringify(deleteError, null, 2))
      }
    })
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        mt: 6
      }}>
      <BillingsIcon />
      <Typography variant={'h2'}>{selectedClaimIds.length} {selectedClaimIds.length === 1 ? 'claim' : 'claims'} selected</Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          mt: 4,
          color: theme.palette.primary.main,
        }}
      >
        <ActionComponent
        icon={<UpdateIcon/>}
        text={'UPDATE'}
        onClick={handleUpdate}
        />
        <ActionComponent
          icon={<PauseCircleOutlineIcon/>}
          text={'HOLD'}
          onClick={handleHold}
        />
        <ActionComponent
          icon={<PlayCircleFilledWhiteOutlinedIcon/>}
          text={'UNHOLD'}
          onClick={handleUnhold}
        />
        <ActionComponent
          icon={<DeleteOutlineOutlinedIcon/>}
          text={'DELETE'}
          onClick={() => setOpenDeleteModal(true)}
        />
        <BatchDeleteDialog
          selectedClaims={selectedClaims}
          openDeleteModal={openDeleteModal}
          setOpenDeleteModal={setOpenDeleteModal}
          onDelete={handleDelete}
        />
        <hr
          style={{
            alignItems: 'center',
            color: theme.palette.greys.light,
            width: '120px',
            marginTop: '32px',
          }}
        />

        <ActionComponent
          icon={<ClearOutlinedIcon/>}
          text={'CANCEL'}
          onClick={() => {console.warn("NYI")}}
        />
      </Box>
    </Box>
  )
}

export const ActionComponent = ({icon, text, onClick}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        mt: '12px',
      }}
    >
      <Button name={'action-button'} variant={'text'} onClick={onClick} startIcon={icon}>
        {text}
      </Button>
    </Box>
  )
}

export default UnsubmittedMultipleSelected
