import { AddButton, Select } from 'saga-library/src'
import React, { useEffect, useState } from "react";
import { useFieldArray, useFormContext, useWatch } from "saga-library/src/components/Form";
import { Box, MenuItem } from '@mui/material'
import { PatientSearch } from './PatientSearch'
import { FamilyRelationshipBlock } from './FamilyRelationshipBlock'
import { FamilyRelationship, PersonalRelationship } from '../../../types/patients/Patient'
import { usePatientProfileContext } from '../../../apps/patients/providers/PatientProfileProvider'
import { displayRelationship } from '../../../apps/patients/util/displayEnum'

export type FamilyRelationshipsArrayProps = {
  name: string
  max?: number
  dataTestId: string
}

export const FamilyRelationshipsArray = ({name, max, dataTestId}: FamilyRelationshipsArrayProps) => {
  const { profileData } = usePatientProfileContext()
  const [autoFocusOnOpen, setAutoFocusOnOpen] = useState<boolean>(false)
  const [displayRelationships, setDisplayRelationships] = useState<JSX.Element[]>([])
  const {
    control,
    getValues,
    watch,
    setValue,
  } = useFormContext()
  const { fields, update, append, remove } = useFieldArray({
    name: name,
    control,
  })
  const watchFieldArray = watch(name);

  const relationships = useWatch({
    control,
    name: name
  });

  const patientSelection = useWatch({
    control,
    name: `patientSelection`
  });

  const typeSelection = useWatch({
    control,
    name: `typeSelection`
  });

  const currentPatientAddress = {
    street: profileData.street,
    street2: profileData.street2,
    street3: profileData.street3,
    city: profileData.city,
    province: profileData.province,
    postalCode: profileData.postalCode,
    country: profileData.country
  }

  useEffect(() => {
    if(relationships === undefined || fields.length === 0) {
      setValue(name, [''], { shouldDirty: false });
    }
  }, [fields, relationships])

  const removeEmergencyContact = (relationship, index) => {
    relationship.isEmergencyContact = false
    update(index, relationship)
  }

  const setAsEmergencyContact = (emergencyContactIndex) => {
    fields.map((item, index) => {
      let relationship = getValues(`${name}[${index}]`)
      relationship = {...relationship, isEmergencyContact: index === emergencyContactIndex}
      update(index, relationship)
    })
  }

  useEffect(() => {
    if (patientSelection && typeSelection) {
      const relationship: FamilyRelationship = {
        relatedPatientId: patientSelection.id,
        relationshipType: typeSelection,
        firstName: patientSelection.firstName,
        lastName: patientSelection.lastName,
        email: patientSelection.email,
        primaryPhoneNumber: patientSelection.primaryPhoneNumber,
        isEmergencyContact: false
      }
      update(fields.length-1, relationship)
      setValue(`patientSelection`, null)
      setValue(`typeSelection`, null)
    }
  }, [patientSelection, typeSelection, JSON.stringify(fields)])

  useEffect(() => {
    setDisplayRelationships(fields.map((item, index) => {
      const relationship = getValues(`${name}[${index}]`)
      if (relationship) {
        return (
          <FamilyRelationshipBlock
            key={`${item.id}-box`}
            blockKey={`${item.id}-box`}
            dataTestId={`${dataTestId}-${index}-box`}
            relationship={relationship}
            onDelete={() => remove(index)}
            setAsEmergencyContact={() => setAsEmergencyContact(index)}
            removeEmergencyContact={() => removeEmergencyContact(relationship, index)}
          />
        )
      }
      return (
        <Box
          key={`${item.id}-box`}
          sx={{
            mb: "8px",
            padding: "8px",
            gap: "4px",
            borderRadius: "8px",
            border: "1px solid var(--grey-light, rgba(32, 41, 49, 0.32))"
          }}
        >
          <PatientSearch
            label={"Patient"}
            dataTestId={`${dataTestId}-${index}-search`}
            key={item.id}
            name={`patientSelection`}
            selectedOptions={[...watchFieldArray, profileData]}
            autoFocusOnOpen={autoFocusOnOpen}
            relationshipAddress={currentPatientAddress}
            includeInactive={true}
          />
          <Box sx={{flexGrow: 1, flexShrink: 0, minWidth: '25%'}}>
            <Select
              dataTestId={`${dataTestId}-relationship-${index}-type-select`}
              name={`typeSelection`}
              label={`Relationship to ${profileData.firstName || 'new patient'}`}
              sx={{width: '100%'}}
            >
              {Object.values(PersonalRelationship).map((relationshipType) => (
                <MenuItem
                  data-testid={`${dataTestId}-relationship-${index}-type-option-${relationshipType}`}
                  value={relationshipType}
                  key={relationshipType}
                >
                  {displayRelationship(relationshipType)}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
      )
    }))
  }, [JSON.stringify(watchFieldArray), JSON.stringify(fields)])

  return (
    <>
      {displayRelationships}
      {(!max || fields.length < max) && <AddButton
        dataTestId={dataTestId}
        label={`Add relationship`}
        onClick={() => {
          append("");
          setAutoFocusOnOpen(true);
        }}
      />}
    </>
  )
}