import React from "react";
import {
  AbClaimAcceptCode,
  ABClaimActionCode,
  ABClaimAssessmentOutcome,
  AbClaimStatus
} from "../../../../utils/ABClaimConstants";
import { CancelWriteOffHeader } from "../AssessedClaimHeaderOptions/CancelWriteOffHeader";
import { AcceptedClaimHeader } from "../AssessedClaimHeaderOptions/AcceptedClaimHeader";
import { ResubmittedHeader } from "../AssessedClaimHeaderOptions/ResubmittedHeader";
import { RefusedClaimHeader } from "../AssessedClaimHeaderOptions/RefusedClaimHeader";
import { AdjustedClaimHeader } from "../AssessedClaimHeaderOptions/AdjustedClaimHeader";
import { ResubmitOptionsList } from "../AssessedClaimHeaderOptions/ResubmitOptionsList";
import { Box, Chip, useTheme } from "@mui/material";
import { Section } from "saga-library/src";

export const AssessedClaimHeader = ({
  getValues,
  setOpenCancelWriteoffDialog,
  setOpenResubmitOptions,
  openResubmitOptions,
  setOpenReassessmentWarningDialog,
  setOpenDeletionWarningDialog,
  setOpenCancelResubmissionDialog,
  setOpenWriteoffDialog,
  resubmitWithCode,
  onAcceptButtonClick,
}) => {
  const claim = getValues()
  const showResubmissionRefusedChip = !claim.acceptCode && claim.assessmentOutcome === ABClaimAssessmentOutcome.REFUSED && claim.actionCode === ABClaimActionCode.CHANGE

  return (
    <Box
      display={'flex'}
      flexDirection={'row'}
      sx={{
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Box display={'flex'} flexDirection={'column'}>
        {
          showResubmissionRefusedChip &&
          <Chip
            label='RESUBMISSION REFUSED'
            sx={theme => ({
              backgroundColor: theme.palette.error.main,
              color: 'white'
            })}
          />
        }
      </Box>
      <AssessedClaimStateHeader
        getValues={getValues}
        setOpenCancelWriteoffDialog={setOpenCancelWriteoffDialog}
        setOpenResubmitOptions={setOpenResubmitOptions}
        openResubmitOptions={openResubmitOptions}
        setOpenReassessmentWarningDialog={setOpenReassessmentWarningDialog}
        setOpenDeletionWarningDialog={setOpenDeletionWarningDialog}
        setOpenCancelResubmissionDialog={setOpenCancelResubmissionDialog}
        setOpenWriteoffDialog={setOpenWriteoffDialog}
        resubmitWithCode={resubmitWithCode}
        onAcceptButtonClick={onAcceptButtonClick}
      />
    </Box>
  )
}

export const AssessedClaimStateHeader = ({
  getValues,
  setOpenCancelWriteoffDialog,
  setOpenResubmitOptions,
  openResubmitOptions,
  setOpenReassessmentWarningDialog,
  setOpenDeletionWarningDialog,
  setOpenCancelResubmissionDialog,
  setOpenWriteoffDialog,
  resubmitWithCode,
  onAcceptButtonClick
}) => {
  const claim = getValues()

  if (!!claim.acceptCode) {
    if (claim.acceptCode === AbClaimAcceptCode.ACCEPT) {
      return (
        <AcceptedClaimHeader
          acceptedDate={claim.assessmentDate}
          onResubmitButtonClick={()=>setOpenResubmitOptions(!openResubmitOptions)}
          onReassessOptionClick={()=>setOpenReassessmentWarningDialog(true)}
          onDeleteOptionClick={()=>setOpenDeletionWarningDialog(true)}
          onChangeOptionClick={()=>resubmitWithCode(ABClaimActionCode.CHANGE)}
          expand={openResubmitOptions}
        />
      )
    }
    else {
      return (
        <CancelWriteOffHeader claim={claim} onClick={() => setOpenCancelWriteoffDialog(true)} />
      )
    }
  }
  else {
    if (claim.claimStatus === AbClaimStatus.RESUBMITTED) {
      return (
        <ResubmittedHeader claim={claim} onClick={() => {setOpenCancelResubmissionDialog(true)}} />
      )
    }
    else {
      if (claim.assessmentOutcome === ABClaimAssessmentOutcome.REFUSED && claim.actionCode !== ABClaimActionCode.CHANGE) {
        return (
          <RefusedClaimHeader onWriteoff={() => setOpenWriteoffDialog(true)} onResubmit={() => resubmitWithCode(ABClaimActionCode.ADD)}/>
        )
      }
      else if (claim.assessmentOutcome === ABClaimAssessmentOutcome.APPLIED || claim.assessmentOutcome === ABClaimAssessmentOutcome.REFUSED) {
        if (claim.adjusted || claim.assessmentOutcome === ABClaimAssessmentOutcome.REFUSED) {
          return (
            <AdjustedClaimHeader
              onAcceptButtonClick={onAcceptButtonClick}
              onResubmitButtonClick={()=>setOpenResubmitOptions(!openResubmitOptions)}
              onReassessOptionClick={()=>setOpenReassessmentWarningDialog(true)}
              onDeleteOptionClick={()=>setOpenDeletionWarningDialog(true)}
              onChangeOptionClick={()=>resubmitWithCode(ABClaimActionCode.CHANGE)}
              expand={openResubmitOptions}
            />
          )
        }
        else {
          return (
            <ResubmitOptionsList
              onResubmitButtonClick={()=>setOpenResubmitOptions(!openResubmitOptions)}
              onReassessOptionClick={()=>setOpenReassessmentWarningDialog(true)}
              onDeleteOptionClick={()=>setOpenDeletionWarningDialog(true)}
              onChangeOptionClick={()=>resubmitWithCode(ABClaimActionCode.CHANGE)}
              expand={openResubmitOptions}
            />
          )
        }
      }
      else {
        return null
      }
    }
  }
}