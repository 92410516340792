import React from 'react'
import { Form, DataGrid } from 'saga-library/src'
import { DATE_FORMAT } from '../../../../utils/SettingsConstants'
import { useAccountContext } from '../../../../providers/AccountContext'
import { borderlessFieldsSx, ExtendedGridColDef } from 'saga-library/src/components/DataGrid/DataGrid'
import { VaccinationSearch } from '../../../../components/SearchControls/Patient/VaccinationSearch'

export const IMMUNIZATIONS_FORM_ID = 'immunizations-form'

interface ImmunizationsFormProps {
  onSubmit: () => void
  dataGridRef: React.MutableRefObject<any>
}

const immunizationColumns: ExtendedGridColDef[]  = [
  {
    field: 'name',
    headerName: 'Vaccination',
    editable: true,
    required: true,
    valueGetter: (value: any) => value && value.name ? value.name : value,
    renderEditCell: (params) => (
      <VaccinationSearch
        params={params}
        name={`vaccination-search`}
        dataTestId={`immunization`}
        placeholderText={'Vaccine required'}
        sx={borderlessFieldsSx}
      />
    )
  },
  {
    field: 'date',
    headerName: 'Date',
    editable: true
  }
]

export const IMMUNIZATION_COLUMN_KEYS = immunizationColumns.map(col => col.field)

export const ImmunizationsForm = ({
  onSubmit,
  dataGridRef
} : ImmunizationsFormProps) => {
  const { getUserSetting } = useAccountContext()

  return (
    <Form onSubmit={onSubmit} id={IMMUNIZATIONS_FORM_ID}>
      <DataGrid
        dataGridRef={dataGridRef}
        name={'immunizations'}
        initialColumns={immunizationColumns}
        dataTestId={'immunization'}
        addLabel={'vaccination'}
        format={getUserSetting(DATE_FORMAT) as string}
        height={'662px'}
        emptyListMessage={'Vaccinations appear here once added'}
      />
    </Form>
  )
}