import React, { useState } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import { AbClaim } from '../../../types/billing'
import { QueryReference, useBackgroundQuery } from '@apollo/client'
import { LIST_UNSUBMITTEDCLAIMS } from '../../../graphql-definitions'


interface UnsubmittedClaimsContextInterface {
  unsubmittedClaimsQueryRef: QueryReference | null,
  selectedClaims: AbClaim[],
  allUnsubmittedClaims: AbClaim[],
  redirectToNewlyCreatedClaim: boolean,
  setRedirectToNewlyCreatedClaim: React.Dispatch<React.SetStateAction<boolean>>
  setCurrentPatientId: (number) => void,
  currentPatientId: string | null,
  selectedClaimIds: string[],
  setSelectedClaimIds: ([]) => void
}

const defaultUnsubmittedClaimsContext: UnsubmittedClaimsContextInterface = {
  unsubmittedClaimsQueryRef: null,
  selectedClaims: [],
  allUnsubmittedClaims: [],
  redirectToNewlyCreatedClaim: false,
  setRedirectToNewlyCreatedClaim: (arg) => null,
  setCurrentPatientId: (arg) => null,
  currentPatientId: null,
  selectedClaimIds: [],
  setSelectedClaimIds: (arg) => null
}

const UnsubmittedClaimsContext = React.createContext(defaultUnsubmittedClaimsContext)

export const UnsubmittedClaimsProvider = () => {
  const { tenant_id } = useParams()

  const [allUnsubmittedClaims] = useState<AbClaim[]>([])
  const [selectedClaims] = useState<AbClaim[]>([])
  const [currentPatientId, setCurrentPatientId] = React.useState('')
  const [redirectToNewlyCreatedClaim, setRedirectToNewlyCreatedClaim] = useState(false)
  const [selectedClaimIds, setSelectedClaimIds] = useState<string[]>([])

  const [queryRef] = useBackgroundQuery(LIST_UNSUBMITTEDCLAIMS, {
    variables: { tenantId: tenant_id },
    fetchPolicy: 'cache-and-network',
  })

  const providerValues = {
    unsubmittedClaimsQueryRef: queryRef,
    selectedClaims,
    allUnsubmittedClaims,
    setRedirectToNewlyCreatedClaim,
    redirectToNewlyCreatedClaim,
    setCurrentPatientId,
    currentPatientId,
    setSelectedClaimIds,
    selectedClaimIds
  }

  return (
    <UnsubmittedClaimsContext.Provider value={providerValues}>
      { queryRef && <Outlet /> }
    </UnsubmittedClaimsContext.Provider>
  )
}

export const useUnsubmittedClaimsContext = () => {
  return React.useContext(UnsubmittedClaimsContext)
}