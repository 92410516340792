import { Button, Menu, useTheme, MenuItem, SxProps, Divider } from '@mui/material'
import { Typography } from "saga-library/src";
import React, { ReactElement, useEffect, useState } from "react";
import { ExpandMore, ExpandLess } from "@mui/icons-material";
import Box from '@mui/material/Box'
import { Role, User } from 'saga-client/src/types/account'
import { TaskCategory } from 'saga-client/src/types/tasks/TaskState'

export interface FilterSelectOption {
  type?: 'divider'
  label?: string | null
  value?: string | User | Role | null | TaskCategory
}

interface FilterSelectProps {
  className?: string
  label?: string
  options?: FilterSelectOption[]
  onSelect?: (string) => void
  defaultSelectedValue?: string | User | Role | null
  size?: "small" | "large"
  onOpen?: (any) => void
  onClose?: () => void
  menuOverride?: ReactElement | ReactElement[]
  dropDownOpen?: boolean
  color?: string
  sx?: SxProps
  dataTestId?: string
}

interface DropDownProps {
  className: string | undefined
  label?: string,
  option: FilterSelectOption | null,
  singleOption: boolean,
  size: "small" | "large"
  showExpand: boolean
  dropDownOpen?: boolean
  color?: string
  openMenuHandler?: (any) => void
  sx?: SxProps
  dataTestId?: string
}

export const FilterSelect = ({
  className,
  label,
  options = [],
  onSelect:parentOnSelect= ()=>{},
  defaultSelectedValue,
  size="small",
  onOpen:parentOnOpen= ()=>{},
  onClose:parentOnClose= ()=>{},
  menuOverride,
  dropDownOpen,
  color,
  sx,
  dataTestId
} : FilterSelectProps) => {
  const theme = useTheme()
  const [anchorEl, setAnchorEl] = useState(null)
  const [menuOpen, setMenuOpen] = useState(false)
  const [selected, setSelected] = useState(options.length > 0 ? options[0] : null)

  useEffect(()=>{
    if(options.length > 0){
      if (defaultSelectedValue){
        setSelected(options.find(options => options.value === defaultSelectedValue) ?? null)
      } else {
        setSelected(options[0])
      }
    }
  }, [JSON.stringify(options)])

  const onSelect = (option) => {
    setSelected(option)
    parentOnSelect(option)
    handleClose()
  }

  const openMenuHandler = (event) => {
    if (dropDownOpen ?? menuOpen) {
      handleClose()
      parentOnClose()
    }
    else {
      setAnchorEl(event.currentTarget)
      setMenuOpen(true)
      parentOnOpen(event)
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
    setMenuOpen && setMenuOpen(false)
  }

  return <>
    <Dropdown
      dataTestId={dataTestId}
      className={className}
      label={label}
      option={selected}
      singleOption={false}
      size={size}
      showExpand={true}
      dropDownOpen={dropDownOpen ?? menuOpen}
      color={color}
      openMenuHandler={openMenuHandler}
      sx={{
        '&:hover': {
          backgroundColor: 'backgrounds.hover'
        },
       ...sx
      }}
    />
    {menuOverride ??
    <Menu
      data-testid={`${dataTestId}-menu`}
      open={dropDownOpen ?? menuOpen}
      anchorEl={anchorEl}
      onClose={handleClose}
      MenuListProps={{ disablePadding: true }}
      sx={{
        "& .Mui-selected": {
          backgroundColor: `${theme.palette.backgrounds.selected} !important`
        },
        "& .Mui-selected:hover": {
          backgroundColor: `${theme.palette.backgrounds.selectedHover} !important`
        },
      }}
    >
      {options.map((option, index) => {
        if(option.type && option.type === 'divider'){
          return <Divider key={`divider-${index}`} />
        }
        return (
          <MenuItem
            data-testid={`${dataTestId}-menuItem-${index}`}
            key={option.label}
            selected={option.value === selected?.value}
            onClick={() => onSelect(option)}
            sx={{
              "&:hover": {
                backgroundColor: theme.palette.backgrounds.hover,
              },
            }}
          >
            <Typography variant={'body1'}>
              {option.label}
            </Typography>
          </MenuItem>
        )
      })}
    </Menu>}
  </>
}

const Dropdown = ({color, className, label, option, singleOption, size, showExpand, dropDownOpen, openMenuHandler, sx, dataTestId} : DropDownProps) => {
  const theme = useTheme()
  const isSmallSize = size === "small"
  const sxExpand = color ? {fill: color} : {fill: theme.palette.greys.dark, opacity: 0.64}

  const Expand = () => {
    return dropDownOpen
      ? <ExpandLess data-testid={`${dataTestId}-expandLess`} sx={sxExpand}/>
      : <ExpandMore data-testid={`${dataTestId}-expandMore`} sx={sxExpand}/>
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 1
      }}
    >
      {label &&
        <Typography
          variant={isSmallSize ? "body1" : "h4"}
        >
          {label}:
        </Typography>
      }
      <Button
        data-testid={dataTestId}
        sx={{
          textTransform: 'none',
          px: '0px',
          py: '4px',
          minWidth: '0px',
          flex: '1 1 auto',
          justifyContent: 'center',
          ...sx
        }}
        onClick={openMenuHandler}
        className={className ?? 'label-dropdown'}
      >
        <Typography
          variant={isSmallSize ? "h5" : "h3"}
          sx={{
            color: color ?? theme.palette.primary.main
          }}
        >
          { option?.label || "n/a" }
        </Typography>
        { showExpand && <Expand />}
      </Button>
    </Box>
  )
}

export default FilterSelect