import { Controller, useFormContext } from 'saga-library/src/components/Form'
import React, { useEffect, useState } from 'react'
import { Autocomplete } from 'saga-library/src'
import { SxProps, Theme } from '@mui/material'
import { useQuery } from '@apollo/client'
import { LIST_TASK_TEMPLATES } from '../graphql-definitions'
import { useParams } from 'react-router-dom'
import { useAlerts } from 'saga-library/src/providers/Alerts'
import _get from 'lodash/get'
import { TaskTemplate } from '../types/tasks/TaskTemplate'
import { IdEqual } from 'saga-library/src/components/SearchControl/ControlledSearchControl'

interface TaskTemplateSelectProps {
  label: string,
  name: string,
  disabled?: boolean,
  onChange?: (value) => void,
  dataTestId: string,
  sx?: SxProps<Theme>
}

export const TaskTemplateSelect = ({
  label = 'Type',
  name,
  disabled = false,
  onChange,
  dataTestId,
  sx
}: TaskTemplateSelectProps) => {
  const { tenant_id } = useParams()
  const { showErrorAlert } = useAlerts()
  const { control } = useFormContext()
  const [ taskTemplates, setTaskTemplates ] = useState<TaskTemplate[]>([])

  const { loading, data } = useQuery(LIST_TASK_TEMPLATES, {
    fetchPolicy: 'cache-first',
    variables: { tenantId: tenant_id },
    onError: (error) => {
      showErrorAlert('Unable to retrieve task templates.')
      console.error(JSON.stringify(error, null, 2))
    }
  })

  useEffect(() => {
    const list = _get(data, "tenant.task.template.list", [])
    setTaskTemplates(list)
  }, [data])

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange: controlledOnChange, value = null }, fieldState: { error } }) => {
        if (value === '') {
          value = null
        }
        return (
          <Autocomplete
            name={name}
            label={label}
            options={taskTemplates}
            loading={loading}
            onChange={(e, newValue) => {
              controlledOnChange(newValue ?? null)
              if (onChange) {
                onChange(newValue ?? null)
              }
            }}
            getOptionLabel={(option) => option?.name || ""}
            isOptionEqualToValue={IdEqual}
            value={value}
            sx={{ flex: '1 1 100%', ...sx }}
            disabled={disabled}
            onInputChange={(e, value, reason) => {
              if (reason === 'clear') {
                controlledOnChange(null)
                if (onChange) {
                  onChange(null)
                }
              }
            }}
            error={error}
            helperText={error?.message}
            dataTestId={dataTestId}
          />
        )
      }}
    />
  )
}