import { Box } from "@mui/material";
import MuiRadioGroup from "@mui/material/RadioGroup";
import { Radio, TableList } from "saga-library/src";
import { UnsubmittedClaimRow } from "./UnsubmittedClaimRow";
import React, { useState } from "react";
import { AbClaimProblemSeverity } from "../../../../utils/ABClaimConstants";
import { UnsubmittedClaimColumns} from "./UnsubmittedClaimList";
import { useParams } from "react-router-dom";
import { useAccountContext } from "../../../../providers/AccountContext";
import { PermissionType, Permission } from "../../../../types/settings/Permission";
import { useUnsubmittedClaimsContext } from '../../providers/UnsubmittedClaimsProvider'
import { useReadQuery } from '@apollo/client'
import _get from 'lodash/get'
import { AbClaim } from '../../../../types/billing'
import { useNavigate } from 'react-router-dom'


export const AllUnsubmittedClaimsListView = () => {
  const [filter, setFilter] = useState('*')
  const { tenant_id } = useParams()
  const { userHasPermission } = useAccountContext()
  const navigate = useNavigate()

  const { unsubmittedClaimsQueryRef, setRedirectToNewlyCreatedClaim, setSelectedClaimIds, selectedClaimIds } = useUnsubmittedClaimsContext()

  const data = useReadQuery(unsubmittedClaimsQueryRef!)
  let allUnsubmittedClaims = _get(data, 'data.tenant.abClaim.listUnsubmittedAbClaim', [] as any)

  const hasEditPermission = (tenant_id && userHasPermission(tenant_id, PermissionType.Billing, Permission.READWRITE))

  const claimStates = {
    all: '*',
    readyToSubmit: 'READY',
    held: 'HOLD',
    containsErrors: 'ERROR',
  }

  const filterClaimsByStatus = (claim) => {
    if (filter === '*') {
      return true
    } else if (filter !== 'ERROR') {
      return claim.claimStatus === filter
    } else {
      return claim.problems.some(p => p.severity === AbClaimProblemSeverity.ERROR)
    }
  }

  const selectionChanged = (keys) => {
    if (keys.length !== selectedClaimIds.length) {
      setSelectedClaimIds(keys)
    }
  }

  const onRowClick = (claim: AbClaim) => {
    setSelectedClaimIds([])
    setRedirectToNewlyCreatedClaim(false)
    navigate(`../c/${claim.id}`)
  }

  return <>
    <Box
      data-testid={"unsubmittedClaimList"}
      display={'flex'}
      flexDirection={'row'}
      sx={{
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <MuiRadioGroup
        row={true}
        name={'filter'}
        data-testid={'filterTestId'}
        onChange={(event) => setFilter(claimStates[event.target.value])}
        defaultValue={'all'}
      >
        <Radio label={'All'} value={'all'} />
        <Radio label={'Ready to submit'} value={'readyToSubmit'} />
        <Radio label={'Held'} value={'held'} />
        <Radio label={'Contains errors'} value={'containsErrors'} />
      </MuiRadioGroup>
    </Box>
    <TableList
      onCheckboxSelectionChanged={selectionChanged}
      size={'small'}
      showCheckbox={!!hasEditPermission}
      emptyListComponent={ { message: "Unsubmitted claims appear here once saved." } }
      columns={UnsubmittedClaimColumns}
      rows={[
        ...allUnsubmittedClaims
          .filter(filterClaimsByStatus)
          .map((claim) => {
            return (
              UnsubmittedClaimRow(claim, onRowClick)
            )
          })
      ]}
    />
  </>
}