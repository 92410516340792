import React, { useState } from "react";
import { Box, Divider, SvgIcon } from "@mui/material";
import { IconButton, Typography } from "saga-library/src";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ScheduleIcon from "@mui/icons-material/CalendarMonthOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import { PatientNotesDialog } from "./PatientNotesDialog";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ClearIcon from "@mui/icons-material/Clear";
import { BirthdayIcon, PractitionerIcon } from "../../../apps/patients/components/ChartIcons";
import { PatientSearchResult } from "../../../types/patients";
import { useFormContext } from "saga-library/src/components/Form";
import { useParams } from "react-router-dom";
import { useAccountContext } from "../../../providers/AccountContext";
import { formatNextAppointmentDate, patientDisplayName } from "saga-library/src/util";
import { IdentifierGroup } from "saga-library/src/components/SearchControl/blocks";
import { SxProps, Theme } from "@mui/material";
import { useFormattedDate} from "../../../hooks/FormattedDate";
import { FormattedAgeString, NO_DATE_OF_BIRTH } from "../../FormattedAge";
import { postalCodeMask } from "saga-library/src/components/PostalCodeField/PostalCodeField";
import { phoneNumberMask } from "saga-library/src/components/PhoneField/PhoneField";
import { practitionerDisplayName } from "saga-library/src/util/formatting";
import { PHONE_TYPES } from "saga-library/src/components/Phone/PhoneType";
import { LoadingSpinner } from "../../LoadingScreen";

const getAgeAndGenderString = (patient) => {
  if (!patient.dob && !patient.gender?.name) {
    return ''
  } else if (!patient.dob) {
    return patient.gender?.name
  }

  const ageString = `${FormattedAgeString(patient.dob, false)} old`
  if (!patient.gender?.name) {
    return ageString
  }

  return `${ageString} ${patient.gender?.name}`
}

const ExpandIconButton = ({ expand, setExpand, dataTestId }) => {
  return expand
    ? <IconButton icon={<ExpandLessIcon />} onClick={() => setExpand(false)} dataTestId={`${dataTestId}-collapse`} sx={{ ml: -1 }} />
    : <IconButton icon={<ExpandMoreIcon />} onClick={() => setExpand(true)} dataTestId={`${dataTestId}-expand`} sx={{ ml: -1 }} />
}

const PatientEndAdornments = ({ patient, openPatientInNewTab, disabled, clearOnClick, refresh, dataTestId } ) => {
  const [notesOpen, setNotesOpen] = useState<boolean>(false)
  return (
    <Box
      display={'flex'}
      flexDirection={'row'}
      alignItems={'center'}
      justifyContent={'flex-end'}
    >
      {
        patient && patient.patientNotes?.length > 0 &&
        <>
          <IconButton
            dataTestId={`${dataTestId}-notes`}
            onClick={() => setNotesOpen(true)}
            icon={<DescriptionOutlinedIcon />}
          />
          <PatientNotesDialog
            patient={patient}
            open={notesOpen}
            setOpen={setNotesOpen}
          />
        </>
      }
      { refresh &&
        <IconButton
          onClick={refresh}
          icon={<AutorenewIcon />}
        />
      }
      { !disabled &&
        <IconButton
          dataTestId={`${dataTestId}-clear`}
          onClick={clearOnClick}
          icon={<ClearIcon />}
        />
      }
    </Box>
  )
}

const DetailedViewRow = ({ Icon, details = [], sx = {} }: { Icon: typeof SvgIcon, details: (string | { label: string, text: string })[], sx?: SxProps<Theme> }) => {
  if (details.length === 0) {
    return <></>
  }

  return (
    <Box display={'flex'} alignItems={'flex-start'} gap={'4px'} sx={sx}>
      <Icon sx={{ color: (theme) => theme.palette.greys.medium, fontSize: '16px' }} />
      <Box display={'flex'} flexDirection={'column'} gap={'2px'}>
        {details.filter(detail => !!detail).map(detail => {
          if (typeof detail === 'string') {
            return (
              <Typography variant={'p2'}>{detail}</Typography>
            )
          }
          return (
            <Box display={'flex'} alignItems={'center'} gap={'4px'}>
              <Typography variant={'p3'}>{detail.label}</Typography>
              <Typography variant={'p2'}>{detail.text}</Typography>
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}

const DetailedView = ({ patient, isLoading }: { patient: any, isLoading?: boolean }) => {
  if (isLoading) {
    return <LoadingSpinner size={'sm'} label={null} />
  }

  const ageAndGenderString = getAgeAndGenderString(patient)
  return (
    <>
      <Divider />
      <Box display={'flex'} flexDirection={'column'} gap={1} paddingY={1}>
        {ageAndGenderString && (
          <DetailedViewRow
            Icon={BirthdayIcon}
            details={[ageAndGenderString]}
          />
        )}
        <Box display={'flex'} flexDirection={'row'} gap={3}>
          {(patient.street || patient.street2 || patient.street3 || patient.city || patient.postalCode) && (
            <DetailedViewRow
              Icon={HomeOutlinedIcon}
              details={[
                patient.street,
                patient.street2,
                patient.street3,
                `${patient.city ? `${patient.city}${patient.province ? ', ' : ''}`: ''}${patient.province || ''}`,
                postalCodeMask(patient.postalCode)
              ]}
            />
          )}
          <Box display={'flex'} flexDirection={'column'} gap={1}>
            {patient.phones && (
              <DetailedViewRow
                Icon={PhoneOutlinedIcon}
                details={patient.phones.map(phone => ({
                  label: PHONE_TYPES[phone.type],
                  text: phoneNumberMask(phone.number, phone.extension)
                }))}
              />
            )}
            {patient.primaryPractitioner && (
              <DetailedViewRow
                Icon={PractitionerIcon}
                details={[practitionerDisplayName(patient.primaryPractitioner.firstName, patient.primaryPractitioner.lastName)]}
              />
            )}
          </Box>
        </Box>
        <DetailedViewRow
          Icon={ScheduleIcon}
          details={[
            { label: 'Last', text: formatNextAppointmentDate(patient.lastAppointmentDate) },
            { label: 'Next', text: formatNextAppointmentDate(patient.nextAppointmentDate) }
          ]}
        />
      </Box>
    </>
  )
}

interface PatientLabelProps {
  patient: PatientSearchResult
  name: string
  disabled: boolean
  variant?: 'md' | 'sm'
  detailedView?: boolean
  isLoadingDetails?: boolean
  refresh?: () => void
  dataTestId?: string
  sx?: SxProps<Theme>
}


interface PatientLabelSizingProps {
  title: 'body1' | 'p2'
  identifier: 'p2' | 'p3'
  flexDirection: 'row' | 'column'
  alignItems?: 'center' | "normal"
  gap: string
  height: string
}

export const PatientLabel = ({
  patient,
  name,
  disabled,
  variant = 'md',
  detailedView = false,
  isLoadingDetails = false,
  refresh,
  dataTestId,
  sx = {}
}: PatientLabelProps) => {
  const { setValue } = useFormContext()
  const { tenant_id } = useParams()
  const { buildTenantRoute } = useAccountContext()
  const [ expand, setExpand ] = useState<boolean>(detailedView)

  const formattedDob = useFormattedDate(patient.dob)

  const openPatientInNewTab = (patient) => {
    window.open(buildTenantRoute(
      `patients/p/${patient.id}`, tenant_id
    ))
  }

  let sizing: PatientLabelSizingProps = {
    title: 'body1',
    identifier: 'p2',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '12px',
    height: '20px'
  }
  if (variant === 'sm') {
    sizing = {
      title: 'p2',
      identifier: 'p3',
      alignItems: 'normal',
      flexDirection: 'column',
      gap: '2px',
      height: '16px'
    }
  }

  return (
    <Box
      sx={{
        padding: '0px 8px 0px 16px',
        borderRadius: '8px',
        border: '1px solid rgba(32, 41, 49, 0.32)',
        mb: 1
      }}
    >
      <Box
        data-testid={dataTestId}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        sx={{
          height: '56px',
          flexShrink: 0,
          alignSelf: 'stretch',
          ...sx,
        }}
      >
        <>
          <Box display={'flex'} alignItems={'center'}>
            {detailedView && <ExpandIconButton expand={expand} setExpand={setExpand} dataTestId={dataTestId} />}
            <Box>
              <Box display={'inline-flex'} alignItems={'center'} gap={1}>
                <Typography variant={sizing.title} lineclamp={1} sx={{fontWeight:500}} >{patientDisplayName(patient.firstName, patient.lastName)}</Typography>
                <IconButton
                  dataTestId={`${dataTestId}-open`}
                  onClick={() => openPatientInNewTab(patient)}
                  icon={<OpenInNewIcon fontSize={'inherit'} />}
                  sx={{ fontSize: '16px', m: '-4px', p: '4px' }}
                />
              </Box>
              <Box
                display={'flex'}
                flexDirection={ sizing.flexDirection }
                sx={{ gap: sizing.gap }}
                alignItems={sizing.alignItems}
              >
                <Typography dataTestId={`${dataTestId}-identifier`} variant={sizing.identifier} color={'greys.dark'} lineclamp={1}>{formattedDob || NO_DATE_OF_BIRTH}</Typography>
                <IdentifierGroup dataTestId={`${dataTestId}-id`} variant={sizing.identifier} height={sizing.height} label={patient.primaryIdentifierLabel || 'ID'} identifier={patient.primaryIdentifier || 'None'} />
              </Box>
            </Box>
          </Box>
          <PatientEndAdornments
            dataTestId={dataTestId}
            patient={patient}
            openPatientInNewTab={openPatientInNewTab}
            disabled={disabled}
            clearOnClick={() => setValue(name, null)}
            refresh={refresh}
          />
        </>
      </Box>
      {detailedView && expand && (
        <DetailedView
          patient={patient}
          isLoading={isLoadingDetails}
        />
      )}
    </Box>
  )
}