import { DialogV2 } from "saga-library/src";
import { Permission, PermissionType } from "../../../../types/settings/Permission";
import PermissionButton from "../../../../components/PermissionButton";
import React, { useEffect, useState } from "react";
import { LinkedDocumentForm } from "./LinkedDocumentForm";
import { MoreDocumentOptions } from "./MoreDocumentOptions";
import { DocumentCategory, UpdateLinkedDocumentInput } from "../../../../types/Document";
import { linkedDocumentFormDefaultValues } from "./LinkedDocumentFormDefaultValues";
import { schema } from "./LinkedDocumentFormValidationSchema";
import { FormProvider, useForm } from "saga-library/src/components/Form";
import { usePrompt } from "../../../../providers/NavigationPrompt";
import { usePatientDocumentContext } from "../../providers/PatientDocumentProvider";
import { LinkedDocumentsPrintButton } from '../print/LinkedDocumentsPrintButton'
import { useParams } from "react-router-dom";

interface LinkedDocumentModalProps {
  formName: string;
  document: any;
  category: DocumentCategory;
  title: string;
  onModalClose: () => void;
  dataTestId?: string;
}

export const LinkedDocumentModal = ({
  formName,
  document,
  category,
  title,
  onModalClose,
  dataTestId
}: LinkedDocumentModalProps) => {
  const { patient_id } = useParams()
  const { enableNavigationPrompt } = usePrompt()
  const { updateLinkedDocument } = usePatientDocumentContext()
  const [showPatient, setShowPatient] = useState<boolean>(false);
  const [openNavigationPrompt, setOpenNavigationPrompt] = useState<boolean>(false)
  const [categoryIsChanged, setCategoryIsChanged] = useState<boolean>(false)

  const formMethods = useForm<UpdateLinkedDocumentInput>({
    defaultValues: linkedDocumentFormDefaultValues,
    schema: schema(showPatient)
  })

  const {
    handleSubmit,
    formState: { dirtyFields, isSubmitting },
    reset
  } = formMethods

  const handleNavigationPromptDiscard = (discard: boolean) => {
    if (openNavigationPrompt) {
      setOpenNavigationPrompt(false)
      if (discard) {
        onModalClose()
        reset(linkedDocumentFormDefaultValues)
      }
    }
  }

  useEffect(() => {
    if(document) {
      enableNavigationPrompt(!!Object.keys(dirtyFields).length, formName, undefined, openNavigationPrompt, handleNavigationPromptDiscard)
    }
    return () => enableNavigationPrompt(false, formName)
  }, [document, Object.keys(dirtyFields).length, openNavigationPrompt]);

  const onCancel = () => {
    if (!!Object.keys(dirtyFields).length) {
      setOpenNavigationPrompt(true)
    } else {
      onModalClose()
    }
  }

  useEffect(() => {
    if (!document) {
      setShowPatient(false);
    }
  }, [document]);

  if(!document) {
    return null
  }

  const onChangePatient = () => {
    setShowPatient(true);
  }

  const onSubmit = handleSubmit(async (data) => {
    await updateLinkedDocument(document?.id!, data, category, categoryIsChanged, showPatient, () => {
      enableNavigationPrompt(false, formName)
      onModalClose()
    })
  })

  return (
    <DialogV2
      title={title}
      size={"lg"}
      onClose={onCancel}
      headerActions={[
        <>
          <LinkedDocumentsPrintButton
            patientId={patient_id}
            documentId={document?.id}
            dataTestId={`linked-document-${dataTestId}`}
          />
          <MoreDocumentOptions onChangePatient={onChangePatient} key={"more_document_options"}/>
        </>
      ]}
      primaryAction={()=>null}
      overridePrimaryComponent={
        <PermissionButton
          name={"saveLinkedDocument"}
          type={"submit"}
          form={formName}
          requiredType={PermissionType.Chart}
          requiredPermission={Permission.READWRITE}
          loading={isSubmitting}
          dataTestId={`${dataTestId}-save-button`}
        >
          Save
        </PermissionButton>
      }
      open={!!document}
      preventScroll={true}
      sx={{overflow:"hidden", overflowY:"hidden"}}
      contentSx={{overflow:"hidden", display:"flex", flexDirection:"column"}}
      PaperProps={{sx:{overflow:"hidden"}}}
    >
      <FormProvider {...formMethods}>
        <LinkedDocumentForm
          formName={formName}
          showPatient={showPatient}
          onSubmit={onSubmit}
          setCategoryIsChanged={setCategoryIsChanged}
          document={document}
          viewerSx={{flex:"1 1 850px", overflow:"hidden"}}
          dataTestId={`${dataTestId}-form`}
        />
      </FormProvider>
    </DialogV2>
  )
}