import React from 'react'
import { Box } from '@mui/material'
import { Form, TextField } from 'saga-library/src'
import { FormRow } from '../../../../components/FormRow'
import { AssignedTo } from '../../../../components/AssignedTo'
import { PractitionerSelect } from '../../../../components/PractitionersSelect'
import FormattedDatePicker from '../../../../components/FormattedDatePicker'
import { TaskTemplateSelect } from '../../../../components/TaskTemplateSelect'
import { TaskStateSelect } from '../../../../components/TaskStateSelect'
import { PatientSearch } from '../../../../components/SearchControls/Patient/PatientSearch'
import { TaskTypeSelect } from '../../../../components/TaskTypeSelect'
import { TaskPrioritySelect } from '../../../../components/TaskPrioritySelect'

interface TaskFormProps {
  formName: string
  onSubmit: () => void
  dataTestId: string
}

export const TaskForm = ({
  formName,
  onSubmit,
  dataTestId
}: TaskFormProps) => {
  return (
    <Form onSubmit={onSubmit} id={formName}>
      <TaskTemplateSelect
        name={'template'}
        label={'Template'}
        dataTestId={`${dataTestId}-template`}
      />
      <FormRow>
        <TextField
          name={'name'}
          label={'Name'}
          fullWidth={true}
          dataTestId={`${dataTestId}-name`}
        />
        <TaskTypeSelect
          name={'typeId'}
          label={'Type'}
          sx={{ flexBasis: '206px' }}
          dataTestId={`${dataTestId}-type`}
        />
      </FormRow>
      <FormRow>
        <Box>
          <AssignedTo
            name={'assignedTo'}
            label={'Assigned to'}
            includeRoles={true}
            dataTestId={`${dataTestId}-assignedTo`}
          />
          <FormRow>
            <TaskStateSelect
              name={'stateId'}
              label={'State'}
              defaultStateName={'To do'}
              dataTestId={`${dataTestId}-state`}
            />
            <TaskPrioritySelect
              name={'priority'}
              label={'Priority'}
              dataTestId={`${dataTestId}-priority`}
            />
          </FormRow>
          <FormRow>
            <FormattedDatePicker
              name={'startDate'}
              label={'Start date'}
              dataTestId={`${dataTestId}-startDate`}
            />
            <FormattedDatePicker
              name={'dueDate'}
              label={'Due date'}
              dataTestId={`${dataTestId}-dueDate`}
            />
          </FormRow>
          <PractitionerSelect
            name={'practitionerId'}
            label={'Practitioner'}
            dataTestId={`${dataTestId}-practitioner`}
          />
          <PatientSearch
            name={'patientId'}
            label={'Patient'}
            detailedView={true}
            showRefresh={true}
            dataTestId={`${dataTestId}-patient`}
          />
          {/* TODO: SAGA-3143 */}
        </Box>
        <Box>
          <TextField
            name={'description'}
            label={'Description'}
            multiline={true}
            rows={3}
            fullWidth={true}
            dataTestId={`${dataTestId}-description`}
          />
          <TextField
            name={'comment'}
            label={'Comment'}
            multiline={true}
            rows={3}
            fullWidth={true}
            dataTestId={`${dataTestId}-comment`}
          />
        </Box>
      </FormRow>
    </Form>
  )
}