import {TableList, TableListHeaderConfig } from 'saga-library/src'
import React, { useEffect, useMemo, useState } from 'react'
import { DocumentsRow } from './DocumentsRow'
import { useReviewContext } from '../../providers/ReviewProvider'
import { DocumentCategory } from '../../../../types/Document'
import { useReadQuery } from '@apollo/client'

const columns: TableListHeaderConfig[] = [
  { name: 'Date', sortable: true },
  { name: 'Patient', sortable: true },
  { name: 'Category' },
  { name: 'Description' }
]

export type FilterType = 'category' | 'unreviewed'

interface DocumentsListProps {
  listFilters: { unreviewed: string; category: string }
  dataTestId: string,
}

export const DocumentsList = ({
  listFilters,
  dataTestId,
}: DocumentsListProps) => {
  const { userDocuments, parseUserDocumentQueryResults } = useReviewContext()
  const { data } = useReadQuery(userDocuments)
  const parsedData = useMemo(() => parseUserDocumentQueryResults(data),
    [userDocuments, parseUserDocumentQueryResults]
  )

  const [filteredDocuments, setFilteredDocuments] = useState(parsedData)

  useEffect(() => {
    let filtered = parsedData
    if (listFilters.category !== '*') {
      switch (listFilters.category) {
        case 'labs':
          filtered = parsedData.filter((doc) => doc.linkedDocument.category === DocumentCategory.LAB_RESULT)
          break
        case 'investigations':
          filtered = parsedData.filter((doc) => doc.linkedDocument.category === DocumentCategory.INVESTIGATION)
          break
        case 'other':
          filtered = parsedData.filter((doc) => doc.linkedDocument.category === DocumentCategory.OTHER_DOCUMENT)
          break
      }
    }

    switch (listFilters.unreviewed) {
      case 'unreviewed':
        filtered = filtered.filter((doc) => !doc.isReviewed)
        break
      case 'reviewed':
        filtered = filtered.filter((doc) => doc.isReviewed)
    }

    setFilteredDocuments(filtered)
  }, [listFilters, parsedData])

  return (
    <TableList
      rows={[...filteredDocuments.map((doc, index) => DocumentsRow(doc, index, dataTestId))]}
      columns={columns}
      showCheckbox={true}
      enableSorting={true}
      emptyListComponent={{ message: "Documents appear here once received." }}
      dataTestId={`${dataTestId}-documents-list`}
    />
  )
}