import React, { useEffect, useMemo, useRef, useState } from 'react'
import { FormProvider, useForm } from "saga-library/src/components/Form";
import { DialogV2, LoadingButton } from "saga-library/src";
import { usePrompt } from "../../../../providers/NavigationPrompt";
import { ImmunizationType } from "../../../../types/Immunization";
import { useHistoryContext } from '../../providers/HistoryProvider'
import { IMMUNIZATION_COLUMN_KEYS, ImmunizationsForm } from './ImmunizationsForm'
import { useReadQuery } from '@apollo/client'
import { useAlerts } from 'saga-library/src/providers/Alerts'
import { blurDataGrid } from 'saga-library/src/components/DataGrid/DataGrid'

const FORM_NAME = "edit_immunization_form"

export type ImmunizationFormType = {
  immunizations: ImmunizationType[]
  version?: string
  isEditing?: boolean
}

const immunizationDefaults: ImmunizationFormType = {
  version: "0",
  immunizations: [],
  isEditing: false
}

const ImmunizationDialog = ({ open, setOpen }) => {
  const { parseImmunizationQueryResults, immunizationQueryRef, updateImmunizations } = useHistoryContext()
  const { enableNavigationPrompt } = usePrompt()
  const { showWarningAlert } = useAlerts()
  const dataGridRef = useRef<any>(null)

  const { data: immunizationData } = useReadQuery(immunizationQueryRef!)
  const immunizationHistory = useMemo(() => parseImmunizationQueryResults(immunizationData), [immunizationData, parseImmunizationQueryResults])

  const [openNavigationPrompt, setOpenNavigationPrompt] = useState<boolean | string>(false)

  const formMethods = useForm<ImmunizationFormType>({
    defaultValues: immunizationDefaults,
  })

  const {
    handleSubmit,
    formState: { dirtyFields, isSubmitting },
    reset,
    getValues
  } = formMethods

  useEffect(() => {
    if (open) {
      if (immunizationHistory.length === 0) {
        reset({ immunizations: [
            {
              id: crypto.randomUUID(),
              name: '',
              date: null
            }
          ]
        })
      } else {
        reset({ immunizations: immunizationHistory })
      }
    }
  }, [open])

  const handleNavigationPromptDiscard = (discard: boolean) => {
    if (openNavigationPrompt) {
      setOpenNavigationPrompt(false)
      if (discard) {
        setOpen(null)
        reset({ immunizations: immunizationHistory })
      }
    }
  }

  useEffect(() => {
    if (open) {
      enableNavigationPrompt(!!Object.keys(dirtyFields).length, FORM_NAME, undefined, !!openNavigationPrompt, handleNavigationPromptDiscard)
    }
    return () => enableNavigationPrompt(false, FORM_NAME)
  }, [open, Object.keys(dirtyFields).length, openNavigationPrompt]);

  const onSubmit = () => {
    setOpen(null)
  }

  const onSave = handleSubmit(async (data) => {
    await blurDataGrid(dataGridRef)
    const updatedData = getValues()
    if (updatedData.isEditing) {
      showWarningAlert(`History contains errors. Please fix before saving.`)
      return
    }

    await updateImmunizations(updatedData)
    setOpen(null)
  })

  const onCancel = async() => {
    await blurDataGrid(dataGridRef)
    const isEditing = getValues('isEditing')
    const updatedValues = getValues('immunizations')
    const emptyFirstItem = immunizationHistory.length === 0 && updatedValues.length === 1 && IMMUNIZATION_COLUMN_KEYS.every(key => !updatedValues[0][key])
    if (!emptyFirstItem && (!!Object.keys(dirtyFields).length || isEditing)) {
      setOpenNavigationPrompt(true)
    } else {
      setOpen(null)
      reset({ immunizations: immunizationHistory })
    }
  }

  return <DialogV2
    dataTestId={'edit-immunization-dialog'}
    title={"Immunization history"}
    open={!!open}
    onClose={onCancel}
    primaryAction={()=> null}
    overridePrimaryComponent={
      <LoadingButton
        name={'saveImmunization'}
        form={FORM_NAME}
        dataTestId={"edit-immunizations-dialog-primary-button"}
        loading={isSubmitting}
        onClick={onSave}
        variant={'contained'}
      >
        Save
      </LoadingButton>
    }
  >
    <FormProvider {...formMethods}>
      <ImmunizationsForm
        onSubmit={onSubmit}
        dataGridRef={dataGridRef}
      />
    </FormProvider>
  </DialogV2>
}

export default ImmunizationDialog