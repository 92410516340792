import React from 'react'
import { BatchUpdateAssessedClaimBaseDetails } from './BatchUpdateAssessedClaimBaseDetails'
import Box from "@mui/material/Box";
import { Typography } from "saga-library/src";
import { AssessmentStatusBadge } from "../../AssessedClaims/AssessedClaimRow";
import { useTheme } from "@mui/material";

export const BatchUpdateAssessedBase = ({
  batchUpdateMultiple,
  claim,
  selectedClaims
}) => {
  const theme = useTheme()
  return (
    <>
      <BatchUpdateAssessedClaimBaseDetails.Form
        batchUpdateMultiple={batchUpdateMultiple}
        selectedClaims={selectedClaims}
      />
      <Box sx={{mt: 3}}>
        <Typography variant={'body2'} color={theme.palette.greys.medium}>
          Outcome
        </Typography>
        <AssessmentStatusBadge
          claim={claim}
          sx={{m: 0}}
        />
      </Box>
    </>
  )
}