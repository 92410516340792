import { Box } from '@mui/material'
import { FilterSelect, LoadingButton, Section } from 'saga-library/src'
import React from 'react'
import { FilterType } from './LabsList'
import { AbLabResult } from '../../../../types/patients'
import {
  LabAndInvestigationLabel,
  LabAndInvestigationPatientLabel
} from '../../../patients/components/labAndInvestigations/components/LabAndInvestigationLabel'

interface LabsHeaderProps {
  setFilters: (event: any, filterType: FilterType) => void,
  dataTestId: string,
}

export const LabsHeader = ({
  setFilters,
  dataTestId
}: LabsHeaderProps) => {
  const filterLabsOptions = [
    { label: 'Labs and investigations', value: '*' },
    { label: 'Labs', value: 'labs' },
    { label: 'Investigations', value: 'investigations' },
  ]

  const filterLabsReviewOptions = [
    { label: 'Unreviewed', value: 'unreviewed' },
    { label: 'Reviewed', value: 'reviewed' },
  ]

  const filterLabsNormalOptions = [
    { label: 'Normal and abnormal', value: '*' },
    { label: 'Normal', value: 'normal' },
    { label: 'Abnormal', value: 'abnormal' },
  ]

  return (
    <Box
      display={'flex'}
      flexDirection={'row'}
      justifyContent={'flex-start'}
      alignItems={'center'}
      gap={1}
    >
      <FilterSelect
        options={filterLabsOptions}
        onSelect={(event) => setFilters(event, "labsAndInvestigations")}
        defaultSelectedValue={'*'}
        dataTestId={`${dataTestId}-list-labs-filter`}
      />
      <FilterSelect
        options={filterLabsReviewOptions}
        onSelect={(event) => setFilters(event, "unreviewed")}
        defaultSelectedValue={'unreviewed'}
        dataTestId={`${dataTestId}-list-labs-review-filter`}
      />
      <FilterSelect
        options={filterLabsNormalOptions}
        onSelect={(event) => setFilters(event, "normalAndAbnormal")}
        defaultSelectedValue={'*'}
        dataTestId={`${dataTestId}-list-labs-normal-filter`}
      />
    </Box>
  )
}

interface LabResultHeaderProps {
  lab: AbLabResult,
  formName: string,
  formSubmitting: boolean,
  dataTestId: string,
}

export const LabsResultHeader = ({
  lab,
  formName,
  formSubmitting,
  dataTestId,
}: LabResultHeaderProps) => {
  return (
    lab && (
      <>
        <Box display={"flex"} alignItems={'center'} gap={1} mb={1}>
          <Section.Header
            dataTestId={`${dataTestId}-sectionLabel`}
            sx={{mb: 0, flex: "1 1 100%"}}
          >
            <LabAndInvestigationLabel labAndInvestigation={lab}/>
          </Section.Header>
          <LoadingButton
            form={formName}
            type={'submit'}
            name={'SaveButton'}
            variant={'outlined'}
            loading={formSubmitting}
            disabled={false}
            data-testid={`${dataTestId}-save-button`}
          >
            SAVE
          </LoadingButton>
          <LoadingButton
            form={formName}
            type={'button'}
            name={'markAsReviewedButton'}
            variant={'contained'}
            loading={false}
            disabled={false}
            data-testid={`${dataTestId}-mark-reviewed-button`}
          >
            MARK AS REVIEWED
          </LoadingButton>

        </Box>
        <LabAndInvestigationPatientLabel
          labAndInvestigation={lab}
          sx={{
            mt: 1,
            display: 'flex',
          }}
        />
      </>
    )
  )
}