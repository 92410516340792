import * as yup from 'yup'

export const schema = yup.object({
  name: yup.string().required("Required"),
  description: yup.string().nullable(),
  taskName: yup.string().nullable(),
  typeId: yup.string().required("Required"),
  assignedUserId: yup.string().nullable(),
  assignedRoleId: yup.string().nullable(),
  practitionerId: yup.string().nullable(),
  startOffset: yup.number().typeError("Required").required("Required"),
  endOffset: yup.number().typeError("Required").required("Required")
    .test({
      name: "end_offset_is_greater",
      message: "Due date should be greater than start date",
      test: (endOffset, context) => {
        const { startOffset } = context.parent
        if ((!startOffset && startOffset !== 0) || (!endOffset && endOffset !== 0)) {
          return false
        }
        return endOffset >= startOffset
      }
    })
})