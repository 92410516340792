import { DialogV2 } from 'saga-library/src'
import { FormProvider } from 'saga-library/src/components/Form'
import { TaskStateForm } from './TaskStateForm'
import React, { useEffect, useState } from 'react'
import { usePrompt } from '../../../../providers/NavigationPrompt'

export const TaskStateModal = ({
  open,
  onClose,
  formName,
  formMethods,
  onSubmit,
}) => {
  const { enableNavigationPrompt } = usePrompt()
  const [ openNavigationPrompt, setOpenNavigationPrompt ] = useState<boolean>(false)

  const {
    formState: {dirtyFields, isSubmitting},
  } = formMethods

  const handleNavigationPromptDiscard = (discard : boolean) => {
    if (openNavigationPrompt) {
      setOpenNavigationPrompt(false)
      if (discard) {
        onClose()
      }
    }
  }

  useEffect(() => {
    if (open) {
      enableNavigationPrompt(!!Object.keys(dirtyFields).length, formName, undefined, openNavigationPrompt, handleNavigationPromptDiscard)
    }
    return () => enableNavigationPrompt(false, formName)
  }, [open, Object.keys(dirtyFields).length, openNavigationPrompt]);


  const handleClose = () => {
    if (!!Object.keys(dirtyFields).length) {
      setOpenNavigationPrompt(true)
    } else {
      onClose()
    }
  }

  return (
    <DialogV2
      dataTestId={'new-task-state-dialog'}
      title={"New task state"}
      open={open}
      onClose={handleClose}
      size={'xs'}
      formName={formName}
      submitting={isSubmitting}
    >
      <FormProvider {...formMethods}>
        <TaskStateForm
          formName={formName}
          onSubmit={onSubmit}
          dataTestId={'new-task-state-form'}
        />
      </FormProvider>
    </DialogV2>
  )
}