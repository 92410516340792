import { gql } from "@apollo/client";

export const GET_PRACTITIONER_LAB_AND_INVESTIGATIONS = gql`
  query GetPractitionerLabAndInvestigations($tenantId: ID!, $practitionerId: ID!) {
    tenant(id: $tenantId) {
      review {
        lab {
          list(id: $practitionerId) {
            id
            date
            isReviewed
            abLabResult {
              id
              isLinkedDocument
              description
              isAbnormal
              isLab
              patientId
              patientName {
                firstName
                lastName
              }
            }
          }
        }
      }
    }
  }
`

const REVIEW_DOCUMENT_FRAGMENT = gql`
  fragment ReviewDocumentFragment on ReviewDocument {
    id
    date
    isReviewed
    linkedDocument {
      id
      fileId
      category
      documentDate
      description
      file {
        originalFileName
      }
    }
    patient {
      id
      firstName
      lastName
    }
    user {
      id
      firstName
      lastName
    }
  }
`

export const GET_USER_DOCUMENTS = gql`
  ${REVIEW_DOCUMENT_FRAGMENT}
  query GetUserDocuments($tenantId: ID!, $userId: ID!) {
    tenant(id: $tenantId) {
      review {
        document {
          list(id: $userId) {
            ...ReviewDocumentFragment
          }
        }
      }
    }
  }
`

export const CREATE_REVIEW_DOCUMENT = gql`
  ${REVIEW_DOCUMENT_FRAGMENT}
  mutation CreateReviewDocument($tenantId: ID!, $input: ReviewDocumentInput!) {
    tenant(id: $tenantId) {
      review {
        document {
          create(input: $input) {
            ...ReviewDocumentFragment
          }
        }
      }
    }
  }
`