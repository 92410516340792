import React from "react";
import { useLoadableQuery } from '@apollo/client'
import { GET_PRACTITIONER_LAB_AND_INVESTIGATIONS, GET_USER_DOCUMENTS } from '../../../graphql-definitions'
import { ReviewLab } from "types/inbox/ReviewLab";
import _get from 'lodash/get'
import { ReviewDocument } from '../../../types/inbox/ReviewDocument'

const getPractitionerLabQueryResults = (data) => {
  return [..._get(data, 'tenant.review.lab.list', []) as Array<ReviewLab>]
}

const parsePractitionerLabQueryResults = (data) => {
  return getPractitionerLabQueryResults(data)
}

const getUserDocumentQueryResults = (data) => {
  return [..._get(data, 'tenant.review.document.list', []) as Array<ReviewDocument>]
}

const parseUserDocumentQueryResults = (data) => {
  return getUserDocumentQueryResults(data)
}

interface ReviewContextInterface {
  loadPractitionerLabs: any,
  practitionerLabs: any,
  parsePractitionerLabQueryResults: (any) => ReviewLab[],
  loadUserDocuments: any,
  userDocuments: any,
  parseUserDocumentQueryResults: (any) => ReviewDocument[],
}

const defaultReviewContext: ReviewContextInterface = {
  practitionerLabs: [],
  loadPractitionerLabs: () => {},
  parsePractitionerLabQueryResults: parsePractitionerLabQueryResults,
  loadUserDocuments: () => {},
  userDocuments: [],
  parseUserDocumentQueryResults: parseUserDocumentQueryResults
}

const ReviewContext = React.createContext(defaultReviewContext)

export const ReviewProvider = ({ children }) => {
  const [loadPractitionerLabs, practitionerLabs] = useLoadableQuery(GET_PRACTITIONER_LAB_AND_INVESTIGATIONS,
    {fetchPolicy: 'cache-and-network'}
  )

  const [loadUserDocuments, userDocuments] = useLoadableQuery(GET_USER_DOCUMENTS,
    {fetchPolicy: 'cache-and-network'}
  )

  const providerValues = {
    practitionerLabs,
    loadPractitionerLabs,
    parsePractitionerLabQueryResults,
    userDocuments,
    loadUserDocuments,
    parseUserDocumentQueryResults
  }

  return (
    <ReviewContext.Provider value={providerValues}>
      { children }
    </ReviewContext.Provider>
  )
}

export const useReviewContext = () => {
  return React.useContext(ReviewContext)
}