import React, { Suspense, useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { TaskList } from './components/Tasks/TaskList'
import { FormSection } from './components/Tasks/FormSection'
import { TaskCategory } from '../../types/tasks/TaskState'
import { useParams } from 'react-router-dom'
import { LoadingSpinner } from '../../components/LoadingScreen'
import { EmptyList, Section } from 'saga-library/src'
import { useTaskContext } from '../../providers/TaskProvider'
import { TaskListHeader } from './components/Tasks/TasksHeader'
import { TaskEditor } from './components/Tasks/TaskEditor'
import { TaskMultipleSelected } from './components/Tasks/TaskMultipleSelected'
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined'

const EmptyTaskSelection = () => {
  return (
    <Section.Column rightPadding={1} sx={{ flex: "1 1 100%" }}>
      <EmptyList icon={AssignmentTurnedInOutlinedIcon} message={'Select a task to view it.'} />
    </Section.Column>
  )
}

export const Tasks = () =>{
  const { user_id, role_id, tenant_id } = useParams()
  const { loadTasks, tasks } = useTaskContext()
  const [ filters, setFilters ] = useState<{type: string, state: TaskCategory | string}>({
    type: '*',
    state: TaskCategory.INCOMPLETE,
  })
  const [ selectedTasks, setSelectedTasks ] = useState<string[]>([])
  const [ clearSelectedTasks, setClearSelectedTasks ]  = useState<boolean>(false)
  const [ showForm, setShowForm ] = useState<boolean>(false)

  useEffect(() => {
    if(!tenant_id && (!user_id || !role_id)) return

    setClearSelectedTasks(true)

    loadTasks({
      tenantId: tenant_id,
      userId: user_id,
      roleId: role_id,
    })
  }, [user_id, role_id, tenant_id])

  useEffect(() => {
    setClearSelectedTasks(true)
  }, [filters])

  const view = () => {
    if (selectedTasks.length > 0) {
      return <TaskMultipleSelected selectedTasks={selectedTasks} clearSelected={() => setClearSelectedTasks(true)} />
    }

    if (showForm) {
      return <TaskEditor />
    }

    return <EmptyTaskSelection />
  }

  return (
    <Box display={'flex'} width={"100%"}>
      <Section.Column
        width={"50%"}
        sx={{pr:1}}
        header={<TaskListHeader setFilters={setFilters} onNewTask={() => setShowForm(true)} />}
      >
        <Suspense fallback={<LoadingSpinner />}>
          {tasks && (
            <TaskList
              filters={filters}
              setSelectedTasks={setSelectedTasks}
              clearSelectedTasks={clearSelectedTasks}
              setClearSelectedTasks={setClearSelectedTasks}
            />
          )}
        </Suspense>
      </Section.Column>
      <FormSection>
        {view()}
      </FormSection>
    </Box>
  )
}
