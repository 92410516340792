import React, { useEffect } from 'react'
import { Navigate, Route, Routes, useLocation, useParams } from 'react-router-dom'
import { usePageTitleContext } from "../providers/PageTitleContextProvider";
import { ComingSoonPlaceholder} from 'saga-library/src'
import { Box } from '@mui/material'
import { useAccountContext } from '../providers/AccountContext'
import { LayoutWrapper } from '../components/Layouts'

import { ImportedDocuments } from '../apps/tasks/documents/ImportedDocuments'
import { InboxSection } from '../apps/inbox/Inbox'
import { Tasks } from '../apps/inbox/Tasks'
import { Labs } from '../apps/inbox/Labs'
import { Documents } from '../apps/inbox/Documents'

export const Inbox = () => {
  const { setAreaTitle } = usePageTitleContext()
  const { tenant_id } = useParams()
  const { userId, buildTenantRoute } = useAccountContext()

  useEffect(() => {
    setAreaTitle('Inbox')
  }, [setAreaTitle])

  const defaultPath = buildTenantRoute(`inbox/u/${userId}/tasks`, tenant_id)

  return (
    <Routes>
      <Route path="/" element={<InboxSection />}>
        <Route index element={<Navigate to={defaultPath} replace />} />
        <Route path={'u/:user_id/*'} element={<InboxSubRoutes />} />
        <Route path={'r/:role_id/*'} element={<InboxSubRoutes />} />
        <Route path={"import"} element={<ImportedDocuments />} />
      </Route>
    </Routes>
  )
}

const InboxSubRoutes = () => {
  const { tenant_id } = useParams()
  const { userId, buildTenantRoute } = useAccountContext()
  const defaultPath = buildTenantRoute(`inbox/u/${userId}/tasks`, tenant_id)
  return (
    <Routes>
      <Route path={'tasks'}>
        <Route index element={<Tasks />} />
        <Route path={'t/:task_id'} element={<Tasks />} />
      </Route>
      <Route path={'labs'}>
        <Route index element={<Labs />} />
        <Route path={'l/:lab_id'} element={<PlaceHolderLayout areaName={'Labs'}/>} />
      </Route>
      <Route path={'documents'}>
        <Route index element={<Documents />} />
        <Route path={'d/:document_id'} element={<PlaceHolderLayout areaName={'Documents'}/>} />
      </Route>
      <Route path="*" element={<Navigate to={defaultPath} replace />} />
    </Routes>
  )
}

const PlaceHolderLayout = ({areaName}) => {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const taskId = queryParams.get('tId') //TODO: get the id of the task/lab/doc from query params in the specific task/lab/doc component.
  //console.log(taskId)
  return (
    <LayoutWrapper>
      <Box flex={"1 1 50%"}>
        <ComingSoonPlaceholder message={`${areaName} list area`} />
      </Box>
      <Box flex={"1 1 50%"}>
        <ComingSoonPlaceholder message={`${areaName} form area`} />
      </Box>
    </LayoutWrapper>
  )
}
