import { Section, useForm } from 'saga-library/src'
import React, { useEffect, useState } from 'react'
import { AbLabResult, AbLabResultUpdate } from '../../../../types/patients'
import { LabAndInvestigationForm } from '../../../patients/components/labAndInvestigations/LabAndInvestigationForm'
import { FormProvider } from 'react-hook-form'
import { schema } from '../../../patients/components/labAndInvestigations/LabAndInvestigationFormValidationSchema'
import { usePrompt } from 'providers/NavigationPrompt'
import { useMutation, useReadQuery } from '@apollo/client'
import _get from 'lodash/get'
import { LabsResultHeader } from './LabsHeader'
import { UPDATE_LAB_AND_INVESTIGATION } from '../../../../graphql-definitions'
import { useParams } from 'react-router-dom'
import { useAlerts } from 'saga-library/src/providers/Alerts'
import {
  LabAndInvestigationFileNavigation
} from '../../../patients/components/labAndInvestigations/components/LabAndInvestigationFileNavigation'
import { FileType } from '../../../../types/tasks'
import { Box } from '@mui/material'

const FORM_NAME = 'labs_and_investigations_inbox_form'

interface LabsResultProps {
  labQuery: any,
  dataTestId: string,
}

export const LabsResult = ({
  labQuery,
  dataTestId,
}: LabsResultProps) => {
  const { tenant_id } = useParams()
  const { showErrorAlert, showSuccessAlert } = useAlerts()
  const { enableNavigationPrompt } = usePrompt()
  const labData = useReadQuery(labQuery)
  const lab = _get(labData, 'data.tenant.patient.labAndInvestigation.get', null) as unknown as AbLabResult
  const [openNavigationPrompt, setOpenNavigationPrompt] = useState<boolean>(false)

  const [file, setFile] = useState<FileType | null>(null)
  const [files, setFiles] = useState<FileType[]>([])

  const formMethods = useForm<AbLabResultUpdate>({
    schema: schema
  })

  const {
    reset,
    formState: { dirtyFields, isSubmitting},
  } = formMethods

  const handleNavigationPromptDiscard = () => {
    setOpenNavigationPrompt(false)
  }

  const [ updateLabAndInvestigationMutation ] = useMutation(UPDATE_LAB_AND_INVESTIGATION)
  const updateLabAndInvestigation = async (labAndInvestigationInput, onSuccess) => {
    const id = labAndInvestigationInput.id
    delete labAndInvestigationInput.id

    await updateLabAndInvestigationMutation({
      variables: {
        tenantId: tenant_id,
        id: id,
        input: labAndInvestigationInput
      },
      onCompleted: (data) => {
        showSuccessAlert("Lab has been saved.")
        if (onSuccess) {
          const labAndInvestigation = _get(data, 'tenant.patient.labAndInvestigation.update', null)
          onSuccess(labAndInvestigation)
        }
      },
      onError: () => {
        showErrorAlert("Lab and investigation couldn't be saved")
      }
    })
  }

  useEffect(() => {
    enableNavigationPrompt(!!Object.keys(dirtyFields).length, FORM_NAME, undefined, openNavigationPrompt, handleNavigationPromptDiscard)
    return () => enableNavigationPrompt(false, FORM_NAME)
  }, [Object.keys(dirtyFields).length, openNavigationPrompt])

  useEffect(() => {
    if (lab) {
      const resultsWithEmbeddedFile = lab?.abLabResultObservationResults
        ?.filter(r => r.file)
        ?.map(r => r.file!)

      setFiles(resultsWithEmbeddedFile)
      resultsWithEmbeddedFile.length > 0 ? setFile(resultsWithEmbeddedFile[0]) : setFile(null)

      reset({
        id: lab?.id,
        notes: lab?.notes,
        version: lab?.version
      })
    }
  }, [lab])

  return (
    <Section.Column
      sx={{ pr: 1 }}
      width={'50%'}
      header={
        <LabsResultHeader
          lab={lab}
          formName={FORM_NAME}
          formSubmitting={isSubmitting}
          dataTestId={dataTestId}
        />
      }
    >
      {
        files.length > 1 &&
        <Box
          pr={"32px"}
          display={"flex"}
          flexDirection={"row"}
          gap={1}
          alignItems={"center"}
        >
          <LabAndInvestigationFileNavigation
            files={files}
            selectedFile={file}
            setSelectedFile={setFile}
            dataTestId={'editLabAndInvestigation'}
          />
        </Box>
      }
      <FormProvider {...formMethods}>
        <LabAndInvestigationForm
          file={file}
          formName={FORM_NAME}
          labAndInvestigation={lab}
          updateLabAndInvestigationMethod={updateLabAndInvestigation}
          dataTestId={`${dataTestId}-labAndInvestigation`}
        />
      </FormProvider>
    </Section.Column>
  )
}